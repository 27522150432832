import React, { useContext } from "react";
import Divider from "../../../components/dividers/Divider/Divider";
import SearchResultsFor from "../SearchResultsFor/SearchResultsFor";
import OpportunitiesFiltersHeader from "./OpportunitiesFiltersHeader";
import OpportunitiesSearchResults from "./OpportunitiesSearchResults";
import { Spacing } from "../../../components/spacing/component";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";

const OpportunitiesPage = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <>
      <SearchResultsFor />

      <OpportunitiesFiltersHeader />

      <Spacing pt={isSmallerScreen ? "12" : "24"} />

      <Divider />

      <OpportunitiesSearchResults />
    </>
  );
};

export default OpportunitiesPage;
