// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_createTagMainContainer__BlfLP {\n  width: 100%;\n}\n\n.styles_createTagButton__YzNof {\n  min-width: 22px;\n}\n\n.styles_addIconContainer__cM-nq {\n  min-width: 18px;\n  max-width: 18px;\n  height: 18px;\n  border-radius: 100%;\n}\n\n.styles_addTagInput__OLKmg {\n  border-radius: 9px;\n  padding: 2px 10px;\n  outline: none;\n  height: 18px;\n  width: 100%;\n  min-width: 75px;\n  max-width: 155px;\n  font-size: 12px;\n}\n\n.styles_addTagInput__OLKmg::-webkit-input-placeholder {\n  font-size: 12px;\n}\n\n.styles_addTagInput__OLKmg::placeholder {\n  font-size: 12px;\n}\n\n.styles_coloredTag__NV8lR {\n  min-width: 12px;\n  height: 12px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/tags/create-tag/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAFA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".createTagMainContainer {\n  width: 100%;\n}\n\n.createTagButton {\n  min-width: 22px;\n}\n\n.addIconContainer {\n  min-width: 18px;\n  max-width: 18px;\n  height: 18px;\n  border-radius: 100%;\n}\n\n.addTagInput {\n  border-radius: 9px;\n  padding: 2px 10px;\n  outline: none;\n  height: 18px;\n  width: 100%;\n  min-width: 75px;\n  max-width: 155px;\n  font-size: 12px;\n}\n\n.addTagInput::placeholder {\n  font-size: 12px;\n}\n\n.coloredTag {\n  min-width: 12px;\n  height: 12px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createTagMainContainer": "styles_createTagMainContainer__BlfLP",
	"createTagButton": "styles_createTagButton__YzNof",
	"addIconContainer": "styles_addIconContainer__cM-nq",
	"addTagInput": "styles_addTagInput__OLKmg",
	"coloredTag": "styles_coloredTag__NV8lR"
};
export default ___CSS_LOADER_EXPORT___;
