import React, { useState } from "react";
import RoundedButton from "../../../../components/buttons/RoundedButton/RoundedButton";
import { Flex } from "../../../../components/containers/Flex/Flex";
import HorizontallyScrollable from "../../../../components/containers/HorizontallyScrollable/HorizontallyScrollable";
import Divider from "../../../../components/dividers/Divider/Divider";
import { Spacing } from "../../../../components/spacing/component";
import SelectionItem from "../SelectionItem/SelectionItem";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import SearchSaveWithTag from "../../../../components/tags/save-search-with-tag/SearchSaveWithTag";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import RText from "../../../../components/fonts/RText/RText";
import { ITag } from "../../../../entities/custom-tags/state/custom-tag.model";

interface IProps {
  tags: ITag[];
  assignTag: (tagId: number) => Promise<void>;
  searchByTag: (tagName: string) => void;
  totalCount: number;
  tagLabel: string;
}

const Selections = ({ tags, assignTag, searchByTag, totalCount, tagLabel }: IProps) => {
  const { t } = useTranslation();

  const [inputOpen, setInputOpen] = useState(false);

  const openInput = () => {
    setInputOpen(true);
  };

  const closeInput = () => {
    setInputOpen(false);
  };

  const onAssignTag = async (tagId: number) => {
    await assignTag(tagId);
    closeInput();
  };

  return (
    <>
      <Divider />
      <Spacing pv="8">
        <Flex justifyContentSpaceBetween className={styles.component}>
          <HorizontallyScrollable showArrows>
            {tags &&
              tags.map((tag) => {
                return (
                  <React.Fragment key={tag.id}>
                    <SelectionItem tag={tag} searchByTag={searchByTag} />
                    <Spacing pl="16" />
                  </React.Fragment>
                );
              })}
          </HorizontallyScrollable>
          <Flex alignItemsCenter>
            {inputOpen ? (
              <>
                <RText immutableBlack>
                  {`${formatNumberWithDotSeparator(totalCount)} ${tagLabel}`}
                </RText>
                <SearchSaveWithTag addTagToEntity={onAssignTag} searchByTag={searchByTag} />
              </>
            ) : (
              <>
                <Spacing pl="20" />
                <RoundedButton text={t("saveSelection")} onClick={openInput} />
              </>
            )}
          </Flex>
        </Flex>
      </Spacing>
      <Divider />
    </>
  );
};

export default Selections;
