// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__Eb2-Q {\n}\n\n.styles_flex1__l0kyP {\n  flex: 1 1;\n}\n\n.styles_collapsed__XpRgz {\n  visibility: hidden;\n  height: 0;\n}\n\n.styles_alignSelfEnd__n8H7S {\n  align-self: end;\n}\n\n.styles_alignItemsCenter__xceVk {\n  align-items: center;\n}\n\n.styles_displayFlex__DzRMJ {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/containers/FlexChild/styles.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".component {\n}\n\n.flex1 {\n  flex: 1;\n}\n\n.collapsed {\n  visibility: hidden;\n  height: 0;\n}\n\n.alignSelfEnd {\n  align-self: end;\n}\n\n.alignItemsCenter {\n  align-items: center;\n}\n\n.displayFlex {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__Eb2-Q",
	"flex1": "styles_flex1__l0kyP",
	"collapsed": "styles_collapsed__XpRgz",
	"alignSelfEnd": "styles_alignSelfEnd__n8H7S",
	"alignItemsCenter": "styles_alignItemsCenter__xceVk",
	"displayFlex": "styles_displayFlex__DzRMJ"
};
export default ___CSS_LOADER_EXPORT___;
