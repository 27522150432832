import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./styles.module.css";
import { IRegisterFormData } from "../../state/akita/session/session.model";
import { SessionContext } from "../../state/akita/session/session.context";
import { Flex } from "../../components/containers/Flex/Flex";
import { Spacing } from "../../components/spacing/component";
import { useRegistrationValidationSchema } from "../../hooks/formValidationSchema.hook";
import { LoginInput } from "../../components/inputs/LoginInput/LoginInput";
import RText from "../../components/fonts/RText/RText";
import { CircularProgress } from "@mui/material";

interface IProps {
  showLogin: () => void;
}

const RegisterForm: React.FC<IProps> = ({ showLogin }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const { registerUser } = useContext(SessionContext);
  const registrationValidationSchema = useRegistrationValidationSchema();
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(registrationValidationSchema),
  });

  const onSubmit = (data: IRegisterFormData) => {
    setLoading(true);
    registerUser(data).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Flex column>
      {/* <RText fontSize="22" className={styles.formTitle}>
                {t("register")}
            </RText>
            <Spacing pt="18" /> */}

      <form>
        <Controller
          defaultValue=""
          name="email"
          control={control}
          render={({ ref, ...props }) => (
            <LoginInput
              inputRef={ref}
              error={errors.email?.message}
              placeholder={t("email")}
              label={t("email")}
              {...props}
            />
          )}
        />
        <Spacing pb="15" />
        <Controller
          defaultValue=""
          name="password"
          control={control}
          render={({ ref, ...props }) => {
            return (
              <>
                <LoginInput
                  inputRef={ref}
                  type="password"
                  error={errors.password?.message}
                  placeholder={t("password")}
                  label={t("password")}
                  {...props}
                />
                <Spacing pb="5" />
                <PasswordStrengthBar password={props.value} shortScoreWord={""} />
              </>
            );
          }}
        />
        <Spacing pb="15" />
        <Controller
          defaultValue=""
          name="confirmPassword"
          control={control}
          render={({ ref, ...props }) => (
            <LoginInput
              inputRef={ref}
              type="password"
              error={errors.confirmPassword?.message}
              placeholder={t("confirmPassword")}
              label={t("confirmPassword")}
              {...props}
            />
          )}
        />
        <Spacing pb="50" />

        <button className={styles.submitButton} onClick={handleSubmit(onSubmit)}>
          {loading && <CircularProgress sx={{ color: "white" }} size={16} />}
          {!loading && <>{t("register")}</>}
        </button>
      </form>

      <Spacing pb="30" />
      <Flex onClick={showLogin} pointer className={styles.link}>
        <RText fontSize="12" white underlined>
          {t("showLogin")}
        </RText>
      </Flex>
    </Flex>
  );
};

export default RegisterForm;
