// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tag__fbAgS {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/CorporatesTags/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".tag {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "styles_tag__fbAgS"
};
export default ___CSS_LOADER_EXPORT___;
