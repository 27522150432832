// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_usersTagMainContainer__FZFz\\+ {\n  width: 100%;\n}\n\n.styles_tagActionContainer__9\\+u9y {\n  opacity: 0.8;\n  padding-right: 10px;\n}\n\n.styles_iconContainer__k-uXE {\n  min-width: 18px;\n  max-width: 18px;\n  height: 18px;\n  border-radius: 100%;\n}\n\n.styles_deleteIconContainer__0FLrZ {\n  padding-right: 10px;\n}\n\n.styles_deleteTagIcon__V80iV {\n  width: 16px;\n  height: 18px;\n  opacity: 0.8;\n}\n\n.styles_deleteTagText__zpNGA {\n  color: var(--immutable_error_red_dark);\n}\n\n.styles_errorIcon__YfZsd {\n  width: 18px;\n  height: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/tags/user-tag/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".usersTagMainContainer {\n  width: 100%;\n}\n\n.tagActionContainer {\n  opacity: 0.8;\n  padding-right: 10px;\n}\n\n.iconContainer {\n  min-width: 18px;\n  max-width: 18px;\n  height: 18px;\n  border-radius: 100%;\n}\n\n.deleteIconContainer {\n  padding-right: 10px;\n}\n\n.deleteTagIcon {\n  width: 16px;\n  height: 18px;\n  opacity: 0.8;\n}\n\n.deleteTagText {\n  color: var(--immutable_error_red_dark);\n}\n\n.errorIcon {\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersTagMainContainer": "styles_usersTagMainContainer__FZFz+",
	"tagActionContainer": "styles_tagActionContainer__9+u9y",
	"iconContainer": "styles_iconContainer__k-uXE",
	"deleteIconContainer": "styles_deleteIconContainer__0FLrZ",
	"deleteTagIcon": "styles_deleteTagIcon__V80iV",
	"deleteTagText": "styles_deleteTagText__zpNGA",
	"errorIcon": "styles_errorIcon__YfZsd"
};
export default ___CSS_LOADER_EXPORT___;
