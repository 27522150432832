import { useContext } from "react";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import RLink from "../../../../components/link/RLink";
import StarRating from "../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../components/spacing/component";
import { IHubFooterItemData } from "../../../../shared/interfaces/hub-footer-item-data.interface";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useTranslation } from "react-i18next";
import { intlFormat } from "date-fns";
import i18n from "../../../../localization/i18n";

interface IProps {
  item: IHubFooterItemData;
}

export default function AnniversaryItem({ item }: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { t } = useTranslation();

  return (
    <Flex column justifyContentSpaceBetween className={styles.companyNameContainer}>
      <StarRating affinity={item.company.affinity_score} spacing="5" iconHeight="12" />
      <Spacing pb="4" />
      <RLink
        path={{
          pathname: `/home/search/corporate-dossier/${item.company.id}`,
          state: { fromHub: true },
        }}
      >
        <RText
          className={styles.companyName}
          fontSize={isSmallerScreen ? "11" : "13"}
          bold
          immutableWhite
        >
          {item.company.company_name}
        </RText>
        <RText fontSize={isSmallerScreen ? "11" : "13"} immutableWhite>
          <>
            {item.company.zip_code ? item.company.zip_code.toString() : ""} {item.company.city}
          </>
        </RText>
      </RLink>

      <Spacing pb="4" />
      <div className={styles.divider} />
      <Spacing pb="2" />

      {item.companyNewsTitle && (
        <div className={styles.newsTitleContainer}>
          <RText immutableWhite fontSize={isSmallerScreen ? "11" : "13"}>
            {`${item.companyNewsTitle.substring(0, 60)}...`}
          </RText>
        </div>
      )}
      <RText fontSize={isSmallerScreen ? "11" : "13"} immutableWhite>
        <>
          {t("milestoneText", {
            milestone_year: item.company.milestone_year,
            month: intlFormat(
              new Date(item.company.anniversary_date),
              {
                month: "long",
              },
              { locale: i18n.language },
            ),
          })}
        </>
      </RText>
    </Flex>
  );
}
