import React, { useContext } from "react";
import CloseIconBlack from "../../../../assets/CloseIconBlack.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../components/spacing/component";
import styles from "./styles.module.css";
import { CustomTagsContext } from "../../../../entities/custom-tags/state/custom-tags.context";
import { ITag } from "../../../../entities/custom-tags/state/custom-tag.model";
import tagsStyles from "../../../../components/tags/styles.module.css";

interface IProps {
  tag: ITag;
  searchByTag: (tagName: string) => void;
}

const SelectionItem = ({ tag, searchByTag }: IProps) => {
  const customTagsContext = useContext(CustomTagsContext);

  const handleSearch = () => {
    searchByTag(tag.name);
  };

  const handleDeleteTag = () => {
    customTagsContext.deleteCustomTag(tag.id);
  };

  return (
    <Flex alignItemsCenter className={styles.component}>
      <Flex pointer alignItemsCenter onClick={handleSearch}>
        <div
          className={tagsStyles.defaultTag}
          style={{
            backgroundColor: tag.color_code,
            border: "1px solid var(--color_background_black)",
          }}
        />
        <Spacing pl="8" />
        <RText immutableBlack fontSize="16" bold>
          {tag.name}
        </RText>
      </Flex>

      <Spacing pl="16" />

      <div onClick={handleDeleteTag} className={styles.closeIcon}>
        <img src={CloseIconBlack} alt="" />
      </div>
    </Flex>
  );
};

export default SelectionItem;
