import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { IHubFilterGroup } from "../../../../shared/interfaces/hub-filter-group.interface";
import HubFilter from "../HubFilter/HubFilter";
import HubFiltersGroupCount from "./HubFiltersGroupCount/HubFiltersGroupCount";
import HubMultiLevelFilterValuesContainer from "./HubMultiLevelFilterValuesContainer/HubMultiLevelFilterValuesContainer";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  filtersGroup: IHubFilterGroup;
  isExecutivesHub?: boolean;
}

const HubFiltersGroup = ({ filtersGroup, isExecutivesHub }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <>
      <Flex className={styles.component}>
        <Flex column className={styles.filtersContent}>
          <Flex
            justifyContentSpaceBetween
            alignItemsCenter
            style={{
              paddingLeft: "20px",
              paddingRight: "10px",
            }}
            className={`${styles.groupTitle} ${
              isSmallerScreen && styles.groupTitle__Responsive
            }`}
          >
            <RText bold fontSize={isSmallerScreen ? "11" : "13"}>
              {t(filtersGroup.title)}
            </RText>
            <HubFiltersGroupCount
              filtersGroup={filtersGroup}
              isExecutivesHub={isExecutivesHub}
            />
          </Flex>

          <Flex column>
            {filtersGroup.filters.map(({ filter, filterUI }, index) => {
              return (
                <HubFilter
                  key={index}
                  filter={filter}
                  filterUI={filterUI}
                  isExecutivesHub={isExecutivesHub}
                />
              );
            })}
          </Flex>
        </Flex>
        {!isSmallerScreen && (
          <HubMultiLevelFilterValuesContainer
            isExecutivesHub={isExecutivesHub}
            filtersGroup={filtersGroup}
          />
        )}
      </Flex>
    </>
  );
};

export default HubFiltersGroup;
