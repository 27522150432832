import { ISelectedValuesRange } from "../../shared/interfaces/selected-value-types.interface";
import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";

export const defaultDynamicRangeSelectionReferents = (
  selectedValue: ISelectedValuesRange,
  filterValues: Array<ICorporatesFilterValue>,
) => {
  const fromReferent = filterValues.find(
    (filterValue) => selectedValue.from === filterValue.value_key,
  );
  const toReferent = filterValues.find(
    (filterValue) => selectedValue.to === filterValue.value_key,
  );
  return { fromReferent, toReferent };
};
