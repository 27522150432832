import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CorporatesFiltersContext } from "../../../../../entities/corporates-filters/state/corporates-filters.context";
import { hubMultiLevelFilterKeys } from "../../../../../constants/hub";
import { Spacing } from "../../../../../components/spacing/component";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import HubMultiLevelFilterValues from "../HubMultiLevelFilterValues/HubMultiLevelFilterValues";
import { ReactComponent as CloseIconBlue } from "../../../../../assets/CloseIconBlue.svg";
import styles from "../styles.module.css";
import RText from "../../../../../components/fonts/RText/RText";
import { IHubFilterGroup } from "../../../../../shared/interfaces/hub-filter-group.interface";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { ExecutivesFiltersContext } from "../../../../../entities/executives-filters/state/executives-filters.context";

interface IProps {
  filtersGroup: IHubFilterGroup;
  isExecutivesHub?: boolean;
}

const HubMultiLevelFilterValuesContainer = ({ filtersGroup, isExecutivesHub }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const corporatesFiltersContext = useContext(CorporatesFiltersContext);
  const executivesFiltersContext = useContext(ExecutivesFiltersContext);

  const onCloseMultiLevelFilter = (filterId: string) => () => {
    if (isExecutivesHub) {
      executivesFiltersContext.deselectMultiLevelHubFilter(filterId);
    } else {
      corporatesFiltersContext.deselectMultiLevelHubFilter(filterId);
    }
  };

  const onClearMultiLevelFilterValues = (filterId: string) => () => {
    if (isExecutivesHub) {
      executivesFiltersContext.deselectMultiLevelHubFilter(filterId);
      executivesFiltersContext.deselectMultiLevelHubFilterValues(filterId);
    } else {
      corporatesFiltersContext.deselectMultiLevelHubFilter(filterId);
      corporatesFiltersContext.deselectMultiLevelHubFilterValues(filterId);
    }
  };

  const selectedFilters = filtersGroup.filters.filter(
    ({ filter, filterUI }) => filterUI.selected,
  );

  const selectedMultiLevelFilter = selectedFilters.find(({ filter, filterUI }) =>
    hubMultiLevelFilterKeys.includes(filter.id),
  );

  if (!selectedMultiLevelFilter) {
    return null;
  }

  return (
    <Spacing
      pl="10"
      className={isSmallerScreen ? styles.multiLevelFilterValuesContainer__Responsive : ""}
    >
      <Flex pointer alignItemsStart>
        <HubMultiLevelFilterValues
          isExecutivesHub={isExecutivesHub}
          selectedFilter={selectedMultiLevelFilter}
        />
        <CloseIconBlue
          color={colors.secondaryDarkColor}
          onClick={onCloseMultiLevelFilter(selectedMultiLevelFilter.filter.id)}
          className={styles.closeMultiLevelFilterIcon}
        />
      </Flex>
      <Spacing pt="40" />
      <Flex
        pointer
        justifyContentEnd
        onClick={onClearMultiLevelFilterValues(selectedMultiLevelFilter.filter.id)}
      >
        <RText primaryBlue fontSize="15">
          {t("clearAll")}
        </RText>
        <Spacing pr="20" />
      </Flex>
    </Spacing>
  );
};

export default HubMultiLevelFilterValuesContainer;
