import { Box, Button, Collapse, Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "../../components/containers/Flex/Flex";
import RHeading4 from "../../components/fonts/RHeading4/RHeading4";
import Text from "../../components/fonts/Text/Text";
import { CorporatesFiltersContext } from "../../entities/corporates-filters/state/corporates-filters.context";
import {
  useHubCorporates,
  useHubFirstLoad,
} from "../../modules/hub/hooks/hub-corporates.hook";
import HubMain from "./HubMain/HubMain";
import HubSidePanel from "./HubMain/HubSidePanel/HubSidePanel";
import { useHubMarkers } from "../../modules/hub/hooks/hub-markers.hook";
import HubFooter from "./HubFooter/HubFooter";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import useAppColors from "../../hooks/app-colors.hook";
import { MapContext } from "../../context/MapContext";

const HubPage = () => {
  const { t } = useTranslation();
  const { hubMarkers } = useHubMarkers();
  const [filteredHubMarkers, setFilteredHubMarkers] = useState(null);
  // const [hubMarkersCenter, setHubMarkersCenter] = useState(null);
  const { state } = useContext(MapContext);

  const { colors } = useAppColors();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const [showNews, setShowNews] = useState<boolean>(upMd);

  function arePointsNear(checkPoint: any, centerPoint: any, km: any) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lon) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  useEffect(() => {
    if (!state.center || !state.center?.lat || !state.center?.lng) {
      setFilteredHubMarkers(hubMarkers);

      return;
    }

    setFilteredHubMarkers(() =>
      hubMarkers.filter((item: any) =>
        arePointsNear(item.coordinates, state.center, state.radius),
      ),
    );
  }, [hubMarkers, state.radius, state.center, state]);

  const { fetchHubCorporates, hubErrorNoData } = useHubCorporates();
  const { firstLoad } = useHubFirstLoad();
  const corporatesFiltersService = useContext(CorporatesFiltersContext);

  useEffect(() => {
    if (firstLoad) {
      // Fetch corporates after filters, because corporates need to
      // update filter facets AFTER filters are already saved to the store
      corporatesFiltersService.fetchHubFilters().then(() => {
        fetchHubCorporates();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  if (hubErrorNoData) {
    return (
      <Flex fullHeight justifyContentCenter alignItemsCenter column>
        <RHeading4>{t("noDataAvailableForCurrentUser")}</RHeading4>
        <Flex pointer onClick={fetchHubCorporates}>
          <Text primaryBlue underlinePrimary>
            {t("tryAgain")}
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item md={8} xs={12} minHeight={`calc(100vh - ${upMd ? "70px" : "120px"})`}>
          <HubMain showNews={showNews} />
        </Grid>
        <Grid
          item
          md={4}
          maxHeight="calc(100vh - 70px)"
          overflow="scroll"
          sx={{ overflowX: "hidden", display: { xs: "none", md: "block" } }}
        >
          <HubSidePanel hubMarkers={filteredHubMarkers ?? hubMarkers} showNews={showNews} />
        </Grid>
        <Box
          position="absolute"
          bottom={0}
          width="100%"
          zIndex={1002}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Button
            onClick={() => setShowNews((prevState) => !prevState)}
            sx={{
              position: "absolute",
              left: (window.innerWidth / 12) * 4,
              top: -35,
              borderRadius: "6px 6px 0 0",
              backgroundColor: colors.backgroundBlackColor,
              color: colors.backgroundWhiteColor,

              "&:hover": {
                backgroundColor: colors.backgroundBlackColor,
              },
            }}
          >
            {showNews ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </Button>
          <Collapse in={showNews}>
            <Paper
              elevation={10}
              sx={{ position: "relative", backgroundColor: "var(--color_background_white)" }}
            >
              <HubFooter />
            </Paper>
          </Collapse>
        </Box>
      </Grid>
    </>
  );
};

export default HubPage;
