import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Flex } from "../../../components/containers/Flex/Flex";
import RText from "../../../components/fonts/RText/RText";
import Spinner from "../../../components/other/Spinner/Spinner";
import { useHubMarkers } from "../../../modules/hub/hooks/hub-markers.hook";
import { calculateMapCenterAsAverageFromMultipleLocations } from "../../../helpers/map.helper";
import HubMap from "./HubMap/HubMap";
import {
  Box,
  Button,
  Collapse,
  SwipeableDrawer,
  Hidden,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
  Stack,
  Slider,
  Autocomplete,
  TextField,
  ClickAwayListener,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  FilterList,
  List,
  Close,
  GpsNotFixed,
} from "@mui/icons-material";
import HubKPIs from "../HubHeader/HubKPIs/HubKPIs";
// import HubFooter from "../HubFooter/HubFooter";
import useAppColors from "../../../hooks/app-colors.hook";
import HubFiltersGroup from "../HubHeader/HubFiltersGroup/HubFiltersGroup";
import { useCorporatesFilterWithHubUIGroups } from "../../../entities/corporates-filters/hooks/corporates-filter-with-ui-groups.hook";
import HubMultiLevelFilterValuesContainer from "../HubHeader/HubFiltersGroup/HubMultiLevelFilterValuesContainer/HubMultiLevelFilterValuesContainer";
import HubSidePanelSortBy, {
  HUB_SIDE_PANEL_SORT_BY,
} from "./HubSidePanel/HubSidePanelHeader/HubSidePanelSortBy/HubSidePanelSortBy";
import { formatNumberWithDotSeparator } from "../../../helpers/number.helper";
import { ICorporateMarkerData } from "../../../entities/corporates/interfaces/corporate-marker-data.interface";
import { ICorporateHubMarker } from "../../../entities/corporates/interfaces/hub-marker.interface";
import { corporatesApiService } from "../../../api/services/corporates-api.service";
import HubSidePanel from "./HubSidePanel/HubSidePanel";
// import { useCorporatesFilterWithSearchUIRows } from "../../../entities/corporates-filters/hooks/corporates-filter-with-ui-rows.hook";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { MapContext } from "../../../context/MapContext";

const provider = new OpenStreetMapProvider();

const SwipeLine = () => (
  <Box
    sx={{
      width: 50,
      height: 4,
      background: "var(--color_gray)",
      margin: "0 auto",
      borderRadius: 3,
    }}
  />
);

const HubMain = ({ showNews }: { showNews: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const swissURL = window.location.pathname.includes("/ch"); // to be changed when refactoring
  const { colors } = useAppColors();
  const { filterWithUIGroups, loadingCorporatesFilters } =
    useCorporatesFilterWithHubUIGroups();
  const { state, setRadius, setCenter } = useContext(MapContext);

  const [showStats, setShowStats] = useState<boolean>(upMd);

  const [hubMarkersCenter, setHubMarkersCenter] = useState(null);
  const { loadingHubCorporates, hubMarkers } = useHubMarkers();

  // const { getAll } = useCorporatesFilterWithSearchUIRows();

  // const [radiusSearchItems, setRadiusSearchItems] = useState([])
  // const [circleCenter, setCircleCenter] = useState<null | { lat: number, lng: number }>(null);
  // const [circleRadius, setCircleRadius] = useState<number>(20);
  const onCircleRadiusChange = (event: Event, value: any, activeThumb: number): void => {
    setRadius(value);
  };

  const [showCircleRadius, setShowCircleRadius] = useState(upMd);

  const [companies, setCompanies] = useState<ICorporateMarkerData[]>([]);
  const filteredResults = useMemo(() => mapMarkerDataForSidePanel(hubMarkers), [hubMarkers]);

  const getCoordinates = async (query: string) => {
    const res = await provider.search({ query });

    const lat = res[0].y;
    const lng = res[0].x;

    return { lng, lat };
  };

  const [placeInput, setPlaceInput] = useState<string | null>(null);
  const autocompleteRef = useRef(null);

  const flyTo = async () => {
    const coordinates = await getCoordinates(
      `${placeInput} ${swissURL ? "Switzerland" : "Germany"}`,
    );

    setCenter(coordinates);
    setRadius(5);
  };

  useEffect(() => {
    placeInput && flyTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeInput]);

  useEffect(() => {
    let isMounted = true;

    corporatesApiService
      .getCorporatesLatestSignal({
        companies:
          filteredResults &&
          filteredResults.map((company: ICorporateMarkerData) => +company.corporateId),
      })
      .then((res: any) => {
        if (!isMounted) {
          return;
        }
        // setCompaniesTrends(mapCompaniesTrend(res.data.companies));
      })
      .catch((e) => console.log(e));

    setCompanies(filteredResults && [...filteredResults]);

    return () => {
      isMounted = false;
    };
  }, [filteredResults]); // eslint-disable-line

  const [sortBy, setSortBy] = useState<HUB_SIDE_PANEL_SORT_BY>(
    HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS,
  );

  const onSortByName = useCallback(() => {
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS) {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.NAME_DESC);
    } else if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_DESC) {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.NAME_ASC);
    } else {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS);
    }
  }, [sortBy]);

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const openFiltersDrawer = () => setShowFiltersDrawer(true);
  const closeFiltersDrawer = () => setShowFiltersDrawer(false);
  const [showResultsDrawer, setShowResultsDrawer] = useState(false);
  const openResultsDrawer = () => setShowResultsDrawer(true);
  const closeResultsDrawer = () => setShowResultsDrawer(false);

  const onSearchInputChange = async (params: any, value: any) => {
    if (!value) {
      setRadius(null);
      setCenter(null);

      return;
    }

    setPlaceInput(value.id ?? value.label ?? value);
  };

  useEffect(() => {
    let isMounted = true;

    if (!hubMarkers || !isMounted) {
      return;
    }

    const _hubMarkersCenter = calculateMapCenterAsAverageFromMultipleLocations(
      hubMarkers.map(({ coordinates }) => coordinates),
    );
    setHubMarkersCenter(_hubMarkersCenter);

    return () => {
      isMounted = false;
    };
  }, [hubMarkers]);

  const hubCorporatesExist = hubMarkers && hubMarkersCenter;
  const hubMarkersMemoized = useMemo(() => hubMarkers, [hubMarkers]);
  const hubMarkersCenterMemoized = useMemo(() => hubMarkersCenter, [hubMarkersCenter]);

  const renderRadiusDrawer = () => (
    <Paper
      elevation={3}
      sx={{
        background: "var(--color_gray)",
        position: "absolute",
        top: upMd ? (showStats ? 150 : 5) : 70,
        left: 20,
        zIndex: 401,
        minWidth: 250,
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          const { value } = autocompleteRef.current;

          if (value.length) setPlaceInput(value);
        }}
      >
        <Autocomplete
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={autocompleteRef}
              sx={{
                background: "var(--color_gray_light)",
                borderRadius: "999px",
                ".MuiOutlinedInput-root": {
                  borderRadius: "999px",
                  py: 0,
                },
                input: {
                  padding: 0,
                  fontSize: "13px",
                },
              }}
              placeholder={t("enterTheCityOrZipCode")}
            />
          )}
          onChange={onSearchInputChange}
          freeSolo
        />
      </ClickAwayListener>
      <Stack spacing={3} direction="row" alignItems="center" px={2}>
        <Typography sx={{ fontSize: 13 }}>Radius</Typography>
        <Slider
          disabled={!state.center}
          min={1}
          max={100}
          value={state.radius}
          valueLabelDisplay="auto"
          onChange={onCircleRadiusChange}
          marks={[
            { value: 1 },
            { value: 2 },
            { value: 3 },
            { value: 4 },
            { value: 5 },
            { value: 10 },
            { value: 15 },
            { value: 20 },
            { value: 25 },
            { value: 30 },
            { value: 40 },
            { value: 50 },
            { value: 60 },
            { value: 70 },
            { value: 90 },
            { value: 100 },
          ]}
        />
      </Stack>
    </Paper>
  );

  const renderHubMap = () => {
    if (loadingHubCorporates || loadingHubCorporates === null) {
      return <Spinner />;
    }

    if (!hubCorporatesExist) {
      return (
        <RText className={styles.noDataText} fontSize="30">
          {t("noHubCorporates")}
        </RText>
      );
    }

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {showCircleRadius && renderRadiusDrawer()}
        <HubMap
          width="100%"
          height="100%"
          zoom={9}
          hubMarkers={hubMarkersMemoized}
          center={hubMarkersCenterMemoized}
          showStats={showStats}
          showNews={showNews}
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles.component}>
        <Flex alignItemsCenter justifyContentSpaceBetween className={styles.mapContainer}>
          <Box>
            <Hidden only={["md", "lg", "xl"]}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: 15,
                  left: 15,
                  zIndex: 99999999,
                  background: "white",
                }}
                onClick={openFiltersDrawer}
                disableRipple
              >
                <FilterList style={{ color: "black" }} />
              </IconButton>
              <IconButton
                sx={{
                  position: "absolute",
                  top: 15,
                  left: 75,
                  zIndex: 99999999,
                  background: "white",
                }}
                onClick={() => setShowCircleRadius((prevState) => !prevState)}
                disableRipple
              >
                <GpsNotFixed style={{ color: "black" }} />
              </IconButton>
              <Button
                sx={{
                  position: "absolute",
                  bottom: 15,
                  left: "50%",
                  zIndex: 99999999,
                  background: "white",
                  transform: "translate(-50%)",
                  borderRadius: 5000,
                  padding: "15px 25px",
                  ":hover": {
                    background: "white",
                  },
                  ":focus": {
                    background: "white",
                  },
                }}
                startIcon={<List />}
                onClick={openResultsDrawer}
              >
                Show List ({hubMarkers && hubMarkers.length})
              </Button>
              <SwipeableDrawer
                anchor="top"
                open={showFiltersDrawer}
                onClose={closeFiltersDrawer}
                onOpen={openFiltersDrawer}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  px={2}
                  pt={2}
                  m={0}
                >
                  <Grid item>
                    <Grid container alignItems="center" gap={1}>
                      <Grid item>
                        <FilterList />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" fontWeight="bold">
                          {t("filter")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={closeFiltersDrawer}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    padding: "20px 20px 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {loadingCorporatesFilters ? (
                    <div />
                  ) : (
                    filterWithUIGroups &&
                    filterWithUIGroups.map((filterGroup: any) => (
                      <Fragment key={filterGroup.title}>
                        <HubFiltersGroup filtersGroup={filterGroup} />
                      </Fragment>
                    ))
                  )}
                </Box>
                <Box>
                  {filterWithUIGroups &&
                    filterWithUIGroups.map((filterGroup) => {
                      return (
                        <HubMultiLevelFilterValuesContainer
                          key={filterGroup.title}
                          filtersGroup={filterGroup}
                        />
                      );
                    })}
                </Box>
                <Box sx={{ pt: 3, pb: 1 }}>
                  <SwipeLine />
                </Box>
                <Flex
                  alignItemsCenter
                  justifyContentSpaceBetween
                  className={styles.filterResultsContainer}
                >
                  <RText bold fontSize="13">
                    {companies?.length &&
                      `${formatNumberWithDotSeparator(companies.length)} ${t("companies")}`}
                  </RText>
                  <HubSidePanelSortBy sortBy={sortBy} onSortByName={onSortByName} />
                </Flex>
              </SwipeableDrawer>
              <SwipeableDrawer
                anchor="bottom"
                open={showResultsDrawer}
                onClose={closeResultsDrawer}
                onOpen={openResultsDrawer}
              >
                <Box sx={{ pt: 1, pb: 3 }} m={0}>
                  <SwipeLine />
                </Box>
                <HubSidePanel hubMarkers={hubMarkersMemoized} hideFilter />
              </SwipeableDrawer>
            </Hidden>
          </Box>
          <Box
            position="absolute"
            top={0}
            minWidth="100%"
            zIndex={1001}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Collapse in={showStats}>
              <Paper
                elevation={0}
                sx={{
                  borderRadius: 0,
                  borderBottom: "1px solid #D6D6D6",
                  backgroundColor: "var(--color_background_white)",
                }}
              >
                <HubKPIs />
              </Paper>
            </Collapse>
            <Button
              onClick={() => setShowStats((prevState) => !prevState)}
              sx={{
                position: "absolute",
                left: "50%",
                borderRadius: "0 0 6px 6px",
                backgroundColor: colors.backgroundWhiteColor,
                color: colors.backgroundBlackColor,

                "&:hover": {
                  backgroundColor: colors.backgroundWhiteColor,
                },
              }}
            >
              {showStats ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </Box>
          {renderHubMap()}
        </Flex>
      </div>
    </>
  );
};

export default HubMain;
// function mapMarkerDataForSidePanel(hubMarkers: import("../../../entities/corporates/interfaces/hub-marker.interface").ICorporateHubMarker[]): any {
//     throw new Error("Function not implemented.");
// }

const mapMarkerDataForSidePanel = (
  hubMarkers: ICorporateHubMarker[],
): ICorporateMarkerData[] => {
  return (hubMarkers || []).flatMap((marker: ICorporateHubMarker) => [
    ...marker.corporatesData,
  ]);
};
