import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";

export const inputChangeCallbackByType: {
  [key: string]: (
    oldValue: string,
    newValue: string,
    items: Array<ICorporatesFilterValue>,
  ) => string;
} = {
  industry_sector_codes_full: (
    oldValue: string,
    newValue: string,
    items: Array<ICorporatesFilterValue>,
  ) => {
    // const upperCaseNewValue = newValue.toUpperCase();
    // alert(newValue)
    // if (
    //     newValue &&
    //     !items.some(
    //         (itm) =>
    //             itm.value_key
    //                 ?.toUpperCase()
    //                 .includes(upperCaseNewValue) ||
    //             itm.label?.toUpperCase().includes(upperCaseNewValue) ||
    //             getDisplayValue("industry_sector_codes_full")(itm)
    //                 ?.toUpperCase()
    //                 .includes(upperCaseNewValue)
    //     )
    // ) {
    //     return oldValue;
    // }
    return newValue;
  },
};
