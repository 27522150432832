import React, { useContext } from "react";
import RText from "../../../../../components/fonts/RText/RText";
import { IHubFilterGroup } from "../../../../../shared/interfaces/hub-filter-group.interface";
import { useHubMarkers } from "../../../../../modules/hub/hooks/hub-markers.hook";
import { useHubNews } from "../../../../../modules/hub/hooks/hub-news.hook";
import { formatNumberWithDotSeparator } from "../../../../../helpers/number.helper";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useExecutiveHubMarkers } from "../../../../../modules/executives-hub/hooks/executives-hub-markers.hook";

interface IProps {
  filtersGroup: IHubFilterGroup;
  isExecutivesHub?: boolean;
}

const HubFiltersGroupCount = ({ filtersGroup, isExecutivesHub }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { hubMarkers } = useHubMarkers();
  const { executiveHubMarkers } = useExecutiveHubMarkers();
  const { cumulativeNewsCount } = useHubNews();

  const stringifyCount = (count: number): string => {
    return formatNumberWithDotSeparator(count);
  };

  if (filtersGroup.title === "corporates") {
    let corporatesCount = 0;

    (hubMarkers || []).forEach((m) => m.corporatesData.forEach((cd) => corporatesCount++));

    return (
      <RText fontSize={isSmallerScreen ? "11" : "12"} bold>
        {stringifyCount(corporatesCount)}
      </RText>
    );
  }

  if (filtersGroup.title === "executivesFilterGroup") {
    let executivesCount = 0;

    (executiveHubMarkers || []).forEach((m) =>
      m.executivesData.forEach((ed) => executivesCount++),
    );

    return (
      <RText fontSize={isSmallerScreen ? "11" : "12"} bold>
        {stringifyCount(executivesCount)}
      </RText>
    );
  }

  if (filtersGroup.title === "marketDrivers") {
    let count = 0;

    if (isExecutivesHub) {
      (executiveHubMarkers || []).forEach((m) => {
        m.executivesData.forEach((e) => {
          // if (e.wealth_score > 0) count++;
          count++;
        });
      });
    } else {
      (hubMarkers || []).forEach((m) => {
        m.corporatesData.forEach((c) => {
          // if (c.affinity > 0) count++;
          count++;
        });
      });
    }

    return count !== null && count !== undefined ? (
      <RText fontSize={isSmallerScreen ? "11" : "12"} bold>
        {stringifyCount(count || 0)}
      </RText>
    ) : null;
  }

  if (filtersGroup.title === "news") {
    return (
      <RText fontSize={isSmallerScreen ? "11" : "12"} bold>
        {stringifyCount(cumulativeNewsCount)}
      </RText>
    );
  }

  return (
    <RText fontSize={isSmallerScreen ? "11" : "12"} bold>
      {`${filtersGroup.count}`}
    </RText>
  );
};

export default HubFiltersGroupCount;
