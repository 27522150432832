// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_customTooltip__RivJi {\n  border: 1px solid var(--color_gray);\n  padding: 10px;\n  background-color: var(--color_background_white);\n}\n\n.styles_tooltipBarValue__kugwS {\n  color: var(--color_secondary_dark) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/corporates/dossier/components/side-navigation-item-content/headcount/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,aAAa;EACb,+CAA+C;AACjD;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".customTooltip {\n  border: 1px solid var(--color_gray);\n  padding: 10px;\n  background-color: var(--color_background_white);\n}\n\n.tooltipBarValue {\n  color: var(--color_secondary_dark) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTooltip": "styles_customTooltip__RivJi",
	"tooltipBarValue": "styles_tooltipBarValue__kugwS"
};
export default ___CSS_LOADER_EXPORT___;
