import React, { useContext } from "react";
import { ICorporatesFilterWithUI } from "../../../../../helpers/filters.helper";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import HubFilterValue from "../../HubFilterValue/HubFilterValue";
import { CorporatesFiltersContext } from "../../../../../entities/corporates-filters/state/corporates-filters.context";
import styles from "./styles.module.css";
import { ExecutivesFiltersContext } from "../../../../../entities/executives-filters/state/executives-filters.context";

interface IProps {
  selectedFilter: ICorporatesFilterWithUI;
  isExecutivesHub?: boolean;
}

const HubMultiLevelFilterValues = ({ selectedFilter, isExecutivesHub }: IProps) => {
  const corporatesFiltersContext = useContext(CorporatesFiltersContext);
  const executivesFiltersContext = useContext(ExecutivesFiltersContext);

  const toggleFilterValueOpen = (filterValueId: string) => {
    if (isExecutivesHub) {
      executivesFiltersContext.selectHubFilterValue(selectedFilter.filter.id, filterValueId);
    } else {
      corporatesFiltersContext.selectHubFilterValue(selectedFilter.filter.id, filterValueId);
    }
  };

  return (
    <Flex column className={styles.component}>
      {selectedFilter.filter.values.map((filterValue) => {
        return (
          <Flex key={filterValue.id} column>
            <HubFilterValue
              key={filterValue.id}
              filterType={selectedFilter.filter.type}
              filterValue={filterValue}
              //TODO fix taking into account other types!!!
              active={(selectedFilter.filterUI.selectedValues as Array<String>)
                .filter((value: any) => typeof value === "string" || value instanceof String)
                .includes(filterValue.label)}
              toggleOpen={() => toggleFilterValueOpen(filterValue.id)}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default HubMultiLevelFilterValues;
