import React from "react";
import styles from "./styles.module.css";
import { classNames } from "../../../../helpers/string.helper";

interface IProps {
  active?: boolean;
  activeMultiLevel?: boolean;
}

const HubFilterDot = ({ active, activeMultiLevel }: IProps) => {
  return <div className={classNames(styles, { active, activeMultiLevel })} />;
};

export default HubFilterDot;
