// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__grhUH {\n  min-width: 1px;\n  max-width: 1px;\n  height: 100%;\n  background-color: var(--color_gray);\n}\n\n.styles_primaryGreen__vq63E {\n  background-color: var(--color_primary_dark);\n}\n\n.styles_primaryBlue__P7eRa {\n  background-color: var(--color_secondary_dark);\n}\n\n.styles_gray__eXniB {\n  background-color: var(--color_gray);\n}\n\n.styles_black__cGbbV {\n  background-color: var(--color_background_black);\n}\n\n.styles_opacity__NUVM6 {\n  opacity: 0.1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dividers/VerticalDivider/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,YAAY;EACZ,mCAAmC;AACrC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,YAAY;AACd","sourcesContent":[".component {\n  min-width: 1px;\n  max-width: 1px;\n  height: 100%;\n  background-color: var(--color_gray);\n}\n\n.primaryGreen {\n  background-color: var(--color_primary_dark);\n}\n\n.primaryBlue {\n  background-color: var(--color_secondary_dark);\n}\n\n.gray {\n  background-color: var(--color_gray);\n}\n\n.black {\n  background-color: var(--color_background_black);\n}\n\n.opacity {\n  opacity: 0.1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__grhUH",
	"primaryGreen": "styles_primaryGreen__vq63E",
	"primaryBlue": "styles_primaryBlue__P7eRa",
	"gray": "styles_gray__eXniB",
	"black": "styles_black__cGbbV",
	"opacity": "styles_opacity__NUVM6"
};
export default ___CSS_LOADER_EXPORT___;
