// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_searchInputContainer__q5GSv {\n  height: 35px;\n  width: 100%;\n  border-radius: 20px;\n  padding: 0px 10px 0px 20px;\n  background-color: var(--color_gray_light);\n  min-width: 100%;\n}\n\n.styles_inputSearch__z3g3D {\n  width: 100%;\n  border: none;\n  outline: medium none invert;\n  outline: initial;\n  font-size: 13px;\n  font-weight: normal;\n  background-color: transparent;\n  color: var(--immutable_background_black);\n}\n\n.styles_deleteSearchQueryIcon__JFVtr,\n.styles_magnifierIcon__evz4n {\n  min-width: 14px;\n  max-width: 14px;\n  color: var(--immutable_background_black);\n}\n\n.styles_deleteSearchQueryIcon__JFVtr {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubMain/HubSidePanel/HubSidePanelHeader/HubSidePanelSearchInput/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,0BAA0B;EAC1B,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAAc;EAAd,gBAAc;EACd,eAAe;EACf,mBAAmB;EACnB,6BAA6B;EAC7B,wCAAwC;AAC1C;;AAEA;;EAEE,eAAe;EACf,eAAe;EACf,wCAAwC;AAC1C;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".searchInputContainer {\n  height: 35px;\n  width: 100%;\n  border-radius: 20px;\n  padding: 0px 10px 0px 20px;\n  background-color: var(--color_gray_light);\n  min-width: 100%;\n}\n\n.inputSearch {\n  width: 100%;\n  border: none;\n  outline: unset;\n  font-size: 13px;\n  font-weight: normal;\n  background-color: transparent;\n  color: var(--immutable_background_black);\n}\n\n.deleteSearchQueryIcon,\n.magnifierIcon {\n  min-width: 14px;\n  max-width: 14px;\n  color: var(--immutable_background_black);\n}\n\n.deleteSearchQueryIcon {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInputContainer": "styles_searchInputContainer__q5GSv",
	"inputSearch": "styles_inputSearch__z3g3D",
	"deleteSearchQueryIcon": "styles_deleteSearchQueryIcon__JFVtr",
	"magnifierIcon": "styles_magnifierIcon__evz4n"
};
export default ___CSS_LOADER_EXPORT___;
