// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__1yEso {\n  min-height: 28px;\n  display: flex;\n  align-items: center;\n  margin-right: 30px;\n  padding-right: 4px;\n  padding-left: 4px;\n}\n\n.styles_component__1yEso:hover {\n  cursor: pointer;\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_selected__kbsvQ {\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_ellipsisContainer__WaVUy {\n  flex: 1 1;\n  min-width: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/filters/FilterValue/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,mDAAmD;AACrD;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,SAAO;EACP,YAAY;AACd","sourcesContent":[".component {\n  min-height: 28px;\n  display: flex;\n  align-items: center;\n  margin-right: 30px;\n  padding-right: 4px;\n  padding-left: 4px;\n}\n\n.component:hover {\n  cursor: pointer;\n  background-color: var(--color_hover_selected_light);\n}\n\n.selected {\n  background-color: var(--color_hover_selected_light);\n}\n\n.ellipsisContainer {\n  flex: 1;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__1yEso",
	"selected": "styles_selected__kbsvQ",
	"ellipsisContainer": "styles_ellipsisContainer__WaVUy"
};
export default ___CSS_LOADER_EXPORT___;
