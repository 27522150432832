import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../components/spacing/component";
import { ICorporateMarkerData } from "../../../../entities/corporates/interfaces/corporate-marker-data.interface";
import HubSidePanelItem from "./HubSidePanelItem/HubSidePanelItem";
import { corporatesApiService } from "../../../../api/services/corporates-api.service";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import { useCorporatesFilterWithHubUIGroups } from "../../../../entities/corporates-filters/hooks/corporates-filter-with-ui-groups.hook";
import HubMultiLevelFilterValuesContainer from "../../HubHeader/HubFiltersGroup/HubMultiLevelFilterValuesContainer/HubMultiLevelFilterValuesContainer";
import HubSidePanelSortBy, {
  HUB_SIDE_PANEL_SORT_BY,
} from "./HubSidePanelHeader/HubSidePanelSortBy/HubSidePanelSortBy";
import HubSidePanelHeader from "./HubSidePanelHeader/HubSidePanelHeader";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { HubContext } from "../../../../modules/hub/state/hub.context";
import { ICorporateHubMarker } from "../../../../entities/corporates/interfaces/hub-marker.interface";
import { ITag } from "../../../../entities/custom-tags/state/custom-tag.model";
import { useAllTopProductTrends } from "../../../../entities/corporates-filters/hooks/top-product-trends.hook";
import HubFiltersGroup from "../../HubHeader/HubFiltersGroup/HubFiltersGroup";
import { Accordion, AccordionSummary, styled, Typography } from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ExpandMore, FilterList } from "@mui/icons-material";
import useAppColors from "../../../../hooks/app-colors.hook";

interface IProps {
  hubMarkers: ICorporateHubMarker[];
  hideFilter?: boolean;
  showNews?: boolean;
}

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  marginBottom: "-16px",
}));

const HubSidePanel = ({ hubMarkers, hideFilter = false, showNews }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const hubContext = useContext(HubContext);
  const { colors } = useAppColors();

  const filteredResults = useMemo(() => mapMarkerDataForSidePanel(hubMarkers), [hubMarkers]);
  const [companies, setCompanies] = useState<ICorporateMarkerData[]>([]);
  const [companiesTrends, setCompaniesTrends] = useState<ICorporatesLatestSignal[]>([]);

  const [sortBy, setSortBy] = useState<HUB_SIDE_PANEL_SORT_BY>(
    HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS,
  );
  const [searchQuery, setSearchQuery] = useState("");
  const { filterWithUIGroups, loadingCorporatesFilters } =
    useCorporatesFilterWithHubUIGroups();

  const [accordionState, setAccordionState] = useState<boolean>(true);
  const handleAccordion = () => setAccordionState((prevState) => !prevState);

  useEffect(() => {
    let isMounted = true;

    corporatesApiService
      .getCorporatesLatestSignal({
        companies: filteredResults.map(
          (company: ICorporateMarkerData) => +company.corporateId,
        ),
      })
      .then((res: any) => {
        if (!isMounted) {
          return;
        }
        setCompaniesTrends(mapCompaniesTrend(res?.data?.companies));
      })
      .catch((e) => console.log(e));

    setCompanies([...filteredResults]);

    return () => {
      isMounted = false;
    };
  }, [filteredResults]); // eslint-disable-line

  const findCompanyTrend = useCallback(
    (companyId: string) => {
      return (
        companiesTrends.find((company: ICorporatesLatestSignal) => {
          return company.companyId === companyId;
        }) ?? null
      );
    },
    [companiesTrends],
  );

  const getSortedCompanies = useCallback(() => {
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS) {
      let companiesWithClientTag: ICorporateMarkerData[] = [];
      let companiesWithLeadTag: ICorporateMarkerData[] = [];
      let companiesWithTrends: ICorporateMarkerData[] = [];
      let companiesWithoutTrends: ICorporateMarkerData[] = [];
      let companiesInLiquidation: ICorporateMarkerData[] = [];

      companies.forEach((company) => {
        const trend = findCompanyTrend(company.corporateId);

        if (company.tags.find((tag) => tag && tag?.name.toLowerCase() === "lead")) {
          companiesWithLeadTag.push(company);
        } else if (company.tags.find((tag) => tag && tag?.name.toLowerCase() === "client")) {
          companiesWithClientTag.push(company);
        } else if (!trend || trend.companyTrend === "") {
          companiesWithoutTrends.push(company);
        } else if (company.title.toLowerCase().includes("in liquidation")) {
          companiesInLiquidation.push(company);
        } else companiesWithTrends.push(company);
      });

      return [
        ...companiesWithClientTag,
        ...companiesWithLeadTag,
        ...companiesWithTrends,
        ...companiesWithoutTrends,
        ...companiesInLiquidation,
      ];
    }

    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_ASC) {
      const sortedByName = companies.sort((a, b) =>
        a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1,
      );
      return [...sortedByName];
    }

    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_DESC) {
      const sortedByName = companies.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
      );
      return [...sortedByName];
    }
  }, [companies, findCompanyTrend, sortBy]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  const onSearchCompanies = useCallback(() => {
    const trimmedSearchTerm = searchQuery?.trim() ?? "";

    if (trimmedSearchTerm.length === 0) {
      // setCompanies([...filteredResults]);
      hubContext.updateHubMapCorporateMarkers({
        showAll: true,
      });

      return;
    }

    const results = [...filteredResults].filter((company: ICorporateMarkerData) =>
      company.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    // setCompanies([...results]);
    hubContext.updateHubMapCorporateMarkers({
      hubCorporateIds: [...results].map(({ corporateId }) => corporateId),
    });
  }, [filteredResults, hubContext, searchQuery]);

  const onSortByName = useCallback(() => {
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS) {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.NAME_DESC);
    } else if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_DESC) {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.NAME_ASC);
    } else {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS);
    }
  }, [sortBy]);

  const onSearchQueryDelete = useCallback(() => {
    setSearchQuery("");

    // setCompanies([...filteredResults]);
    hubContext.updateHubMapCorporateMarkers({
      showAll: true,
    });
  }, [hubContext]);

  const { setHubRolloverCorporate } = useContext(HubContext);
  const allTrends = useAllTopProductTrends();
  const sortedCompanies = getSortedCompanies();

  const trendCompanies = sortedCompanies.filter((company) =>
    allTrends.some((trend) => trend.company?.id === company.corporateId && trend.newItem),
  );
  const nonTrendCompanies = sortedCompanies.filter(
    (company) =>
      !allTrends.some((trend) => trend.company?.id === company.corporateId && trend.newItem),
  );

  return (
    <div
      className={`${styles.sidePanelContainer} ${
        isSmallerScreen && styles.sidePanelContainer__Responsive
      }`}
    >
      {!hideFilter && (
        <>
          <Accordion
            expanded={accordionState}
            onChange={handleAccordion}
            elevation={0}
            sx={{ backgroundColor: "var(--color_background_white)" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: colors.secondaryDarkColor }} />}
            >
              <Flex alignItemsCenter>
                <FilterList sx={{ mr: 1.3, color: "var(--color_text_dark)" }} />
                <Typography fontWeight="bold" color="var(--color_text_dark)">
                  {t("filter")}
                </Typography>
              </Flex>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.groupsContainer}>
                {loadingCorporatesFilters ? (
                  <div />
                ) : (
                  filterWithUIGroups &&
                  filterWithUIGroups.map((filterGroup) => (
                    <Fragment key={filterGroup.title}>
                      <HubFiltersGroup filtersGroup={filterGroup} />
                    </Fragment>
                  ))
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {isSmallerScreen &&
            filterWithUIGroups &&
            filterWithUIGroups.map((filterGroup) => {
              return (
                <HubMultiLevelFilterValuesContainer
                  key={filterGroup.title}
                  filtersGroup={filterGroup}
                />
              );
            })}
        </>
      )}

      <div className={styles.sidePanelStickyHeader}>
        <HubSidePanelHeader
          searchQuery={searchQuery}
          sortBy={sortBy}
          onInputChange={onInputChange}
          onSearch={onSearchCompanies}
          onSortByName={onSortByName}
          onSearchQueryDelete={onSearchQueryDelete}
        />

        <Flex
          alignItemsCenter
          justifyContentSpaceBetween
          className={styles.filterResultsContainer}
        >
          <RText bold fontSize="13">
            {`${formatNumberWithDotSeparator(companies.length)} ${t("companies")}`}
          </RText>
          <HubSidePanelSortBy sortBy={sortBy} onSortByName={onSortByName} />
        </Flex>
      </div>

      <Spacing className={styles.companiesResultsContainer}>
        {sortedCompanies.length > 0 ? (
          <div
            className={`${styles.companiesContainer} ${
              showNews && styles.companiesContainer__padded
            }`}
            onMouseLeave={() => setHubRolloverCorporate(null)}
          >
            {trendCompanies.length > 0 &&
              mapFilteredResults(trendCompanies).map((company: ICompany, index: number) => (
                <Fragment key={company.corporateId}>
                  <HubSidePanelItem
                    isNew={true}
                    company={company}
                    companyTrendData={findCompanyTrend(company.corporateId)}
                  />
                </Fragment>
              ))}
            {nonTrendCompanies.length > 0 &&
              mapFilteredResults(nonTrendCompanies).map((company: ICompany, index: number) => (
                <Fragment key={company.corporateId}>
                  <HubSidePanelItem
                    isNew={false}
                    company={company}
                    companyTrendData={findCompanyTrend(company.corporateId)}
                  />
                </Fragment>
              ))}
          </div>
        ) : (
          <Flex>
            <Spacing pr="10" />
            <RText fontSize="16">{t("noHubCorporates")}</RText>
          </Flex>
        )}
      </Spacing>
    </div>
  );
};

export default React.memo(HubSidePanel);

export interface ICompany {
  corporateId: string;
  affinity: number;
  companyName: string;
  city: string;
  zipCode: string;
  successScore: string;
  promoterScore: string;
  tags: ITag[];
}

const mapFilteredResults = (filteredResults: ICorporateMarkerData[]): ICompany[] => {
  return filteredResults.map((company: ICorporateMarkerData) => {
    return {
      corporateId: company.corporateId,
      affinity: company.affinity,
      companyName: company.title,
      city: company.city,
      zipCode: company.zipCode,
      successScore: company.successScore,
      promoterScore: company.promoterScore,
      tags: company.tags,
    };
  });
};

export interface ICorporatesLatestSignal {
  companyId: string;
  companyTrend: string;
}

export const mapCompaniesTrend = (results: any): ICorporatesLatestSignal[] => {
  return (
    results?.map((companyData: any) => {
      return {
        companyId: companyData.company?.internal_company_id?.toString(),
        companyTrend: companyData.latest?.affinity?.product?.cluster[0] ?? "",
      };
    }) ?? []
  );
};

const mapMarkerDataForSidePanel = (
  hubMarkers: ICorporateHubMarker[],
): ICorporateMarkerData[] => {
  return (hubMarkers || []).flatMap((marker: ICorporateHubMarker) => [
    ...marker.corporatesData,
  ]);
};
