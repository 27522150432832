// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_scoreAnimationContainer__oyLTD {\n  width: 110px;\n}\n\n.styles_scoreAnimationContainer__Responsive__svT80 {\n  width: 160px;\n  height: 90px;\n}\n\n.styles_animatedIconContainer__eiWF1 {\n  width: 110px;\n  height: 110px;\n}\n\n.styles_animatedIconContainer__Responsive__ZAkdk {\n  height: 80px;\n  width: 80px;\n}\n\n.styles_animatedIconContainer__Mobile__OfQiI {\n  width: 60px;\n  height: 60px;\n}\n\n.styles_scoreIconContainer__I2nns {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.styles_scoreIconContainerValue__28w9W {\n  position: absolute;\n  margin: 0 auto;\n  left: 0;\n  right: 0;\n  text-align: center;\n  top: 40%;\n  padding-right: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/corporates/dossier/components/score/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,QAAQ;EACR,kBAAkB;AACpB","sourcesContent":[".scoreAnimationContainer {\n  width: 110px;\n}\n\n.scoreAnimationContainer__Responsive {\n  width: 160px;\n  height: 90px;\n}\n\n.animatedIconContainer {\n  width: 110px;\n  height: 110px;\n}\n\n.animatedIconContainer__Responsive {\n  height: 80px;\n  width: 80px;\n}\n\n.animatedIconContainer__Mobile {\n  width: 60px;\n  height: 60px;\n}\n\n.scoreIconContainer {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.scoreIconContainerValue {\n  position: absolute;\n  margin: 0 auto;\n  left: 0;\n  right: 0;\n  text-align: center;\n  top: 40%;\n  padding-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreAnimationContainer": "styles_scoreAnimationContainer__oyLTD",
	"scoreAnimationContainer__Responsive": "styles_scoreAnimationContainer__Responsive__svT80",
	"animatedIconContainer": "styles_animatedIconContainer__eiWF1",
	"animatedIconContainer__Responsive": "styles_animatedIconContainer__Responsive__ZAkdk",
	"animatedIconContainer__Mobile": "styles_animatedIconContainer__Mobile__OfQiI",
	"scoreIconContainer": "styles_scoreIconContainer__I2nns",
	"scoreIconContainerValue": "styles_scoreIconContainerValue__28w9W"
};
export default ___CSS_LOADER_EXPORT___;
