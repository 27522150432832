// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_column1__Xc8ON {\n  width: 40%;\n}\n\n.styles_tags__u5oNC {\n  width: 30%;\n  height: auto;\n  padding: 0px 10px;\n}\n\n.styles_tags__Responsive__kUDaZ {\n  width: 25%;\n}\n\n.styles_column2__KnK6T {\n  width: 20%;\n}\n\n.styles_column3__dK\\+4g {\n  width: 20%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/lists/SearchResultsList/ExecutivesSearchResultsListHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".column1 {\n  width: 40%;\n}\n\n.tags {\n  width: 30%;\n  height: auto;\n  padding: 0px 10px;\n}\n\n.tags__Responsive {\n  width: 25%;\n}\n\n.column2 {\n  width: 20%;\n}\n\n.column3 {\n  width: 20%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column1": "styles_column1__Xc8ON",
	"tags": "styles_tags__u5oNC",
	"tags__Responsive": "styles_tags__Responsive__kUDaZ",
	"column2": "styles_column2__KnK6T",
	"column3": "styles_column3__dK+4g"
};
export default ___CSS_LOADER_EXPORT___;
