import { useLocation } from "react-router-dom";

const useIsHub = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home" || pathname === "/";
};

const useIsCorporateDossier = () => {
  const pathname = useLocation().pathname;
  return pathname.split("/").includes("corporate-dossier");
};

const useIsExecutiveDossier = () => {
  const pathname = useLocation().pathname;
  return pathname.split("/").includes("executive-dossier");
};

const useIsSearchAll = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home/search/all";
};

const useIsSearchCorporates = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home/search/corporates";
};

const useIsSearchExecutives = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home/search/executives";
};

const useIsSearchInterests = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home/search/interests";
};

const useIsSearchOpportunities = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home/search/opportunities";
};

const useIsSearchStartups = () => {
  const pathname = useLocation().pathname;
  return pathname === "/home/search/startups";
};

const useIsSearchPage = () => {
  const isSearchAll = useIsSearchAll();
  const isSearchCorporates = useIsSearchCorporates();
  const isSearchExecutives = useIsSearchExecutives();
  const isSearchInterests = useIsSearchInterests();
  const isSearchOpportunities = useIsSearchOpportunities();
  const isSearchStartups = useIsSearchStartups();

  return (
    isSearchAll ||
    isSearchCorporates ||
    isSearchExecutives ||
    isSearchInterests ||
    isSearchOpportunities ||
    isSearchStartups
  );
};

const useIsDossier = () => {
  const isCorporateDossier = useIsCorporateDossier();
  const isExecutiveDossier = useIsExecutiveDossier();
  return isCorporateDossier || isExecutiveDossier;
};

export {
  useIsHub,
  useIsSearchPage,
  useIsDossier,
  useIsCorporateDossier,
  useIsExecutiveDossier,
};
