// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_itemIcon__HVi92 {\n  height: 30px;\n}\n\n.styles_itemIcon__Responsive__L\\+3Ni {\n  height: 20px;\n}\n\n.styles_active__mONZQ {\n  border-bottom: 2px solid var(--color_text_dark);\n  position: relative;\n  bottom: -1px;\n  min-width: auto;\n}\n\n.styles_textContainer__xVFtB {\n  display: flex;\n  height: 30px;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/navigation/navigation-item/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+CAA+C;EAC/C,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".itemIcon {\n  height: 30px;\n}\n\n.itemIcon__Responsive {\n  height: 20px;\n}\n\n.active {\n  border-bottom: 2px solid var(--color_text_dark);\n  position: relative;\n  bottom: -1px;\n  min-width: auto;\n}\n\n.textContainer {\n  display: flex;\n  height: 30px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemIcon": "styles_itemIcon__HVi92",
	"itemIcon__Responsive": "styles_itemIcon__Responsive__L+3Ni",
	"active": "styles_active__mONZQ",
	"textContainer": "styles_textContainer__xVFtB"
};
export default ___CSS_LOADER_EXPORT___;
