// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__vQ-m\\+ {\n  min-width: 6px;\n  max-width: 6px;\n  height: 6px;\n  border-radius: 100%;\n}\n\n.styles_active__CsVx- {\n  background: var(--color_background_black);\n}\n\n.styles_activeMultiLevel__3eolk {\n  border: 1px solid var(--color_background_black);\n  background-color: var(--color_primary_dark);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubHeader/HubFilterDot/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,+CAA+C;EAC/C,2CAA2C;AAC7C","sourcesContent":[".component {\n  min-width: 6px;\n  max-width: 6px;\n  height: 6px;\n  border-radius: 100%;\n}\n\n.active {\n  background: var(--color_background_black);\n}\n\n.activeMultiLevel {\n  border: 1px solid var(--color_background_black);\n  background-color: var(--color_primary_dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__vQ-m+",
	"active": "styles_active__CsVx-",
	"activeMultiLevel": "styles_activeMultiLevel__3eolk"
};
export default ___CSS_LOADER_EXPORT___;
