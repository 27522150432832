import { Query, toBoolean } from "@datorama/akita";
import { SessionState, SessionStore, sessionStore } from "./session.store";

export class SessionQuery extends Query<SessionState> {
  isLoggedIn$ = this.select((state) => this.isLoggedIn(state));
  currentUser$ = this.select((state) => state.user);
  token$ = this.select((state) => this.getToken(state));

  constructor(protected store: SessionStore) {
    super(store);
  }

  isLoggedIn(state: SessionState = this.getValue()) {
    return toBoolean(state.user);
  }

  getToken(state: SessionState = this.getValue()) {
    if (state.token) {
      return state.token;
    } else {
      return localStorage.getItem("token");
    }
  }
}

export const sessionQuery = new SessionQuery(sessionStore);
