// import React, { useContext } from "react";
import React from "react";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import styles from "./styles.module.css";
import { Spacing } from "../../../../../../components/spacing/component";
import { ReactComponent as CloseIconBlack } from "../../../../../../assets/CloseIconBlack.svg";
import { Magnifier as MagnifierIcon } from "../../../../../../assets/company-dossier/news-section/icons";
import { useTranslation } from "react-i18next";
// import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../hooks/app-colors.hook";

interface IProps {
  searchQuery: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchQueryDelete: () => void;
  onEnterKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const HubSidePanelSearchInput = ({
  searchQuery,
  onInputChange,
  onSearchQueryDelete,
  onEnterKeyPress,
}: IProps) => {
  const { t } = useTranslation();
  // const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  return (
    <Flex alignItemsCenter className={styles.searchInputContainer} fullWidth>
      <input
        name="searchCompanies"
        className={styles.inputSearch}
        placeholder={t("search")}
        value={searchQuery}
        autoComplete="off"
        onChange={onInputChange}
        onKeyUp={onEnterKeyPress}
      />
      {searchQuery?.length > 0 && (
        <>
          <Spacing pl="12" />
          <CloseIconBlack
            color={colors.backgroundBlackColor}
            className={styles.deleteSearchQueryIcon}
            onClick={onSearchQueryDelete}
          />
        </>
      )}
      <Spacing pl="12" />
      <MagnifierIcon className={styles.magnifierIcon} />
    </Flex>
  );
};

export default React.memo(HubSidePanelSearchInput);
