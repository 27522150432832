// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon__kidOs {\n  min-width: 20px;\n  max-width: 20px;\n  min-height: 20px;\n  max-height: 20px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/header/dark-mode-switcher/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".icon {\n  min-width: 20px;\n  max-width: 20px;\n  min-height: 20px;\n  max-height: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon__kidOs"
};
export default ___CSS_LOADER_EXPORT___;
