/**
 * Every filter generates ES query by using the title_key as the JSON key,
 * but they generate values for that key differently depending on the type
 *
 * Filter types without single_ prefix have their values visible on the UI. Depending on the part
 * of the app, when user clicks on the filter title, it opens the values. Clicking on the value
 * will run the filtering:
 *
 * basic
 *  - filter values visible on the UI as text
 *  - query object example { "region_lvl1": ["Bremen", "Hamburg"] }
 *
 * affinity
 *  - same as basic, only difference is in the UI
 *  - filter values visible as star icons instead of text
 *  - query object example { "affinity_score": [5, 3] }
 *
 * range
 *  - filter values visible on the UI as text
 *  - query object
 *
 *  Filter types with single_ prefix are filters that don't run when their value is clicked. They don't
 *  even have a value visible on the UI. They only have their title visible, and are run when
 *  the title is clicked.
 *
 * single_basic
 *  - doesn't have anything in the values array
 *  - generates query from title_string by splitting it over "_" separator.
 *  - For example:
 *    title key "tags_client" will become query object {"tags": ["Client"]}
 *
 * single_with_values
 *  - has values array
 *  - generates query by putting everything from values array into query value
 *  - For example:
 *    filter with title key "status" and values ["Liquidated", "Filed for Insolvency"]
 *    will become query object {"status": ["Liquidated", "Filed for Insolvency"]}
 *
 * single_echobot
 *  - all of the above filters build query objects for ElasticSearch, Echobot filters
 *    don't build any query objects. Instead, they call specific API endpoint for each filter
 *
 * single_range
 *  - builds query similar to range filters, but not from the values object. This is currently unique only to
 *  "Startups" filter on the hub, which should create a range object { "to": <today's date>, "from": <6 months ago date> }
 *
 * autosuggest_range
 *  - all values of this filter are shown as dropdown options with free input capabilities offering autosuggest
 *  - a difference is that this filter can also select dynamic ranges, resulting in selected items which were not part of
 *    the received values, but are instead generated dynamically on the frontend based on user selection
 */

export enum FILTER_TYPE {
  BASIC = "basic",
  AFFINITY = "affinity",
  RANGE = "range",
  SINGLE_RANGE = "single_range",
  SINGLE_BASIC = "single_basic",
  MULTI_BASIC = "multi_basic",
  SINGLE_WITH_VALUES = "single_with_values",
  SINGLE_ECHOBOT = "single_echobot",
  PRODUCT_TRENDS = "product_trends",
  AUTOSUGGEST_RANGE = "autosuggest_range",
  AGE_RANGE = "age_range",
}
