import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  FooterBankruptciesIcon,
  FooterNavigationIcon,
  FooterNewsIcon,
  FooterProductTrendsIcon,
  FooterStartUpsIcon,
  GreenCircle,
  GreenDot,
} from "../../../assets/hub/footer-icons/icons";
import { ReactComponent as ChevronRightLargeBlue } from "../../../assets/hub/ChevronRightLargeBlue.svg";
import { ReactComponent as ChevronLeftLargeBlue } from "../../../assets/hub/ChevronLeftLargeBlue.svg";
import { Flex } from "../../../components/containers/Flex/Flex";
import HorizontallyScrollable from "../../../components/containers/HorizontallyScrollable/HorizontallyScrollable";
import RText from "../../../components/fonts/RText/RText";
import BounceContent from "../../../components/other/BounceContent/BounceContent";
import { Spacing } from "../../../components/spacing/component";
import HubFooterItem from "./HubFooterItem/HubFooterItem";
import HubFooterNavigation from "./HubFooterNavigation/HubFooterNavigation";
import { useWindowSize } from "../../../hooks/window.hook";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../hooks/app-colors.hook";
import { HubContext } from "../../../modules/hub/state/hub.context";
import {
  ILeftArrowHandler,
  IRightArrowHandler,
  useLeftArrowHandler,
  useRightArrowHandler,
} from "../../../hooks/hub-footer.hook";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";
import { DataContainer, ClientType } from "./HubFooterLayouts";
import { useCurrentClient } from "../../../hooks/session.hook";
export enum HUB_FOOTER_NAVIGATION_TYPE {
  PRODUCT_TRENDS,
  NEWS,
  START_UPS,
  BANKRUPTCIES,
  ANNIVERSARY,
  PLACEHOLDER,
}

export interface INavigationItem {
  title: string;
  type: HUB_FOOTER_NAVIGATION_TYPE;
  exist: boolean;
  loading: boolean;
  items: IHubFooterItemData[];
}

const FooterDataConsumer = ({
  noDataAvailable,
  allContentLoaded,
  navigationItems,
  children,
}: {
  noDataAvailable: boolean;
  allContentLoaded: boolean;
  navigationItems: INavigationItem[];
  children: (renderProps?: {
    selectedNavItem: INavigationItem;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<INavigationItem>>;
  }) => JSX.Element;
}): JSX.Element => {
  const [selectedNavItem, setSelectedNavItem] = useState<INavigationItem>(null);

  const navigationItemsChange = navigationItems.map((item) => !item.loading && item.exist);

  useEffect(() => {
    if (selectedNavItem !== null) {
      return;
    }

    const firstNavItemWithData = navigationItems.find((navItem) => navItem.exist);

    if (firstNavItemWithData) {
      setSelectedNavItem(firstNavItemWithData);
    }
  }, [navigationItemsChange]); // eslint-disable-line

  return children({
    selectedNavItem,
    setSelectedNavItem,
  });
};

const HubFooter = () => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { setHubRolloverCorporate } = useContext(HubContext);
  const [repeatAnimation, setRepeatAnimation] = useState(false);
  const currentClient = useCurrentClient();

  const leftArrowHandler = useLeftArrowHandler();
  const rightArrowHandler = useRightArrowHandler();
  const windowSize = useWindowSize();
  const [navigationItems, setNavigationItems] = useState<any[]>([]);
  const noDataAvailable = !Boolean(navigationItems.find(({ exist }) => exist));

  const allContentLoaded = !navigationItems.some((navItem) => navItem.loading);

  return (
    <DataContainer
      clientType={
        currentClient?.name?.toUpperCase() === "VKB" ? ClientType.vkb : ClientType.default
      }
      setNavigationItems={setNavigationItems}
    >
      <FooterDataConsumer
        noDataAvailable={noDataAvailable}
        allContentLoaded={allContentLoaded}
        navigationItems={navigationItems}
      >
        {({ selectedNavItem, setSelectedNavItem }) => {
          const onNavigationItemClick = (item: INavigationItem) => {
            if (!item.exist || !allContentLoaded) {
              return;
            }

            setRepeatAnimation(true);
            setSelectedNavItem(item);
          };

          const onAnimationOver = () => {
            const nextActiveNavItem = getNextActiveNavigationItem(
              selectedNavItem.title,
              navigationItems,
            );

            setSelectedNavItem(nextActiveNavItem);
          };

          /**
           * In order to run animation even if we don't have enough content,
           * we need to define min width for animation container.
           *
           * To define that, we need substract the width of the screen with number 430.
           * 430 is total width of:
           *  footer navigation container -> 335px (defined in css file)
           *  + padding from left and right -> 55px (defined in css file)
           *  + width of the icon container on the right -> 45px (defined in css file)
           *  - some extra space if needed -> 5px (manually added)
           *
           *  For smaller screens, number is 320.
           *  Footer navigation container is 225
           *  + padding from left and right -> 55px (defined in css file)
           *  + width of the icon container on the right -> 45px (defined in css file)
           *  - some extra space if needed -> 5px (manually added)
           */
          let minAnimationContentWidth = isSmallerScreen
            ? windowSize.width - 320
            : windowSize.width - 430;

          const hubLeadsWidth =
            selectedNavItem !== null &&
              selectedNavItem?.type !== HUB_FOOTER_NAVIGATION_TYPE.ANNIVERSARY
              ? selectedNavItem?.items?.length * 100
              : minAnimationContentWidth;

          const onAnimationRepeat = () => {
            setRepeatAnimation(false);

            const contentInitialXAxisPosition = {
              start: 0,
              /**
               * Number 30 is manually added,
               * so that the content can be moved a little further to the left for its initial end position.
               */
              end: minAnimationContentWidth - 30,
            };

            return contentInitialXAxisPosition;
          };

          const renderFooterNavigationContent = () => {
            if (
              noDataAvailable &&
              selectedNavItem?.type === HUB_FOOTER_NAVIGATION_TYPE.ANNIVERSARY
            ) {
              return (
                <HorizontallyScrollable>
                  <RText immutableWhite fontSize="16">
                    {t("noCompaniesMatchingTags")}
                  </RText>
                </HorizontallyScrollable>
              );
            }

            if (noDataAvailable) {
              return (
                <HorizontallyScrollable>
                  <RText immutableWhite fontSize="16">
                    {t("noDataAvailable")}
                  </RText>
                </HorizontallyScrollable>
              );
            }
            const renderedFooterContent = renderFooterItems(
              selectedNavItem?.type === HUB_FOOTER_NAVIGATION_TYPE.ANNIVERSARY
                ? navigationItems[2]?.items
                : selectedNavItem?.items,
              selectedNavItem?.type,
              hubLeadsWidth,
            );

            return renderBounceContent(
              renderedFooterContent,
              onAnimationOver,
              repeatAnimation,
              onAnimationRepeat,
              leftArrowHandler,
              rightArrowHandler,
            );
          };

          const selectedNavigationItemIconProps = getSelectedNavigationItemIconProps(
            selectedNavItem?.type,
            navigationItems?.findIndex((item) => item.title === selectedNavItem?.title) ?? -1,
          );

          return (
            <Flex
              justifyContentSpaceBetween
              alignItemsCenter
              className={isSmallerScreen ? styles.component__Responsive : styles.component}
              onMouseLeave={() => setHubRolloverCorporate(null)}
            >
              <Flex
                alignItemsCenter
                className={`${styles.footerNavigationContainer} ${isSmallerScreen && styles.footerNavigationContainer__Responsive
                  }`}
              >
                {!isSmallerScreen && (
                  <div className={styles.footerNavigationIconContainer}>
                    <RText fontSize="13" bold immutableWhite className={styles.footerText}>
                      {t("top")}
                    </RText>

                    {selectedNavigationItemIconProps?.renderIcon({
                      color: colors.primaryDarkColor,
                      className: styles.selectedNavItemIcon,
                    })}
                    <FooterNavigationIcon
                      color={colors.primaryDarkColor}
                      className={styles.footerNavigationIcon}
                    />

                    {selectedNavigationItemIconProps?.dotIconPosition && (
                      <GreenDot
                        style={{
                          position: "absolute",
                          color: colors.primaryDarkColor,
                          ...selectedNavigationItemIconProps.dotIconPosition,
                        }}
                      />
                    )}
                  </div>
                )}

                <Spacing pr="30" />

                <HubFooterNavigation
                  selectedNavItem={selectedNavItem}
                  navigationItems={navigationItems}
                  onItemClick={onNavigationItemClick}
                />

                <div className={styles.verticalDivider} style={{ marginRight: "15px" }} />
                <ChevronLeftLargeBlue
                  color={colors.secondaryDarkColor}
                  className={styles.arrowIcon}
                  {...leftArrowHandler.clickHandlers}
                />
                <div
                  className={styles.verticalDivider}
                  style={{
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                />
              </Flex>
              {!allContentLoaded ? (
                <HorizontallyScrollable>
                  <RText immutableWhite fontSize="16">
                    {t("loading")}
                  </RText>
                </HorizontallyScrollable>
              ) : (
                renderFooterNavigationContent()
              )}
              <Flex
                alignItemsCenter
                justifyContentEnd
                className={styles.rightArrowIconContainer}
              >
                <div className={styles.verticalDivider} style={{ margin: "0px 15px" }} />
                <ChevronRightLargeBlue
                  color={colors.secondaryDarkColor}
                  className={styles.arrowIcon}
                  {...rightArrowHandler.clickHandlers}
                />
              </Flex>
            </Flex>
          );
        }}
      </FooterDataConsumer>
    </DataContainer>
  );
};

export default React.memo(HubFooter);

export const renderBounceContent = (
  content: any,
  onAnimationOver: () => void,
  repeatAnimation: boolean,
  onAnimationRepeat: () => { start: number; end: number },
  leftArrowHandler: ILeftArrowHandler,
  rightArrowHandler: IRightArrowHandler,
) => (
  <BounceContent
    content={content}
    velocity={leftArrowHandler.fastForward || rightArrowHandler.fastBackward ? 0.3 : 0.08}
    onAnimationOver={onAnimationOver}
    repeatAnimation={repeatAnimation}
    onAnimationRepeat={onAnimationRepeat}
    leftArrowHandler={leftArrowHandler}
    rightArrowHandler={rightArrowHandler}
  />
);

const CustomHorizontallyScrollable = ({ children }: any) => (
  <HorizontallyScrollable>{children}</HorizontallyScrollable>
);

const CustomFlex = ({ children }: any) => <Flex alignItemsCenter>{children}</Flex>;

const WrapperComponent = ({
  itemType,
  children,
}: {
  itemType: HUB_FOOTER_NAVIGATION_TYPE;
  children: React.ReactNode;
}) => {
  return itemType === HUB_FOOTER_NAVIGATION_TYPE.ANNIVERSARY ? (
    <CustomHorizontallyScrollable>{children}</CustomHorizontallyScrollable>
  ) : (
    <CustomFlex>{children}</CustomFlex>
  );
};

const renderFooterItems = (
  items: IHubFooterItemData[],
  itemType: HUB_FOOTER_NAVIGATION_TYPE,
  minAnimationContentWidth: number,
) => (
  <div
    className={styles.footerItemsContainer}
    style={{
      minWidth: `${minAnimationContentWidth}px`,
    }}
  >
    <WrapperComponent itemType={itemType}>
      {items &&
        items.map((item: IHubFooterItemData, index: number) => (
          <React.Fragment key={`anniversaryItem${item?.company?.id}${index}`}>
            <HubFooterItem itemType={itemType} item={item} />
            {index < items.length - 1 && (
              <div className={styles.verticalDivider} style={{ margin: "0 15px" }} />
            )}
          </React.Fragment>
        ))}
    </WrapperComponent>
  </div>
);

function getNextActiveNavigationItem(
  currentActiveItemTitle: string,
  navigationItems: INavigationItem[],
) {
  const currentIdx = navigationItems.findIndex(
    (navItem) => navItem.title === currentActiveItemTitle,
  );

  // if currently active item no longer exists return first existing item
  if (currentIdx === -1) {
    return navigationItems.find((navItem) => navItem.exist);
  }

  // try returning first existing item after current
  for (let i = currentIdx; ++i < navigationItems.length;) {
    if (navigationItems[i].exist) {
      return navigationItems[i];
    }
  }

  // otherwise return first existing item from the beginning
  return navigationItems.find((navItem) => navItem.exist);
}

export interface INavigationIconProps {
  color: string;
  className: string;
}

function getSelectedNavigationItemIconProps(
  selectedItemType: HUB_FOOTER_NAVIGATION_TYPE,
  selectedItemIndex: number,
) {
  if (selectedItemType === undefined || selectedItemType === null || selectedItemIndex === -1)
    return null;

  let dotIconPosition;
  let renderIcon;

  switch (selectedItemIndex) {
    case 0:
      dotIconPosition = { top: "2px", right: "15px" };
      break;
    case 1:
      dotIconPosition = { top: "24px", right: "0px" };
      break;
    case 2:
      dotIconPosition = { top: "46px", right: "0px" };
      break;
    case 3:
      dotIconPosition = { top: "68px", right: "15px" };
      break;
    default:
      break;
  }

  switch (selectedItemType) {
    case HUB_FOOTER_NAVIGATION_TYPE.PRODUCT_TRENDS:
      renderIcon = ({ color, className }: INavigationIconProps) => (
        <FooterProductTrendsIcon color={color} className={className} />
      );
      break;
    case HUB_FOOTER_NAVIGATION_TYPE.NEWS:
      renderIcon = ({ color, className }: INavigationIconProps) => (
        <FooterNewsIcon color={color} className={className} />
      );
      break;
    case HUB_FOOTER_NAVIGATION_TYPE.START_UPS:
      renderIcon = ({ color, className }: INavigationIconProps) => (
        <FooterStartUpsIcon color={color} className={className} />
      );
      break;
    case HUB_FOOTER_NAVIGATION_TYPE.BANKRUPTCIES:
      renderIcon = ({ color, className }: INavigationIconProps) => (
        <FooterBankruptciesIcon color={color} className={className} />
      );
      break;
    default:
      renderIcon = ({ color, className }: INavigationIconProps) => (
        <GreenCircle color={color} className={className} />
      );
      break;
  }
  return { renderIcon, dotIconPosition };
}
