import React from "react";
import styles from "./styles.module.css";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../components/spacing/component";

interface IProps {
  insights: Object;
}

export default function CompanyInsights({ insights }: IProps) {
  return (
    <Flex column justifyContentSpaceBetween className={styles.insightsContainer}>
      {insights &&
        Object.entries(insights).map(([name, value], index) => (
          <React.Fragment key={name}>
            <Flex justifyContentSpaceBetween>
              <RText fontSize="12">{name}</RText>
              <Spacing pr="10" />
              <RText bold fontSize="12">
                {value}
              </RText>
            </Flex>
            {index < Object.keys(insights).length - 1 && <Spacing pt="8" />}
          </React.Fragment>
        ))}
    </Flex>
  );
}
