// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_companiesControl__O1IaW {\n  height: 17px;\n  cursor: pointer;\n}\n\n.styles_newsAndRelationsContainer__9V\\+gm {\n  background-color: var(--color_gray_light);\n  padding: 10px;\n}\n\n.styles_executiveNameContainer__F2303 {\n  padding: 15px;\n}\n\n.styles_executiveNameContainer__F2303:hover {\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_addToRouteContainer__u0qXQ {\n  position: fixed;\n  bottom: 0;\n  background-color: var(--immutable_background_white);\n  width: 100%;\n  max-width: 350px;\n}\n\n.styles_addToRouteIcon__pos2n {\n  height: 18px;\n}\n\n.styles_scoreContainer__vRfI\\+ {\n  min-width: 60px;\n  max-width: 60px;\n  min-height: 60px;\n  max-height: 60px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/executives-hub/ExecutivesHubMain/ExecutivesHubMarkerPopup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,eAAe;EACf,SAAS;EACT,mDAAmD;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".companiesControl {\n  height: 17px;\n  cursor: pointer;\n}\n\n.newsAndRelationsContainer {\n  background-color: var(--color_gray_light);\n  padding: 10px;\n}\n\n.executiveNameContainer {\n  padding: 15px;\n}\n\n.executiveNameContainer:hover {\n  background-color: var(--color_hover_selected_light);\n}\n\n.addToRouteContainer {\n  position: fixed;\n  bottom: 0;\n  background-color: var(--immutable_background_white);\n  width: 100%;\n  max-width: 350px;\n}\n\n.addToRouteIcon {\n  height: 18px;\n}\n\n.scoreContainer {\n  min-width: 60px;\n  max-width: 60px;\n  min-height: 60px;\n  max-height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companiesControl": "styles_companiesControl__O1IaW",
	"newsAndRelationsContainer": "styles_newsAndRelationsContainer__9V+gm",
	"executiveNameContainer": "styles_executiveNameContainer__F2303",
	"addToRouteContainer": "styles_addToRouteContainer__u0qXQ",
	"addToRouteIcon": "styles_addToRouteIcon__pos2n",
	"scoreContainer": "styles_scoreContainer__vRfI+"
};
export default ___CSS_LOADER_EXPORT___;
