// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_companiesControl__x8HzX {\n  height: 17px;\n  cursor: pointer;\n}\n\n.styles_newsAndRelationsContainer__fG6wb {\n  background-color: var(--color_gray_light);\n  padding: 10px;\n}\n\n.styles_companyNameContainer__PHydz {\n  padding: 15px;\n}\n\n.styles_companyNameContainer__PHydz:hover {\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_addToRouteContainer__EZVw2 {\n  position: fixed;\n  bottom: 0;\n  background-color: var(--immutable_background_white);\n  width: 100%;\n  max-width: 350px;\n}\n\n.styles_addToRouteIcon__LT0w8 {\n  height: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubMain/HubMarkerPopup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,eAAe;EACf,SAAS;EACT,mDAAmD;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".companiesControl {\n  height: 17px;\n  cursor: pointer;\n}\n\n.newsAndRelationsContainer {\n  background-color: var(--color_gray_light);\n  padding: 10px;\n}\n\n.companyNameContainer {\n  padding: 15px;\n}\n\n.companyNameContainer:hover {\n  background-color: var(--color_hover_selected_light);\n}\n\n.addToRouteContainer {\n  position: fixed;\n  bottom: 0;\n  background-color: var(--immutable_background_white);\n  width: 100%;\n  max-width: 350px;\n}\n\n.addToRouteIcon {\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companiesControl": "styles_companiesControl__x8HzX",
	"newsAndRelationsContainer": "styles_newsAndRelationsContainer__fG6wb",
	"companyNameContainer": "styles_companyNameContainer__PHydz",
	"addToRouteContainer": "styles_addToRouteContainer__EZVw2",
	"addToRouteIcon": "styles_addToRouteIcon__LT0w8"
};
export default ___CSS_LOADER_EXPORT___;
