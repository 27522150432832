// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__u-f\\+0 {\n  position: relative;\n}\n\n.styles_groupTitle__BblG3 {\n  position: relative;\n}\n\n.styles_groupTitle__Responsive__cAaaT {\n  position: relative;\n}\n\n.styles_filtersContent__4Og94 {\n  min-width: 150px;\n}\n\n.styles_closeMultiLevelFilterIcon__bRmaw {\n  position: relative;\n  top: 18px;\n  left: 15px;\n}\n\n.styles_multiLevelFilterValuesContainer__Responsive__GKi4l {\n  background-color: var(--color_background_white);\n  border-bottom: 1px solid var(--color_gray);\n  padding-bottom: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubHeader/HubFiltersGroup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,+CAA+C;EAC/C,0CAA0C;EAC1C,mBAAmB;AACrB","sourcesContent":[".component {\n  position: relative;\n}\n\n.groupTitle {\n  position: relative;\n}\n\n.groupTitle__Responsive {\n  position: relative;\n}\n\n.filtersContent {\n  min-width: 150px;\n}\n\n.closeMultiLevelFilterIcon {\n  position: relative;\n  top: 18px;\n  left: 15px;\n}\n\n.multiLevelFilterValuesContainer__Responsive {\n  background-color: var(--color_background_white);\n  border-bottom: 1px solid var(--color_gray);\n  padding-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__u-f+0",
	"groupTitle": "styles_groupTitle__BblG3",
	"groupTitle__Responsive": "styles_groupTitle__Responsive__cAaaT",
	"filtersContent": "styles_filtersContent__4Og94",
	"closeMultiLevelFilterIcon": "styles_closeMultiLevelFilterIcon__bRmaw",
	"multiLevelFilterValuesContainer__Responsive": "styles_multiLevelFilterValuesContainer__Responsive__GKi4l"
};
export default ___CSS_LOADER_EXPORT___;
