import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

export default createTheme({
  palette: {
    primary: {
      main: "#0f0f0f",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "outlined" && {
            fontSize: "1rem",
            fontWeight: "bold",
            borderColor: colors.grey[400],
            textTransform: "unset",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
            background: colors.common.white,
            ":hover": {
              background: colors.common.white,
            },
          }),
        }),
      },
    },
  },
});
