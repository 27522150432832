import { differenceInDays } from "date-fns";
import { ICorporate } from "../entities/corporates/models/corporate.model";
import { IExecutive } from "../entities/executives/state/executive.model";

export function isClient(corporate: ICorporate): boolean {
  return corporate.tags.filter((t) => t.tag.name === "Client").length > 0;
}

export function isLead(corporate: ICorporate): boolean {
  return corporate.tags.filter((t) => t.tag.name === "Lead").length > 0;
}

export function isExecutiveClient(executive: IExecutive): boolean {
  return executive.tags.filter((t) => t.tag.name === "Client").length > 0;
}

export function isExecutiveLead(executive: IExecutive): boolean {
  return executive.tags.filter((t) => t.tag.name === "Lead").length > 0;
}

export function isStartup(corporate: ICorporate): boolean {
  return (
    corporate.founding_date &&
    Math.abs(differenceInDays(new Date(), new Date(corporate.founding_date))) <= 180
  );
}

export function isBankruptcy(corporate: ICorporate, statusLimitDays?: number): boolean {
  if (!corporate.status) {
    return false;
  }

  const provisionalResult =
    corporate.status.trim().toLowerCase().includes("liquidation") ||
    corporate.status.trim().toLowerCase().includes("filed for insolvency");

  if (statusLimitDays) {
    return (
      provisionalResult &&
      Math.abs(differenceInDays(new Date(), new Date(corporate.status_date))) <=
        statusLimitDays
    );
  }

  return provisionalResult;
}

export function isOther(corporate: ICorporate): boolean {
  return (
    !isStartup(corporate) &&
    !isLead(corporate) &&
    !isBankruptcy(corporate) &&
    !isClient(corporate)
  );
}
