import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "../styles.module.css";
import NewsIcon from "../../../../../assets/hub/NewsIcon.svg";
import RelationshipsIcon from "../../../../../assets/hub/RelationshipsIcon.svg";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import { ICorporateMarkerData } from "../../../../../entities/corporates/interfaces/corporate-marker-data.interface";
import { hubApiService } from "../../../../../api/services/hub-api.service";
import { corporatesApiService } from "../../../../../api/services/corporates-api.service";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../components/spacing/component";
import CountBox from "../../../../../components/other/CountBox/CountBox";
import Divider from "../../../../../components/dividers/Divider/Divider";
import { FILTER_CATEGORY } from "../../../../../api/enums/filter-category.enum";
import { RELATIONS_COUNT } from "../../../../../api/enums/relationship-indicator.enum";
import { IRouteParams } from "../../../../../shared/interfaces/route-params.interface";
import RLink from "../../../../../components/link/RLink";
import { useIsCorporateDossier } from "../../../../../utils/route.util";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import CorporatesSelectionTags from "../../../../home/CorporatesTags/CorporatesSelectionTags";

interface IProps {
  markerCorporate: ICorporateMarkerData;
}

const HubMarkerPopupCorporate = ({ markerCorporate }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { tags } = markerCorporate;
  const isCorporateDossier = useIsCorporateDossier();

  const [newsCount, setNewsCount] = useState<number>(null);
  const [productTrendsCount, setProductTrendsCount] = useState<number>(null);
  const [relationshipCount, setRelationshipCount] = useState<number>(null);

  const { id: corporateId } = useParams<IRouteParams>();

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const shouldOpenCorporateDossier = () => {
    if (isCorporateDossier && corporateId === markerCorporate.corporateId) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    corporatesApiService
      .getCorporateRelationshipIndicator(
        FILTER_CATEGORY.CORPORATES,
        markerCorporate.corporateId,
        RELATIONS_COUNT.TRUE,
      )
      .then((res: any) => {
        if (!isMounted.current) return;
        if (Array.isArray(res) && res.length === 0) {
          setRelationshipCount(0);
        } else {
          setRelationshipCount(res.count);
        }
      })
      .catch((e) => console.log(e));

    hubApiService
      .fetchNewsCountForCompany(markerCorporate.corporateId)
      .then((res) => {
        if (!isMounted.current) return;
        setNewsCount(res.data.count);
      })
      .catch((e) => console.log(e));

    hubApiService
      .fetchTrendsCountForCompany(markerCorporate.corporateId)
      .then((res) => {
        if (!isMounted.current) return;
        setProductTrendsCount(res.data.count);
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerCorporate.corporateId]);

  return (
    <Flex column>
      <Flex justifyContentEnd className={styles.newsAndRelationsContainer}>
        <Flex alignItemsCenter>
          <RText immutableBlack fontSize="11">
            {t("news")}
          </RText>
          <Spacing pl="5" />
          <CountBox count={newsCount} icon={NewsIcon} />
          <Spacing pl="10" />
          <RText immutableBlack fontSize="11">
            {t("relationships")}
          </RText>
          <Spacing pl="5" />
          <CountBox count={relationshipCount} icon={RelationshipsIcon} />
        </Flex>
      </Flex>

      <Divider />

      <RLink
        path={{
          pathname: `/home/search/corporate-dossier/${markerCorporate.corporateId}`,
          state: { fromHub: true },
        }}
        condition={!!shouldOpenCorporateDossier()}
      >
        <Flex column className={styles.companyNameContainer}>
          <StarRating
            iconHeight={isSmallerScreen ? "13" : "18"}
            spacing="2"
            affinity={markerCorporate.affinity}
            emptyIconSize={isSmallerScreen ? "8" : "10"}
          />
          <Spacing pb="5" />
          <span>
            <RText
              immutableBlack
              bold
              fontSize={isSmallerScreen ? "13" : "18"}
              elementStyle={{ display: "inline" }}
            >
              {markerCorporate.title}
            </RText>
            <CorporatesSelectionTags tags={tags} />
          </span>
          <Spacing pb="5" />
          <RText immutableBlack fontSize={isSmallerScreen ? "11" : "13"}>
            {markerCorporate.address}
          </RText>
        </Flex>
      </RLink>

      <Divider />

      <Spacing ph="15" pt="15" pb={isSmallerScreen ? "16" : "35"}>
        <Flex>
          <Flex column>
            <RText immutableBlack bold>
              {t("productTrends")}
            </RText>
            <RText immutableBlack bold fontSize={isSmallerScreen ? "13" : "20"}>{`${
              productTrendsCount ?? ""
            }`}</RText>
          </Flex>
          <Spacing pr="30" />
          <Flex column>
            <RText immutableBlack bold>
              {t("successScore")}
            </RText>
            <RText immutableBlack bold fontSize={isSmallerScreen ? "13" : "20"}>
              {markerCorporate.successScore ? markerCorporate.successScore + "%" : ""}
            </RText>
          </Flex>
        </Flex>
      </Spacing>
    </Flex>
  );
};

export default HubMarkerPopupCorporate;
