import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";
import { ISelectedValuesRange } from "../../shared/interfaces/selected-value-types.interface";
import { getDisplayValue } from ".";

export const dynamicRangeLabelsByType: {
  [key: string]: (
    from: ICorporatesFilterValue,
    to: ICorporatesFilterValue,
    selectedValue: ISelectedValuesRange,
  ) => [string, string];
} = {
  industry_sector_codes_full: (from, to) => [
    `${from?.value_key} ${from?.label ?? ""}`,
    `${to?.value_key} ${to?.label ?? ""}`,
  ],
  zip_code: (from, to, selectedValue) =>
    [
      `${
        from
          ? getDisplayValue("zip_code")(from)
          : getDisplayValue("zip_code")({
              label: selectedValue.from,
              id: undefined,
              value_key: undefined,
            })
      }`,
      `${
        to
          ? getDisplayValue("zip_code")(to)
          : getDisplayValue("zip_code")({
              label: selectedValue.to,
              id: undefined,
              value_key: undefined,
            })
      }`,
    ] as [string, string],
};

export const defaultDynamicRangeLabel = (
  from: ICorporatesFilterValue,
  to: ICorporatesFilterValue,
  selectedValue: ISelectedValuesRange,
) =>
  [
    `${from ? getDisplayValue()(from) : selectedValue.from}`,
    `${to ? getDisplayValue()(to) : selectedValue.to}`,
  ] as [string, string];
