// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__LZ-we {\n  border: solid 1px var(--color_secondary_dark);\n  display: flex;\n  align-items: center;\n\n  /* Prevent text from breaking into 2 or more lines */\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.styles_component__LZ-we:hover {\n  cursor: pointer;\n}\n\n.styles_borderless__32I6y {\n  border: none;\n}\n\n.styles_iconContainer__jCyuA {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/buttons/SquareButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,aAAa;EACb,mBAAmB;;EAEnB,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd","sourcesContent":[".component {\n  border: solid 1px var(--color_secondary_dark);\n  display: flex;\n  align-items: center;\n\n  /* Prevent text from breaking into 2 or more lines */\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.component:hover {\n  cursor: pointer;\n}\n\n.borderless {\n  border: none;\n}\n\n.iconContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__LZ-we",
	"borderless": "styles_borderless__32I6y",
	"iconContainer": "styles_iconContainer__jCyuA"
};
export default ___CSS_LOADER_EXPORT___;
