// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__JEucx,\n.styles_component__JEucx > * {\n  height: 100%;\n}\n\n.styles_component__JEucx > * {\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/components/containers/FullHeight/styles.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".component,\n.component > * {\n  height: 100%;\n}\n\n.component > * {\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__JEucx"
};
export default ___CSS_LOADER_EXPORT___;
