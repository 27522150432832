import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { RouteModel } from "../../models/route/route.model";
import { RouteType, toExactRoute } from "../../helpers/route.helper";
import LoginPage from "../login/LoginPage";
import HomePage from "../home/HomePage";
import { ResolutionContextProvider } from "../../state/context/ResolutionContext/ResolutionContextProvider";
import { SessionContext } from "../../state/akita/session/session.context";
import { getCurrentTenant } from "../../utils/tenant.util";
import { useCaptureLastVisitedPage } from "../../hooks/useCaptureLastVisitedPage";

function App() {
  const currentTenant = getCurrentTenant();
  const routerBasename = currentTenant.subdomainTenant ? "" : `/${currentTenant.tenant}`;

  return (
    <ResolutionContextProvider>
      <Router basename={routerBasename}>
        <RouteSwitch />
      </Router>
    </ResolutionContextProvider>
  );
}

function RouteSwitch() {
  const pathname = useLocation().pathname;

  useCaptureLastVisitedPage();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {exactRoutes.map(toExactRoute)}
      <PrivateRoute path="/home" Component={() => <HomePage />} title="MOVE" />
      <PrivateRoute path="/:lang/home" Component={() => <HomePage />} title="MOVE" />
      <PrivateRoute path="/" Component={() => <Redirect to="/home" />} title="MOVE" />
    </Switch>
  );
}

const exactRoutes: RouteModel[] = [
  {
    title: "MOVE",
    path: "/login",
    Component: () => <LoginPage />,
  },
];

export default App;

// TODO: export into it's own package
export const PrivateRoute = ({ Component, path, title, exact }: RouteType, index?: number) => {
  const sessionContext = useContext(SessionContext);

  if (!sessionContext.getToken()) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      key={index}
      component={() => {
        document.title = title;
        return <Component />;
      }}
      {...{ exact, path }}
    />
  );
};
