import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import styles from "./styles.module.css";
import { ReactComponent as AddToRouteIcon } from "../../../../assets/hub/map-icons/AddToRouteIcon.svg";
import { ReactComponent as ChevronLeftBlueIcon } from "../../../../assets/ChevronLeftBlue.svg";
import { ReactComponent as ChevronRightBlueIcon } from "../../../../assets/ChevronRightBlue.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import Divider from "../../../../components/dividers/Divider/Divider";
import { Spacing } from "../../../../components/spacing/component";
import { ICorporateMarkerData } from "../../../../entities/corporates/interfaces/corporate-marker-data.interface";
import HubMarkerPopupCorporate from "./HubMarkerPopupCorporate/HubMarkerPopupCorporate";
import RText from "../../../../components/fonts/RText/RText";
import { buildCompanyAddressForGeoCoding } from "../../../../helpers/map.helper";
import useAppColors from "../../../../hooks/app-colors.hook";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useHubRolloverCorporate } from "../../../../modules/hub/hooks/hub-rollover-corporate";

interface IProps {
  markerCorporates: ICorporateMarkerData[];
  isCorporateLookALike?: boolean;
  addToRouteData?: {
    waypoints: { [key: string]: number[] };
    getEndCoordinates: (companyId: string, companyAddress: string) => void;
  };
}

const HubMarkerPopup = ({
  markerCorporates,
  isCorporateLookALike,
  addToRouteData,
}: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const map = useMap();
  const { colors } = useAppColors();

  const { hubRolloverCorporateId } = useHubRolloverCorporate();
  const [selectedCorporateIndex, setSelectedCorporateIndex] = useState(0);

  useEffect(() => {
    if (markerCorporates?.length <= 1) {
      return;
    }

    if (!hubRolloverCorporateId) {
      setSelectedCorporateIndex(0);
    } else {
      const rolloverCorporateIndex = markerCorporates.findIndex(
        (marker) => marker.corporateId === hubRolloverCorporateId,
      );

      setSelectedCorporateIndex(rolloverCorporateIndex !== -1 ? rolloverCorporateIndex : 0);
    }
  }, [hubRolloverCorporateId, markerCorporates]);

  const selectNextCorporate = () => {
    if (selectedCorporateIndex === markerCorporates.length - 1) return;
    setSelectedCorporateIndex(selectedCorporateIndex + 1);
  };

  const selectPreviousCorporate = () => {
    if (selectedCorporateIndex === 0) return;
    setSelectedCorporateIndex(selectedCorporateIndex - 1);
  };

  const onAddToRoute = () => {
    if (!addToRouteData) {
      return;
    }

    const { waypoints, getEndCoordinates } = addToRouteData;
    const corporate = markerCorporates[selectedCorporateIndex];

    // Prevent calling API if route is already calculated for given company.
    if (waypoints.hasOwnProperty(corporate.corporateId)) {
      return;
    }

    getEndCoordinates(
      corporate.corporateId,
      buildCompanyAddressForGeoCoding(corporate.street, corporate.city),
    );

    map.closePopup();
  };

  return (
    <Flex column>
      <Flex justifyContentSpaceBetween>
        <ChevronLeftBlueIcon
          color={colors.secondaryDarkColor}
          className={styles.companiesControl}
          onClick={selectPreviousCorporate}
        />
        <Flex alignItemsCenter>
          <RText immutableBlack bold fontSize={isSmallerScreen ? "11" : "12"}>{`${
            selectedCorporateIndex + 1
          }`}</RText>
          <Spacing ph="2">|</Spacing>
          <RText immutableBlack fontSize={isSmallerScreen ? "11" : "12"}>
            {`${markerCorporates.length} ${t("companies")}`}
          </RText>
        </Flex>
        <ChevronRightBlueIcon
          color={colors.secondaryDarkColor}
          className={styles.companiesControl}
          onClick={selectNextCorporate}
        />
      </Flex>

      <Spacing pb="10" />

      <Divider immutableBlack />

      <Spacing pb="2" />

      <HubMarkerPopupCorporate markerCorporate={markerCorporates[selectedCorporateIndex]} />

      {isCorporateLookALike && (
        <div className={styles.addToRouteContainer}>
          <Divider />
          <Spacing pv="10">
            <Flex alignItemsCenter justifyContentCenter>
              <Flex pointer alignItemsCenter onClick={onAddToRoute}>
                <RText fontSize={isSmallerScreen ? "11" : "15"} primaryBlue bold>
                  {t("addToRoute")}
                </RText>
                <Spacing pr="2" />
                <AddToRouteIcon
                  color={colors.secondaryDarkColor}
                  className={styles.addToRouteIcon}
                />
              </Flex>
            </Flex>
          </Spacing>
        </div>
      )}
    </Flex>
  );
};

export default HubMarkerPopup;
