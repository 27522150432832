import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { INavigationItem } from "../HubFooter";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  selectedNavItem: INavigationItem;
  navigationItems: INavigationItem[];
  onItemClick: (item: INavigationItem) => void;
}

const HubFooterNavigation = ({ selectedNavItem, navigationItems, onItemClick }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex
      column
      justifyContentSpaceBetween={!isSmallerScreen}
      justifyContentCenter={isSmallerScreen}
      className={`${styles.navigationItemsContainer} ${
        isSmallerScreen && styles.navigationItemsContainer__Responsive
      }`}
    >
      {navigationItems.map((item: INavigationItem, index: number) => {
        return (
          <Flex key={index} pointer={item.exist} onClick={() => onItemClick(item)}>
            <RText
              fontSize={isSmallerScreen ? "11" : "13"}
              immutableWhite
              className={`
                                ${
                                  selectedNavItem && selectedNavItem.title === item.title
                                    ? styles.activeItem
                                    : ""
                                }
                                ${!item.exist ? styles.disabledItem : ""}
                            `}
            >
              {t(item.title)}
            </RText>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default HubFooterNavigation;
