// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_active__IF-1s {\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_active__IF-1s p {\n  font-weight: bold;\n}\n\n.styles_menuItem__m3Nog {\n  padding: 20px;\n  cursor: pointer;\n}\n\n.styles_menuItem__m3Nog:hover {\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_itemValue__ftpIe {\n  padding-left: 5px;\n  font-weight: bold;\n}\n\n.styles_disabled__BqZpA {\n  cursor: default;\n  opacity: 0.5;\n}\n\n.styles_rightIcon__019GF {\n  width: 12px;\n  height: 18px;\n}\n\n.styles_sideNavIcon__hkyyt {\n  height: 40px;\n  width: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/executives/dossier/components/side-navigation/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;AACrD;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".active {\n  background-color: var(--color_hover_selected_light);\n}\n\n.active p {\n  font-weight: bold;\n}\n\n.menuItem {\n  padding: 20px;\n  cursor: pointer;\n}\n\n.menuItem:hover {\n  background-color: var(--color_hover_selected_light);\n}\n\n.itemValue {\n  padding-left: 5px;\n  font-weight: bold;\n}\n\n.disabled {\n  cursor: default;\n  opacity: 0.5;\n}\n\n.rightIcon {\n  width: 12px;\n  height: 18px;\n}\n\n.sideNavIcon {\n  height: 40px;\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": "styles_active__IF-1s",
	"menuItem": "styles_menuItem__m3Nog",
	"itemValue": "styles_itemValue__ftpIe",
	"disabled": "styles_disabled__BqZpA",
	"rightIcon": "styles_rightIcon__019GF",
	"sideNavIcon": "styles_sideNavIcon__hkyyt"
};
export default ___CSS_LOADER_EXPORT___;
