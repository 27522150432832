const root = document.documentElement;

const getColorValue = (colorKeyName: string) => {
  return getComputedStyle(root).getPropertyValue(colorKeyName);
};

const setColorValue = (colorKeyName: string, colorValue: string) => {
  root.style.setProperty(colorKeyName, colorValue);
};

export { getColorValue, setColorValue };
