// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__f4XCe {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-left: 4px;\n  padding-right: 4px;\n  background-color: var(--color_primary_dark);\n}\n\n.styles_circular__DFNns {\n  border-radius: 100px;\n}\n\n.styles_primaryBlue__h1mfe {\n  background-color: var(--color_secondary_dark);\n}\n\n.styles_primaryGreen__qLZIq {\n  background-color: var(--color_primary_light);\n}\n\n.styles_primaryGreenDark__wFzpJ {\n  background-color: var(--color_primary_dark);\n}\n", "",{"version":3,"sources":["webpack://./src/components/containers/ColoredContainer/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".component {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-left: 4px;\n  padding-right: 4px;\n  background-color: var(--color_primary_dark);\n}\n\n.circular {\n  border-radius: 100px;\n}\n\n.primaryBlue {\n  background-color: var(--color_secondary_dark);\n}\n\n.primaryGreen {\n  background-color: var(--color_primary_light);\n}\n\n.primaryGreenDark {\n  background-color: var(--color_primary_dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__f4XCe",
	"circular": "styles_circular__DFNns",
	"primaryBlue": "styles_primaryBlue__h1mfe",
	"primaryGreen": "styles_primaryGreen__qLZIq",
	"primaryGreenDark": "styles_primaryGreenDark__wFzpJ"
};
export default ___CSS_LOADER_EXPORT___;
