import { IUserResponse } from "../../../models/login/user.model";

export enum SELECTED_FORM {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
}

export enum USER_BUSINESS_TYPE {
  B2B = "B2B",
  B2C = "B2C",
}

export enum THEME_MODE {
  LIGHT = "light",
  DARK = "dark",
}

export interface ILoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface IRegisterFormData extends ILoginFormData {
  confirmPassword: string;
}

export interface ILoginResponseModel {
  id: number;
  token_key: string;
  user: IUserResponse;
  expires_at: Date;
  last_active_date: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IForgotPasswordFormData {
  email: string;
}
