// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_toolbarContent__fy3zf {\n  height: 35px;\n}\n\n.styles_toolbarContent__Responsive__p\\+qNg {\n  height: 25px;\n}\n\n.styles_searchResults__QtwII {\n  margin-left: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/Toolbar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".toolbarContent {\n  height: 35px;\n}\n\n.toolbarContent__Responsive {\n  height: 25px;\n}\n\n.searchResults {\n  margin-left: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContent": "styles_toolbarContent__fy3zf",
	"toolbarContent__Responsive": "styles_toolbarContent__Responsive__p+qNg",
	"searchResults": "styles_searchResults__QtwII"
};
export default ___CSS_LOADER_EXPORT___;
