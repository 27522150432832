import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { ICorporateHubMarker } from "../../../../entities/corporates/interfaces/hub-marker.interface";
import { useHubRolloverCorporate } from "../../../../modules/hub/hooks/hub-rollover-corporate";
import { StyledPopup } from "../../../home/corporates/dossier/components/map/StyledPopup";
import HubMarkerPopup from "../HubMarkerPopup/HubMarkerPopup";
import {
  createHubDivIconFromMarkerData,
  isPurpleMarker,
} from "../../../../helpers/mapMarker.helper";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  hubMarkers: ICorporateHubMarker[];
}

const HubMapMarkers = ({ hubMarkers }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  if (!hubMarkers) {
    return null;
  }

  return (
    <>
      {hubMarkers.map((hm: ICorporateHubMarker) => {
        if (!hm.coordinates.lat || !hm.coordinates.lat) {
          return null;
        }

        return (
          <MarkerWrapper key={hm.corporatesData[0]?.corporateId} marker={hm}>
            <StyledPopup isSmallerScreen={isSmallerScreen}>
              <HubMarkerPopup markerCorporates={hm.corporatesData} />
            </StyledPopup>
          </MarkerWrapper>
        );
      })}
    </>
  );
};

export default React.memo(HubMapMarkers);

interface IMarkerWrapperProps {
  marker: ICorporateHubMarker;
  children: React.ReactNode;
}

const MarkerWrapper = React.memo(({ marker, children }: IMarkerWrapperProps) => {
  const { hubRolloverCorporateId } = useHubRolloverCorporate();
  const markerRef = useRef(null);
  const isActiveMarker: boolean = useMemo(
    () => isPurpleMarker(marker, hubRolloverCorporateId),
    [hubRolloverCorporateId, marker],
  );

  useEffect(() => {
    if (!markerRef.current) {
      return;
    }

    markerRef.current.closePopup();

    if (isActiveMarker) {
      markerRef.current.openPopup();
    }
  }, [isActiveMarker]);

  const icon = useMemo(
    () => createHubDivIconFromMarkerData(marker, hubRolloverCorporateId),
    [hubRolloverCorporateId, marker],
  );

  const position = useMemo(
    () => ({
      lat: marker.coordinates.lat,
      lng: marker.coordinates.lon,
    }),
    [marker.coordinates],
  );

  return (
    <Marker
      ref={markerRef}
      position={position}
      icon={icon}
      zIndexOffset={isActiveMarker ? 100000 : 1}
    >
      {children}
    </Marker>
  );
});
