import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import Divider from "../../../components/dividers/Divider/Divider";
import RText from "../../../components/fonts/RText/RText";
import CorporatesSearchResultsListHeader from "../../../components/lists/SearchResultsList/CorporatesSearchResultsListHeader/CorporatesSearchResultsListHeader";
import CorporatesSearchResultsListItem, {
  mapCorporatesSearchResponseIntoResultItems,
} from "../../../components/lists/SearchResultsList/CorporatesSearchResultsListItem/CorporatesSearchResultsListItem";
import Spinner from "../../../components/other/Spinner/Spinner";
import { Spacing } from "../../../components/spacing/component";
import { useStartupsSearchResponse } from "../../../entities/startups-filters/hooks/startups-search-response.hook";

const StartupsSearchResults = () => {
  const { t } = useTranslation();

  const { searchResultTotalCount, searchResults, fetchMore, loading } =
    useStartupsSearchResponse();

  if (!searchResults?.length) {
    if (loading) {
      return <Spinner />;
    }

    return (
      <Spacing pt="48">
        <RText fontSize="28">{t("noSearchResults")}</RText>
      </Spacing>
    );
  }

  return (
    <Spacing pt="48">
      <CorporatesSearchResultsListHeader
        column1={t("company")}
        column2={t("productAffinity")}
        column3={t("industry")}
      />

      <Spacing pt="12" />

      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={fetchMore}
        hasMore={searchResults.length < searchResultTotalCount}
        loader={<Spinner key={searchResults.length + 1} />}
      >
        <Divider />
        {mapCorporatesSearchResponseIntoResultItems(searchResults).map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <CorporatesSearchResultsListItem key={item.id} item={item} />
              <Divider />
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </Spacing>
  );
};

export default StartupsSearchResults;
