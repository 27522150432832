// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_infoContainer__TfS3w {\n  height: 88px;\n}\n\n.styles_firstColumn__UAbW- {\n  flex: 2 1;\n}\n\n.styles_text__bwuhg {\n  width: 102px;\n}\n\n.styles_companiesContainer__WFh1d {\n  position: relative;\n  height: 100%;\n  max-height: 890px;\n  width: 100%;\n  overflow-y: scroll;\n}\n\n.styles_companiesContainer__WFh1d::-webkit-scrollbar {\n  display: none;\n}\n\n.styles_companiesContainer__WFh1d {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.styles_lookALikesMapIcon__hhz9I {\n  width: 40px;\n  height: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/corporates/dossier/components/side-navigation-item-content/look-a-likes/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".infoContainer {\n  height: 88px;\n}\n\n.firstColumn {\n  flex: 2;\n}\n\n.text {\n  width: 102px;\n}\n\n.companiesContainer {\n  position: relative;\n  height: 100%;\n  max-height: 890px;\n  width: 100%;\n  overflow-y: scroll;\n}\n\n.companiesContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.companiesContainer {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.lookALikesMapIcon {\n  width: 40px;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoContainer": "styles_infoContainer__TfS3w",
	"firstColumn": "styles_firstColumn__UAbW-",
	"text": "styles_text__bwuhg",
	"companiesContainer": "styles_companiesContainer__WFh1d",
	"lookALikesMapIcon": "styles_lookALikesMapIcon__hhz9I"
};
export default ___CSS_LOADER_EXPORT___;
