// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__y06-M {\n  height: 100%;\n  width: 1px;\n  background-color: var(--color_gray);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubHeader/HubFiltersDivider/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,mCAAmC;AACrC","sourcesContent":[".component {\n  height: 100%;\n  width: 1px;\n  background-color: var(--color_gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__y06-M"
};
export default ___CSS_LOADER_EXPORT___;
