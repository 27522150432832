// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_text__lO\\+aJ {\n  line-height: 1.5;\n}\n\n.styles_sectorCodeTitle__W6Z04 {\n  color: var(--color_text_disabled);\n}\n\n.styles_selectedNavItem__G9oUZ {\n  color: var(--color_text_dark);\n}\n\n.styles_selectedDividerLine__YVJau {\n  position: relative;\n  top: 1px;\n}\n\n.styles_itemContainer__IMZgg {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  cursor: pointer;\n}\n\n.styles_itemRowContainer__MIqay {\n  height: 100%;\n}\n\n.styles_itemRowTextContainer__VnROE {\n  padding: 10px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/corporates/dossier/components/side-navigation-item-content/main-industry/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,0BAAkB;EAAlB,uBAAkB;EAAlB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".text {\n  line-height: 1.5;\n}\n\n.sectorCodeTitle {\n  color: var(--color_text_disabled);\n}\n\n.selectedNavItem {\n  color: var(--color_text_dark);\n}\n\n.selectedDividerLine {\n  position: relative;\n  top: 1px;\n}\n\n.itemContainer {\n  width: fit-content;\n  cursor: pointer;\n}\n\n.itemRowContainer {\n  height: 100%;\n}\n\n.itemRowTextContainer {\n  padding: 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles_text__lO+aJ",
	"sectorCodeTitle": "styles_sectorCodeTitle__W6Z04",
	"selectedNavItem": "styles_selectedNavItem__G9oUZ",
	"selectedDividerLine": "styles_selectedDividerLine__YVJau",
	"itemContainer": "styles_itemContainer__IMZgg",
	"itemRowContainer": "styles_itemRowContainer__MIqay",
	"itemRowTextContainer": "styles_itemRowTextContainer__VnROE"
};
export default ___CSS_LOADER_EXPORT___;
