import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styles from "./styles.module.css";
// import ArrowUp from "../../../../assets/hub/ArrowUp.svg";
import { ReactComponent as BlackCube } from "../../../../assets/hub/BlackCube.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { Spacing } from "../../../../components/spacing/component";
import { easeOutQuad } from "../../../../components/animations/count-up-animation/CountUpAnimation";
import RText from "../../../../components/fonts/RText/RText";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import HubFiltersDivider from "../HubFiltersDivider/HubFiltersDivider";
import { useHubAnimations } from "../../../../modules/hub/hooks/hub-animations.hook";
import useAppColors from "../../../../hooks/app-colors.hook";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import Toolbar from "../../../home/Toolbar/Toolbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

export enum PROGRESS_BAR_TYPE {
  CIRCULAR,
  VERTICAL,
}

export interface IAnimation {
  title: string;
  count: number;
  percentage: number;
  pathColor: string;
  trailColor: string;
  Icon: React.ReactNode;
  type: PROGRESS_BAR_TYPE;
}

const HubKPIs = () => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {
    hubCompaniesWithTrendsCount,
    hubCompaniesWithNewsCount,
    hubCompaniesWithTrendsAndNewsCount,
    unfilteredHubCorporatesCount,
  } = useHubAnimations();

  const calculatePercentage = (count: number | null) => {
    if (
      count &&
      count > 0 &&
      unfilteredHubCorporatesCount &&
      unfilteredHubCorporatesCount > 0
    ) {
      return Math.round((count * 100) / unfilteredHubCorporatesCount);
    }

    return 0;
  };

  const potentialClientsAndNews: IAnimation[] = [
    {
      title: t("potentialClients"),
      count: unfilteredHubCorporatesCount ?? 0,
      percentage: calculatePercentage(unfilteredHubCorporatesCount),
      pathColor: colors.secondaryDarkColor,
      trailColor: colors.secondaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.CIRCULAR,
    },
    {
      title: t("news"),
      count: hubCompaniesWithNewsCount ?? 0,
      percentage: calculatePercentage(hubCompaniesWithNewsCount),
      pathColor: colors.secondaryDarkColor,
      trailColor: colors.secondaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.VERTICAL,
    },
  ];

  const targetsAndNews: IAnimation[] = [
    {
      title: t("targets"),
      count: hubCompaniesWithTrendsCount ?? 0,
      percentage: calculatePercentage(hubCompaniesWithTrendsCount),
      pathColor: colors.primaryDarkColor,
      trailColor: colors.primaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.CIRCULAR,
    },
    {
      title: t("news"),
      count: hubCompaniesWithTrendsAndNewsCount ?? 0,
      percentage: calculatePercentage(hubCompaniesWithTrendsAndNewsCount),
      pathColor: colors.primaryDarkColor,
      trailColor: colors.primaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.VERTICAL,
    },
  ];

  return (
    <Flex justifyContentSpaceEvenly flex className={styles.animations}>
      {isDesktop && <Toolbar />}
      <Flex alignItemsCenter>
        {potentialClientsAndNews.map((animation, index: number) => {
          return (
            <Fragment key={index}>
              <Flex alignItemsCenter>
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={animation.percentage}
                  duration={1000}
                  easingFunction={easeOutQuad}
                >
                  {(value: number) => {
                    // const roundedValue = value % 1 ? value.toFixed(1) : value;
                    const roundedValue = Math.round(value);

                    return animation.type === PROGRESS_BAR_TYPE.CIRCULAR ? (
                      <div
                        className={`${styles.circularAnimationContainer} ${
                          isSmallerScreen && styles.circularAnimationContainer__Responsive
                        }`}
                      >
                        <CircularProgressbar
                          value={value}
                          maxValue={100}
                          text={`${roundedValue}%`}
                          circleRatio={0.8}
                          styles={buildStyles({
                            pathTransition: "none",
                            rotation: 1 / 2 + 1 / 10,
                            strokeLinecap: "butt",
                            textColor: colors.textDarkColor,
                            pathColor: animation.pathColor,
                            trailColor: animation.trailColor,
                          })}
                        />
                      </div>
                    ) : (
                      <VerticalProgressBar
                        value={roundedValue}
                        trailColor={animation.trailColor}
                        pathColor={animation.pathColor}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
                <Spacing pr="15" />
                <AnimationDescription
                  title={animation.title}
                  count={animation.count}
                  Icon={animation.Icon}
                />
              </Flex>
              {index < potentialClientsAndNews.length - 1 && <Spacing pr="20" />}
            </Fragment>
          );
        })}
      </Flex>
      <HubFiltersDivider />
      <Flex>
        {targetsAndNews.map((animation, index: number) => {
          return (
            <Fragment key={index}>
              <Flex alignItemsCenter>
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={animation.percentage}
                  duration={1000}
                  easingFunction={easeOutQuad}
                >
                  {(value: number) => {
                    // const roundedValue = value % 1 ? value.toFixed(1) : value;
                    const roundedValue = Math.round(value);

                    return animation.type === PROGRESS_BAR_TYPE.CIRCULAR ? (
                      <div className={styles.circularAnimationContainer}>
                        <CircularProgressbar
                          value={value}
                          maxValue={100}
                          text={`${roundedValue}%`}
                          circleRatio={0.8}
                          styles={buildStyles({
                            pathTransition: "none",
                            rotation: 1 / 2 + 1 / 10,
                            strokeLinecap: "butt",
                            textColor: colors.textDarkColor,
                            pathColor: animation.pathColor,
                            trailColor: animation.trailColor,
                          })}
                        />
                      </div>
                    ) : (
                      <VerticalProgressBar
                        value={roundedValue}
                        trailColor={animation.trailColor}
                        pathColor={animation.pathColor}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
                <Spacing pr="15" />
                <AnimationDescription
                  title={animation.title}
                  count={animation.count}
                  Icon={animation.Icon}
                />
              </Flex>
              {index < targetsAndNews.length - 1 && <Spacing pr="20" />}
            </Fragment>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default React.memo(HubKPIs);

interface IAnimationDescriptionProps {
  title: string;
  count: number;
  Icon: React.ReactNode;
}

export const AnimationDescription = ({ title, count, Icon }: IAnimationDescriptionProps) => {
  return (
    <Flex column>
      <RText fontSize="13">{title}</RText>
      <Spacing pt="5" />
      <Flex alignItemsCenter>
        <RText fontSize="15" bold>
          {formatNumberWithDotSeparator(count)}
        </RText>
        <Spacing pr="5" />
        {Icon}
      </Flex>
    </Flex>
  );
};

interface IVBarProps {
  value: number;
  trailColor: string;
  pathColor: string;
}

export const VerticalProgressBar = ({ value, trailColor, pathColor }: IVBarProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <div
      className={`${styles.lineContainer} ${
        isSmallerScreen && styles.lineContainer__Responsive
      }`}
      style={{ backgroundColor: trailColor }}
    >
      <div
        className={styles.filledContainer}
        style={{
          height: value + "%",
          maxHeight: "100%",
          backgroundColor: pathColor,
        }}
      />
    </div>
  );
};
