import React, { useContext } from "react";
import styles from "./styles.module.css";
import NewsArrowsLeft from "../../../../../assets/hub/NewsArrowsLeft.svg";
import NewsArrowsRight from "../../../../../assets/hub/NewsArrowsRight.svg";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../components/spacing/component";
import { IHubFooterItemData } from "../../../../../shared/interfaces/hub-footer-item-data.interface";
import RLink from "../../../../../components/link/RLink";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  item: IHubFooterItemData;
}

export default function NewsItem({ item }: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex column justifyContentSpaceBetween className={styles.newsItem}>
      <StarRating affinity={item.company.affinity_score} spacing="5" iconHeight="12" />
      <Spacing pb="4" />
      <RLink
        path={{
          pathname: `/home/search/corporate-dossier/${item.company.id}`,
          state: { fromHub: true },
        }}
      >
        <RText fontSize={isSmallerScreen ? "11" : "13"} bold immutableWhite>
          {item.company.company_name}
        </RText>
      </RLink>

      <Spacing pb="4" />
      <div className={styles.divider} />
      <Spacing pb="2" />

      {item.companyNewsTitle && (
        <div className={styles.newsTitleContainer}>
          <img src={NewsArrowsRight} alt="" className={styles.arrowIcon} />
          <RText immutableWhite fontSize={isSmallerScreen ? "11" : "13"}>
            {`${item.companyNewsTitle.substring(0, 60)}...`}
          </RText>
          <img src={NewsArrowsLeft} alt="" className={styles.arrowIcon} />
        </div>
      )}
    </Flex>
  );
}
