// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_defaultTag__zmHaW {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n}\n\n.styles_defaultTag__Responsive__glItj {\n  width: 15px;\n  height: 15px;\n}\n\n.styles_tagCompanyTitle__9mEUq {\n  line-height: 1.2 !important;\n  letter-spacing: 0.5px !important;\n}\n\n.styles_userTagsContentContainer__oWZs5 {\n  height: 30px;\n  padding: 5px 4px 5px 10px;\n}\n\n.styles_searchResultsTagsCloseIcon__OnX0o {\n  width: 18px;\n  height: 18px;\n}\n\n.styles_arrowIcon__Avwe1 {\n  width: 18px;\n  height: 18px;\n}\n\n.styles_addIcon__mSzvL {\n  width: 8px;\n  height: 8px;\n}\n\n.styles_tagPopup__zXW0r {\n  position: absolute;\n  top: 25px;\n  z-index: 201;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 4px;\n  padding: 15px 0;\n  min-width: 350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/tags/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".defaultTag {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n}\n\n.defaultTag__Responsive {\n  width: 15px;\n  height: 15px;\n}\n\n.tagCompanyTitle {\n  line-height: 1.2 !important;\n  letter-spacing: 0.5px !important;\n}\n\n.userTagsContentContainer {\n  height: 30px;\n  padding: 5px 4px 5px 10px;\n}\n\n.searchResultsTagsCloseIcon {\n  width: 18px;\n  height: 18px;\n}\n\n.arrowIcon {\n  width: 18px;\n  height: 18px;\n}\n\n.addIcon {\n  width: 8px;\n  height: 8px;\n}\n\n.tagPopup {\n  position: absolute;\n  top: 25px;\n  z-index: 201;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 4px;\n  padding: 15px 0;\n  min-width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultTag": "styles_defaultTag__zmHaW",
	"defaultTag__Responsive": "styles_defaultTag__Responsive__glItj",
	"tagCompanyTitle": "styles_tagCompanyTitle__9mEUq",
	"userTagsContentContainer": "styles_userTagsContentContainer__oWZs5",
	"searchResultsTagsCloseIcon": "styles_searchResultsTagsCloseIcon__OnX0o",
	"arrowIcon": "styles_arrowIcon__Avwe1",
	"addIcon": "styles_addIcon__mSzvL",
	"tagPopup": "styles_tagPopup__zXW0r"
};
export default ___CSS_LOADER_EXPORT___;
