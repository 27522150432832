// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_scoreContainer__uNHBE {\n  position: relative;\n}\n\n.styles_scoreValue__7Mr4G {\n  position: absolute;\n  margin: 0 auto;\n  left: 0;\n  right: 0;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/executives/dossier/shared/EAndEScore/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,OAAO;EACP,QAAQ;EACR,kBAAkB;AACpB","sourcesContent":[".scoreContainer {\n  position: relative;\n}\n\n.scoreValue {\n  position: absolute;\n  margin: 0 auto;\n  left: 0;\n  right: 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreContainer": "styles_scoreContainer__uNHBE",
	"scoreValue": "styles_scoreValue__7Mr4G"
};
export default ___CSS_LOADER_EXPORT___;
