// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_newsTitleContainer__ruc7M {\n  width: 275px;\n  white-space: normal;\n}\n\n.styles_newsTitleContainer__ruc7M > p {\n  display: inline;\n  padding: 0px 4px;\n}\n\n.styles_newsItem__sGVeE {\n  height: 100%;\n  padding: 4px 10px;\n}\n\n.styles_newsItem__sGVeE:hover {\n  background-color: var(--immutable_black_light);\n}\n\n.styles_arrowIcon__MLt35 {\n  width: 11px;\n  height: 11px;\n}\n\n.styles_divider__Ca1u- {\n  height: 2px;\n  width: 30px;\n  background-color: var(--immutable_background_white);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubFooter/HubFooterItem/NewsItem/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,mDAAmD;AACrD","sourcesContent":[".newsTitleContainer {\n  width: 275px;\n  white-space: normal;\n}\n\n.newsTitleContainer > p {\n  display: inline;\n  padding: 0px 4px;\n}\n\n.newsItem {\n  height: 100%;\n  padding: 4px 10px;\n}\n\n.newsItem:hover {\n  background-color: var(--immutable_black_light);\n}\n\n.arrowIcon {\n  width: 11px;\n  height: 11px;\n}\n\n.divider {\n  height: 2px;\n  width: 30px;\n  background-color: var(--immutable_background_white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsTitleContainer": "styles_newsTitleContainer__ruc7M",
	"newsItem": "styles_newsItem__sGVeE",
	"arrowIcon": "styles_arrowIcon__MLt35",
	"divider": "styles_divider__Ca1u-"
};
export default ___CSS_LOADER_EXPORT___;
