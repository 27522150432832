// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_animatedCircleIconContainer__THMtf {\n  width: 100%;\n  height: 100%;\n}\n\n.styles_circleContainer__SjjVR {\n  position: relative;\n  border: 1px solid var(--immutable_background_black);\n  border-radius: 100%;\n  width: 65%;\n  height: 65%;\n  padding: 7px;\n}\n\n.styles_percentageContainer__V-9cK {\n  position: absolute;\n  background-color: var(--immutable_background_white);\n  height: 10px;\n  width: 10px;\n  left: 30px;\n  padding: 10px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/animations/animated-circle-icon/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mDAAmD;EACnD,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mDAAmD;EACnD,YAAY;EACZ,WAAW;EACX,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".animatedCircleIconContainer {\n  width: 100%;\n  height: 100%;\n}\n\n.circleContainer {\n  position: relative;\n  border: 1px solid var(--immutable_background_black);\n  border-radius: 100%;\n  width: 65%;\n  height: 65%;\n  padding: 7px;\n}\n\n.percentageContainer {\n  position: absolute;\n  background-color: var(--immutable_background_white);\n  height: 10px;\n  width: 10px;\n  left: 30px;\n  padding: 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animatedCircleIconContainer": "styles_animatedCircleIconContainer__THMtf",
	"circleContainer": "styles_circleContainer__SjjVR",
	"percentageContainer": "styles_percentageContainer__V-9cK"
};
export default ___CSS_LOADER_EXPORT___;
