// add allowed tenants here only, the order is important
// since the first item is the default tenant
export const allowedTenants = ["de", "ch"] as const;

// do not change
export type IAllowedTenants = (typeof allowedTenants)[number];

const currentPathTenant = (() => {
  const loc = window.location.pathname?.split("/");
  const tenantCandidate = loc[1];
  if (
    !(
      loc[0] === "" &&
      allowedTenants.some((allowedTenant) => allowedTenant === tenantCandidate)
    )
  ) {
    return undefined;
  }
  return tenantCandidate as IAllowedTenants;
})();

export function getCurrentTenant(): {
  tenant: IAllowedTenants;
  subdomainTenant: boolean;
} {
  // if the first path element is an allowed tenant, use it...
  if (currentPathTenant) {
    return {
      tenant: currentPathTenant,
      subdomainTenant: false,
    };
  }
  //... otherwise try to get tenant from the subdomain or fall back to the default tenant
  const hostnamePrefix = window.location.hostname.split(".")[0]?.toLowerCase();
  const currentSubdomainTenant = hostnamePrefix
    ? allowedTenants.find((allowedTenant) => allowedTenant === hostnamePrefix) ??
      allowedTenants[0]
    : allowedTenants[0];
  return {
    tenant: currentSubdomainTenant,
    subdomainTenant: true,
  };
}
