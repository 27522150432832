// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__xKX2z {\n  cursor: pointer;\n}\n\n.styles_active__H7M2X {\n  background: var(--color_hover_selected_light);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubHeader/HubFilterValue/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".component {\n  cursor: pointer;\n}\n\n.active {\n  background: var(--color_hover_selected_light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__xKX2z",
	"active": "styles_active__H7M2X"
};
export default ___CSS_LOADER_EXPORT___;
