// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__MIz0o {\n  flex: 1 1;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.styles_collapsed__eUmxQ {\n  visibility: hidden;\n  height: 0;\n}\n\n.styles_showMore__JgaAd {\n  height: 28px;\n  display: flex;\n  margin-right: 30px;\n  padding-right: 4px;\n  padding-left: 4px;\n  cursor: pointer;\n  align-items: center;\n}\n\n.styles_title__K0tCZ {\n  height: 28px;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/filters/Filter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".component {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.collapsed {\n  visibility: hidden;\n  height: 0;\n}\n\n.showMore {\n  height: 28px;\n  display: flex;\n  margin-right: 30px;\n  padding-right: 4px;\n  padding-left: 4px;\n  cursor: pointer;\n  align-items: center;\n}\n\n.title {\n  height: 28px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__MIz0o",
	"collapsed": "styles_collapsed__eUmxQ",
	"showMore": "styles_showMore__JgaAd",
	"title": "styles_title__K0tCZ"
};
export default ___CSS_LOADER_EXPORT___;
