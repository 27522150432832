// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__8DHLN {\n  cursor: pointer;\n  padding: 3px 8px 3px 13px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.styles_active__UTu81 {\n  background: var(--color_hover_selected_light);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubHeader/HubFilter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".component {\n  cursor: pointer;\n  padding: 3px 8px 3px 13px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.active {\n  background: var(--color_hover_selected_light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__8DHLN",
	"active": "styles_active__UTu81"
};
export default ___CSS_LOADER_EXPORT___;
