import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const useCaptureLastVisitedPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login") return;
    if (location.pathname === "/") return;
    if (location.pathname === "/home") return;

    localStorage.setItem("lastVisitedPage", location.pathname);
  }, [location]);
};
