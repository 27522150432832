// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_goToItemsContainer__9qDP0 {\n  position: relative;\n  height: 100%;\n  max-height: 100px;\n  width: 100%;\n  overflow-y: scroll;\n  z-index: 1111;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.styles_goToItemsContainer__9qDP0::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.styles_goToItemsContainer__9qDP0 {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n.styles_goToItem__J5amD {\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n.styles_goToSubItem__007nR {\n  padding-top: 5px;\n  padding-bottom: 5px;\n  padding-left: 22px;\n}\n\n.styles_goToSubItem__007nR:hover {\n  background-color: var(--color_hover_selected_light);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/Toolbar/GoTo/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":[".goToItemsContainer {\n  position: relative;\n  height: 100%;\n  max-height: 100px;\n  width: 100%;\n  overflow-y: scroll;\n  z-index: 1111;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.goToItemsContainer::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.goToItemsContainer {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n.goToItem {\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n.goToSubItem {\n  padding-top: 5px;\n  padding-bottom: 5px;\n  padding-left: 22px;\n}\n\n.goToSubItem:hover {\n  background-color: var(--color_hover_selected_light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goToItemsContainer": "styles_goToItemsContainer__9qDP0",
	"goToItem": "styles_goToItem__J5amD",
	"goToSubItem": "styles_goToSubItem__007nR"
};
export default ___CSS_LOADER_EXPORT___;
