// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_divider__kXFRJ {\n  height: 2px !important;\n}\n\n.styles_contentContainer__U489j {\n  position: relative;\n  width: 100%;\n  /* height: 100%;\n    max-height: 820px;\n    overflow-y: scroll; */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.styles_contentContainer__U489j::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.styles_contentContainer__U489j {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/corporates/dossier/components/side-navigation-item-content/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX;;yBAEuB;AACzB;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC","sourcesContent":[".divider {\n  height: 2px !important;\n}\n\n.contentContainer {\n  position: relative;\n  width: 100%;\n  /* height: 100%;\n    max-height: 820px;\n    overflow-y: scroll; */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.contentContainer::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.contentContainer {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "styles_divider__kXFRJ",
	"contentContainer": "styles_contentContainer__U489j"
};
export default ___CSS_LOADER_EXPORT___;
