import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";
import { ISelectedValuesRange } from "../../shared/interfaces/selected-value-types.interface";

export const dynamicRangeFilterCountsByType: {
  [key: string]: (
    filterValues: Array<ICorporatesFilterValue>,
    selectedValue: ISelectedValuesRange,
  ) => number;
} = {
  industry_sector_codes_full: () => undefined,
};

export const defaultDynamicRangeFilterCount = (
  filterValues: Array<ICorporatesFilterValue>,
  selectedValue: ISelectedValuesRange,
) =>
  filterValues.reduce((acc, filterValue) => {
    if (
      parseInt(filterValue.value_key) >= parseInt(selectedValue.from) &&
      parseInt(filterValue.value_key) < parseInt(selectedValue.to)
    ) {
      acc += filterValue.doc_count;
    }
    return acc;
  }, 0);
