// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__L\\+jIz {\n  min-width: 35px;\n  margin-right: 10px;\n  height: 20px;\n  background: var(--immutable_background_white);\n  border: 1px solid var(--color_gray_dark);\n  border-radius: 30px;\n  display: flex;\n  align-items: center;\n  padding: 1px;\n  justify-content: flex-start;\n}\n\n.styles_containerActive__LSDBV {\n  justify-content: flex-end;\n}\n\n.styles_switch__jAecX {\n  border-radius: 100px;\n  width: 14px;\n  height: 14px;\n  background: var(--color_secondary_dark);\n  justify-self: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/corporates/dossier/components/map/map-controls/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,6CAA6C;EAC7C,wCAAwC;EACxC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,uCAAuC;EACvC,wBAAwB;AAC1B","sourcesContent":[".container {\n  min-width: 35px;\n  margin-right: 10px;\n  height: 20px;\n  background: var(--immutable_background_white);\n  border: 1px solid var(--color_gray_dark);\n  border-radius: 30px;\n  display: flex;\n  align-items: center;\n  padding: 1px;\n  justify-content: flex-start;\n}\n\n.containerActive {\n  justify-content: flex-end;\n}\n\n.switch {\n  border-radius: 100px;\n  width: 14px;\n  height: 14px;\n  background: var(--color_secondary_dark);\n  justify-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__L+jIz",
	"containerActive": "styles_containerActive__LSDBV",
	"switch": "styles_switch__jAecX"
};
export default ___CSS_LOADER_EXPORT___;
