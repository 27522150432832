import React, { createContext, useEffect, useState } from "react";
import { useWindowSize } from "../../../hooks/window.hook";
import { isMobile } from "react-device-detect";

interface IResolutionContext {
  isSmallerScreen: boolean;
}

export const ResolutionContext = createContext<IResolutionContext>({
  isSmallerScreen: false,
});

export const ResolutionContextProvider = ({ children }: { children: JSX.Element }) => {
  const { width } = useWindowSize();
  const [isSmallerScreen, setIsSmallerScreen] = useState(width <= 1366 || isMobile);

  useEffect(() => {
    if (width <= 1366 || isMobile) {
      setIsSmallerScreen(true);
    } else {
      setIsSmallerScreen(false);
    }
  }, [width]);

  return (
    <ResolutionContext.Provider value={{ isSmallerScreen }}>
      {children}
    </ResolutionContext.Provider>
  );
};
