// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_horizontallyScrollable__OXwE8 {\n  overflow: auto;\n  white-space: nowrap;\n  display: flex;\n  align-items: center;\n  flex: 1 1;\n\n  /* Hide scrollbar for IE, Edge and Firefox */\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.styles_horizontallyScrollable__OXwE8::-webkit-scrollbar {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/containers/HorizontallyScrollable/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,SAAO;;EAEP,4CAA4C;EAC5C,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf","sourcesContent":[".horizontallyScrollable {\n  overflow: auto;\n  white-space: nowrap;\n  display: flex;\n  align-items: center;\n  flex: 1;\n\n  /* Hide scrollbar for IE, Edge and Firefox */\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.horizontallyScrollable::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontallyScrollable": "styles_horizontallyScrollable__OXwE8"
};
export default ___CSS_LOADER_EXPORT___;
