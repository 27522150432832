import { akitaConfig, combineQueries, resetStores } from "@datorama/akita";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { userService } from "../../../api/services/user.service";
import DefaultB2CMoveLogo from "../../../assets/B2CMoveLogoTextIcon.svg";
import DefaultMoveLogo from "../../../assets/MoveLogoTextIcon.svg";
import { b2bDefaultColors, b2cDefaultColors } from "../../../constants/colors";
import { API_LOGOS_URL } from "../../../constants/urls";
import { IClient, IColors } from "../../../models/login/client.model";
import { IUser, IUserResponse } from "../../../models/login/user.model";
import { setColorValue } from "../../../utils/css-colors.util";
import {
  IForgotPasswordFormData,
  ILoginFormData,
  ILoginResponseModel,
  IRegisterFormData,
  THEME_MODE,
  USER_BUSINESS_TYPE,
} from "./session.model";
import { sessionQuery, SessionQuery } from "./session.query";
import { sessionStore, SessionStore } from "./session.store";

akitaConfig({ resettable: true });

export interface ISessionFacade {
  currentUser$: Observable<IUser | undefined>;

  token$: Observable<string | null>;

  login(loginData: ILoginFormData): Promise<ILoginResponseModel>;

  registerUser(registerData: IRegisterFormData): void;

  fetchCurrentUser(): void;

  logout(): void;

  resetPassword(userData: IForgotPasswordFormData): void;

  isB2CUser(currentUser?: IUser): boolean;

  isDarkTheme(): boolean;

  getCurrentClient(): IClient;

  getCurrentUser(): IUser;

  getToken(): string;

  getColors(): IColors;

  getTagColors(): string[];

  getLogo(): string;

  getTheme(): THEME_MODE;

  setTheme(themeMode: THEME_MODE): void;
}

export class SessionFacade implements ISessionFacade {
  readonly currentUser$ = this.query.currentUser$;
  readonly isLoggedIn$ = this.query.isLoggedIn$;
  readonly token$ = this.query.token$;
  readonly client$ = this.query.select("client");
  readonly theme$ = this.query.select("theme");
  readonly colors$ = combineQueries([this.currentUser$, this.client$, this.theme$]).pipe(
    map(([user, client, theme]) => this._getColorsFromClient(user, client, theme)),
  );
  readonly logo$ = combineQueries([this.currentUser$, this.client$]).pipe(
    map(([user, client]) => this._getLogoFromClient(user, client)),
  );
  readonly tagColors$ = this.query
    .select("client")
    .pipe(map((client: IClient) => this._getTagColorsFromClient(client)));

  constructor(private store: SessionStore, private query: SessionQuery) {
    this.colors$.subscribe((colors: IColors) => {
      this._setClientColors(colors);
    });
  }

  async login(loginData: ILoginFormData): Promise<ILoginResponseModel> {
    try {
      const res = await userService.login(loginData);
      // No user found for given credentials, incorrect email or password
      if (res) {
        const {
          user: { client, ...user },
          token_key: token,
        } = res;

        sessionStore.update({
          token,
          user,
          client: {
            ...client,
            template: client?.b2c_template ?? client?.template ?? ({} as any),
          },
          theme: THEME_MODE.LIGHT,
        });
      }

      return res;
    } catch (e) {
      console.warn("Error while logging in", e);
    }
  }

  async registerUser(registerData: IRegisterFormData) {
    try {
      const { client, login_token, ...user }: IUserResponse = await userService.register(
        registerData,
      );

      sessionStore.update({
        user,
        token: login_token,
        client: {
          ...client,
          template: client?.b2c_template ?? client?.template ?? ({} as any),
        },
      });
    } catch (e) {
      console.warn("Error while registering", e);
    }
  }

  async fetchCurrentUser() {
    const { loadingUser } = this.query.getValue();

    if (loadingUser) {
      return;
    }

    sessionStore.update({
      loadingUser: true,
    });

    try {
      const { client, ...user }: IUserResponse = await userService.current();

      sessionStore.update({
        user,
        client: {
          ...client,
          template: client?.b2c_template ?? client?.template ?? ({} as any),
        },
        loadingUser: false,
      });
    } catch (e) {
      console.warn("Error while fetching user");
    }
  }

  logout = () => {
    resetStores();
  };

  async resetPassword(userEmail: IForgotPasswordFormData) {
    try {
      await userService.resetPassword(userEmail);
    } catch (e) {
      console.warn("Error while reseting password", e);
    }
  }

  isB2CUser = (currentUser?: IUser) => {
    if (!currentUser) {
      const { user } = this.query.getValue();
      currentUser = user;
    }

    return !currentUser ? null : currentUser.business_type === USER_BUSINESS_TYPE.B2C;
  };

  isDarkTheme = () => {
    const theme = this.getTheme();

    return theme === THEME_MODE.DARK;
  };

  getCurrentClient = () => {
    const { client } = this.query.getValue();

    return client;
  };

  getCurrentUser = () => {
    const { user } = this.query.getValue();

    return user;
  };

  getToken = () => {
    const { token } = this.query.getValue();

    return token;
  };

  getColors = () => {
    const { user, client, theme } = this.query.getValue();

    return this._getColorsFromClient(user, client, theme);
  };

  getTagColors = () => {
    const { client } = this.query.getValue();

    return this._getTagColorsFromClient(client);
  };

  getLogo = () => {
    const { user, client } = this.query.getValue();

    return this._getLogoFromClient(user, client);
  };

  getTheme = () => {
    const { theme } = this.query.getValue();

    return theme;
  };

  setTheme = (themeMode: THEME_MODE) => {
    sessionStore.update({
      theme: themeMode,
    });
  };

  private _getColorsFromClient(user: IUser, client: IClient, theme: THEME_MODE) {
    const defaultColors = this.isB2CUser(user) ? b2cDefaultColors : b2bDefaultColors;

    const staticColors = {
      backgroundBlackColor: this._getThemeColor(
        theme,
        defaultColors.BACKGROUND_BLACK_COLOR,
        defaultColors.BACKGROUND_WHITE_COLOR,
      ),
      backgroundWhiteColor: this._getThemeColor(
        theme,
        defaultColors.BACKGROUND_WHITE_COLOR,
        defaultColors.DARK_MODE_BACKGROUND_WHITE_COLOR,
      ),
    };

    if (!Object.keys(client?.template ?? {}).length) {
      return {
        primaryDarkColor: this._getThemeColor(
          theme,
          defaultColors.PRIMARY_DARK_COLOR,
          defaultColors.DARK_MODE_PRIMARY_DARK_COLOR,
        ),
        primaryLightColor: this._getThemeColor(
          theme,
          defaultColors.PRIMARY_LIGHT_COLOR,
          defaultColors.DARK_MODE_PRIMARY_LIGHT_COLOR,
        ),
        secondaryDarkColor: this._getThemeColor(
          theme,
          defaultColors.SECONDARY_DARK_COLOR,
          defaultColors.DARK_MODE_SECONDARY_DARK_COLOR,
        ),
        secondaryLightColor: this._getThemeColor(
          theme,
          defaultColors.SECONDARY_LIGHT_COLOR,
          defaultColors.DARK_MODE_SECONDARY_LIGHT_COLOR,
        ),
        textDarkColor: this._getThemeColor(
          theme,
          defaultColors.TEXT_DARK_COLOR,
          defaultColors.TEXT_LIGHT_COLOR,
        ),
        textLightColor: this._getThemeColor(
          theme,
          defaultColors.TEXT_LIGHT_COLOR,
          defaultColors.TEXT_DARK_COLOR,
        ),
        hoverSelectedLightColor: this._getThemeColor(
          theme,
          defaultColors.HOVER_SELECTED_LIGHT_COLOR,
          defaultColors.HOVER_SELECTED_DARK_COLOR,
        ),
        ...staticColors,
      };
    }

    const {
      color_primary_dark,
      color_primary_light,
      color_secondary_dark,
      color_secondary_light,
      color_text_dark,
      color_text_light,
      color_hover_selected_light,
      dark_mode_color_primary_dark,
      dark_mode_color_primary_light,
      dark_mode_color_secondary_dark,
      dark_mode_color_secondary_light,
      dark_mode_color_text_dark,
      dark_mode_color_text_light,
      dark_mode_color_hover_selected_light,
    } = client.template;

    return {
      primaryDarkColor: `#${this._getThemeColor(
        theme,
        color_primary_dark,
        dark_mode_color_primary_dark,
      )}`,
      primaryLightColor: `#${this._getThemeColor(
        theme,
        color_primary_light,
        dark_mode_color_primary_light,
      )}`,
      secondaryDarkColor: `#${this._getThemeColor(
        theme,
        color_secondary_dark,
        dark_mode_color_secondary_dark,
      )}`,
      secondaryLightColor: `#${this._getThemeColor(
        theme,
        color_secondary_light,
        dark_mode_color_secondary_light,
      )}`,
      textDarkColor: `#${this._getThemeColor(
        theme,
        color_text_dark,
        dark_mode_color_text_dark,
      )}`,
      textLightColor: `#${this._getThemeColor(
        theme,
        color_text_light,
        dark_mode_color_text_light,
      )}`,
      hoverSelectedLightColor: `#${this._getThemeColor(
        theme,
        color_hover_selected_light,
        dark_mode_color_hover_selected_light,
      )}`,
      ...staticColors,
    };
  }

  private _setClientColors(colors: IColors) {
    const {
      primaryDarkColor,
      primaryLightColor,
      secondaryDarkColor,
      secondaryLightColor,
      textDarkColor,
      textLightColor,
      hoverSelectedLightColor,
      backgroundBlackColor,
      backgroundWhiteColor,
    } = colors;

    setColorValue("--color_primary_dark", primaryDarkColor);
    setColorValue("--color_primary_light", primaryLightColor);
    setColorValue("--color_secondary_dark", secondaryDarkColor);
    setColorValue("--color_secondary_light", secondaryLightColor);
    setColorValue("--color_text_dark", textDarkColor);
    setColorValue("--color_text_light", textLightColor);
    setColorValue("--color_hover_selected_light", hoverSelectedLightColor);
    setColorValue("--color_background_black", backgroundBlackColor);
    setColorValue("--color_background_white", backgroundWhiteColor);
  }

  private _getLogoFromClient(user: IUser, client: IClient) {
    if (!client?.template?.logo) {
      return this.isB2CUser(user) ? DefaultB2CMoveLogo : DefaultMoveLogo;
    }

    const logoURL = API_LOGOS_URL + client.template.logo;

    return logoURL;
  }

  private _getThemeColor(theme: THEME_MODE, lightThemeColor: string, darkThemeColor: string) {
    return theme === THEME_MODE.LIGHT ? lightThemeColor : darkThemeColor;
  }

  private _getTagColorsFromClient(client: IClient) {
    // for the time being tag colors will be generic with no support
    // for templating

    // if (!Object.keys(client?.template ?? {}).length) {
    /**
     * Default tag colors
     */
    return [
      "#601A22",
      "#880817",
      "#C7142C",
      "#DE3900",
      "#F88D68",
      "#DE8C09",
      "#FA9C06",
      "#FFBC4E",
      "#FFDCA0",
      "#FDF1DA",
      "#24518E",
      "#1173A5",
      "#13A0C2",
      "#99C4DD",
      "#CBE7F7",
      "#325D30",
      "#158015",
      "#26B256",
      "#1AF03B",
      "#92F5C0",
      "#331484",
      "#7F14AF",
      "#A45FC9",
      "#C884F0",
      "#D1CFF8",
    ];
    // }

    // const {
    //     custom_tag_color_one,
    //     custom_tag_color_two,
    //     custom_tag_color_three,
    //     custom_tag_color_four,
    // } = client.template;

    // return [
    //     `#${custom_tag_color_one}`,
    //     `#${custom_tag_color_two}`,
    //     `#${custom_tag_color_three}`,
    //     `#${custom_tag_color_four}`,
    // ];
  }
}

export const sessionFacade = new SessionFacade(sessionStore, sessionQuery);
