import React from "react";
import { Animate } from "react-move";

interface IProps {
  valueStart: number;
  valueEnd: number;
  duration: number;
  easingFunction: CallableFunction;
  children: any;
}

const AnimatedProgressProvider = ({
  valueStart = 0,
  valueEnd,
  duration,
  easingFunction,
  children,
}: IProps) => {
  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [valueEnd],
        timing: {
          duration: duration,
          ease: easingFunction,
        },
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  );
};

export default AnimatedProgressProvider;
