import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as TrendIcon } from "../../../../../assets/hub/TrendIcon.svg";
import { Spacing } from "../../../../../components/spacing/component";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { ICompany, ICorporatesLatestSignal } from "../HubSidePanel";
import RLink from "../../../../../components/link/RLink";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { HubContext } from "../../../../../modules/hub/state/hub.context";
import CompanyInsights from "./CompanyInsights/CompanyInsights";
import CorporatesTags from "../../../../home/CorporatesTags/CorporatesTags";
import CorporatesSelectionTags from "../../../../home/CorporatesTags/CorporatesSelectionTags";

interface IProps {
  company: ICompany;
  companyTrendData: ICorporatesLatestSignal | null;
  isNew: boolean;
}

function fixTags(company: ICompany) {
  return company.tags.map((t) => {
    if (t?.name.includes("color_code")) {
      return { ...t, ...JSON.parse(t.name) };
    }

    return t;
  });
}

const HubSidePanelItem = ({ company, companyTrendData, isNew }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return isSmallerScreen ? (
    <SmallItemLayout company={company} companyTrendData={companyTrendData} isNew={isNew} />
  ) : (
    <DefaultItemLayout company={company} companyTrendData={companyTrendData} isNew={isNew} />
  );
};

const DefaultItemLayout = ({ company, companyTrendData, isNew }: IProps) => {
  const { setHubRolloverCorporate } = useContext(HubContext);
  const { t } = useTranslation();

  const tags = fixTags(company);

  return (
    <div
      className={styles.companyContainer}
      onMouseEnter={() => setHubRolloverCorporate(company.corporateId)}
    >
      <Flex alignItemsCenter justifyContentSpaceBetween>
        <Flex column className={styles.companyColumn}>
          <NewLabel isNew={isNew} />
          <StarRating
            affinity={company.affinity}
            iconHeight="18"
            spacing="2"
            emptyIconSize="10"
          />
          <Spacing pt="10" />
          <span>
            <RLink
              path={{
                pathname: `/home/search/corporate-dossier/${company.corporateId}`,
                state: { fromHub: true },
              }}
              className={styles.inline}
            >
              <RText bold fontSize="15" className={styles.inline}>
                {company.companyName}
              </RText>
            </RLink>
            <CorporatesSelectionTags tags={tags} />
          </span>
          <Spacing pt="10" />
          <Flex flex>
            <RText fontSize="14">{company.zipCode?.toString()}</RText>
            <Spacing pl="10" />
            <RText fontSize="14">{company.city}</RText>
          </Flex>
          <Spacing pt="10" />
          <CorporatesTags companyId={company.corporateId} />
        </Flex>

        <Spacing pr="10" />

        <CompanyInsights
          insights={{
            [t("trend")]: !!companyTrendData?.companyTrend
              ? companyTrendData.companyTrend
              : t("noData"),
            [t("promoterScore")]:
              company?.promoterScore >= "0" ? company.promoterScore + "%" : t("noData"),
            [t("successScore")]:
              company?.successScore >= "0" ? company.successScore + "%" : t("noData"),
          }}
        />
      </Flex>
    </div>
  );
};

const SmallItemLayout = ({ company, companyTrendData, isNew }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();
  const { setHubRolloverCorporate } = useContext(HubContext);

  const tags = fixTags(company);

  return (
    <div
      className={styles.companyContainer}
      onMouseEnter={() => setHubRolloverCorporate(company.corporateId)}
    >
      <Flex alignItemsCenter justifyContentSpaceBetween>
        <Flex column>
          <NewLabel isNew={isNew} />
          <Flex alignItemsCenter>
            <StarRating
              affinity={company.affinity}
              iconHeight="14"
              spacing="6"
              emptyIconSize="10"
            />

            <Spacing pl="10" />

            <Flex alignItemsCenter className={styles.trendContainer__Responsive}>
              <TrendIcon
                color={colors.primaryDarkColor}
                className={styles.trendIcon__Responsive}
              />
              <Spacing pl="6" />
              <RText immutableBlack fontSize="11">
                {companyTrendData && companyTrendData.companyTrend
                  ? companyTrendData.companyTrend
                  : t("noData")}
              </RText>
            </Flex>
          </Flex>

          <Spacing pt="10" />

          <span>
            <RLink
              path={{
                pathname: `/home/search/corporate-dossier/${company.corporateId}`,
                state: { fromHub: true },
              }}
              className={styles.inline}
            >
              <RText bold fontSize="11" className={styles.inline}>
                {company.companyName}
              </RText>
            </RLink>
            <CorporatesSelectionTags tags={tags} />
          </span>

          <Spacing pt="10" />

          <Flex flex>
            <RText fontSize="11">{company.zipCode?.toString()}</RText>
            <Spacing pl="10" />
            <RText fontSize="11">{company.city}</RText>
          </Flex>

          <Spacing pt="10" />

          <CorporatesTags companyId={company.corporateId} />
        </Flex>
      </Flex>
    </div>
  );
};

const NewLabel = ({ isNew }: { isNew: boolean }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: isNew ? "block" : "none" }}>
      <span className={styles.newLabel}>{t("new").toUpperCase()}</span>
    </div>
  );
};

export default HubSidePanelItem;
