// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_animations__Kwb8l {\n  width: 100%;\n  height: 100%;\n  padding: 15px 30px 15px 0;\n}\n\n.styles_circularAnimationContainer__Pjz2J {\n  width: 70px;\n  max-height: 70px;\n  padding-top: 5px;\n}\n\n.styles_circularAnimationContainer__Responsive__3NT\\+1 {\n  width: 70px;\n  max-height: 70px;\n}\n\n.styles_lineContainer__vKrWE {\n  width: 8px;\n  height: 100px;\n  -webkit-transform: rotate(180deg);\n  transform: rotate(180deg);\n}\n\n.styles_lineContainer__Responsive__4V0bu {\n  height: 70px;\n}\n\n.styles_filledContainer__IEzot {\n  width: 8px;\n}\n\n.styles_iconInsideCircle__waJMC {\n  height: 33px;\n}\n\n.styles_CircularProgressbar__joYMv {\n  height: 70px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/hub/HubHeader/HubKPIs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".animations {\n  width: 100%;\n  height: 100%;\n  padding: 15px 30px 15px 0;\n}\n\n.circularAnimationContainer {\n  width: 70px;\n  max-height: 70px;\n  padding-top: 5px;\n}\n\n.circularAnimationContainer__Responsive {\n  width: 70px;\n  max-height: 70px;\n}\n\n.lineContainer {\n  width: 8px;\n  height: 100px;\n  -webkit-transform: rotate(180deg);\n  transform: rotate(180deg);\n}\n\n.lineContainer__Responsive {\n  height: 70px;\n}\n\n.filledContainer {\n  width: 8px;\n}\n\n.iconInsideCircle {\n  height: 33px;\n}\n\n.CircularProgressbar {\n  height: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animations": "styles_animations__Kwb8l",
	"circularAnimationContainer": "styles_circularAnimationContainer__Pjz2J",
	"circularAnimationContainer__Responsive": "styles_circularAnimationContainer__Responsive__3NT+1",
	"lineContainer": "styles_lineContainer__vKrWE",
	"lineContainer__Responsive": "styles_lineContainer__Responsive__4V0bu",
	"filledContainer": "styles_filledContainer__IEzot",
	"iconInsideCircle": "styles_iconInsideCircle__waJMC",
	"CircularProgressbar": "styles_CircularProgressbar__joYMv"
};
export default ___CSS_LOADER_EXPORT___;
