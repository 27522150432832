import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { differenceInDays, format } from "date-fns";
import styles from "./styles.module.css";
import { ReactComponent as TrendIcon } from "../../../../../assets/hub/TrendIcon.svg";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../components/spacing/component";
import { IHubFooterItemData } from "../../../../../shared/interfaces/hub-footer-item-data.interface";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { ICorporate } from "../../../../../entities/corporates/models/corporate.model";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import RLink from "../../../../../components/link/RLink";
import { buildCompanyAddress } from "../../../../../helpers/map.helper";
import CountBox from "../../../../../components/other/CountBox/CountBox";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import NewsIconWhite from "../../../../../assets/hub/NewsIconWhite.svg";

export enum SelectedView {
  COMPANY_INFO,
  PROMOTER_SCORE,
  SUCCESS_SCORE,
}

interface IProps {
  item: IHubFooterItemData;
  isStartupItem?: boolean;
  isBankruptcyItem?: boolean;
}

const FooterItem = ({ item, isStartupItem, isBankruptcyItem }: IProps) => {
  const renderContentOnLeftSide = () => {
    if (isStartupItem) {
      return (
        <StartUpFounding
          daysSinceFoundation={
            item.company?.founding_date &&
            Math.abs(differenceInDays(new Date(), new Date(item.company.founding_date)))
          }
        />
      );
    } else if (isBankruptcyItem) {
      return (
        <DayOfBankruptcy
          dayOfBankruptcy={
            item.company?.status_date &&
            format(new Date(item.company.status_date), "dd.MM.yyyy")
          }
        />
      );
    }

    return <ProductTrend newItem={item.newItem} trendType={item.trendType} />;
  };

  return (
    <Flex alignItemsCenter={isStartupItem || isBankruptcyItem} className={styles.footerItem}>
      {renderContentOnLeftSide()}
      <Spacing pl="15" />
      <CompanyInfo company={item.company} newsCount={item.newsCount} />
    </Flex>
  );
};

export default FooterItem;

const CompanyInfo = ({ company, newsCount }: { company: ICorporate; newsCount: number }) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex>
      <Flex column justifyContentSpaceBetween>
        <StarRating affinity={company.affinity_score} spacing="5" iconHeight="12" />
        <Spacing pb="8" />
        <RLink
          path={{
            pathname: `/home/search/corporate-dossier/${company.id}`,
            state: { fromHub: true },
          }}
          className={styles.companyNameContainer}
        >
          <RText ellipsis fontSize={isSmallerScreen ? "11" : "13"} bold immutableWhite>
            {company.company_name}
          </RText>
        </RLink>
        <Spacing pb="8" />
        <RText fontSize={isSmallerScreen ? "11" : "13"} immutableWhite>
          {buildCompanyAddress(
            null, // exclude street from address
            company.zip_code,
            company.city,
          )}
        </RText>
      </Flex>
      <Spacing pl="15" />
      <Flex column>
        <CountBox count={newsCount} icon={NewsIconWhite} />
      </Flex>
    </Flex>
  );
};

const ProductTrend = ({ trendType, newItem }: { trendType: string; newItem?: boolean }) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();

  const newLabelContainerStyle: React.CSSProperties = newItem ? {} : { visibility: "hidden" };

  return (
    <Flex column alignItemsCenter>
      <Spacing
        pb="5"
        ph="5"
        className={styles.newLabelContainer}
        style={newLabelContainerStyle}
      >
        {t("new")}
      </Spacing>
      <Spacing pb="5" ph="5">
        <Flex alignItemsCenter>
          <TrendIcon color={colors.primaryDarkColor} />
          <Spacing pr="3" />
          <RText bold fontSize="12" immutableWhite>
            {t("trend")}
          </RText>
        </Flex>
      </Spacing>
      <div className={styles.horizontalLine} />
      <Spacing pt="5" ph="5">
        <Flex alignItemsCenter justifyContentCenter>
          <RText fontSize="13" immutableWhite>
            {trendType ? trendType : t("noData")}
          </RText>
        </Flex>
      </Spacing>
    </Flex>
  );
};

const StartUpFounding = ({ daysSinceFoundation }: { daysSinceFoundation: number | null }) => {
  const { t } = useTranslation();

  return (
    daysSinceFoundation && (
      <Flex alignItemsCenter className={styles.startupFoundingContainer}>
        <RText fontSize="18" bold className={styles.startupText}>
          {`${daysSinceFoundation}`}
        </RText>
        <Spacing pr="5" />
        <Flex column>
          <RText fontSize="12" immutableWhite>
            {t("founding")}
          </RText>
          <Spacing pt="2" />
          <RText fontSize="12" className={styles.startupText}>
            {t("daysAgo")}
          </RText>
        </Flex>
      </Flex>
    )
  );
};

const DayOfBankruptcy = ({ dayOfBankruptcy }: { dayOfBankruptcy: string | null }) => {
  const { t } = useTranslation();

  return (
    <Flex
      column
      justifyContentCenter
      alignItemsCenter
      className={styles.dayOfBankruptcyContainer}
    >
      <RText fontSize="12" immutableWhite>
        {dayOfBankruptcy ? t("dayOfBankruptcy") : t("insolvencyOpened").split(" ")[0]}
      </RText>
      <Spacing pt="2" />
      <RText
        fontSize={dayOfBankruptcy ? "16" : "14"}
        bold
        className={styles.dayOfBankruptcyText}
      >
        {dayOfBankruptcy ?? t("insolvencyOpened").split(" ")[1]}
      </RText>
    </Flex>
  );
};
