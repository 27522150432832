// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_bounceContentParent__MrzOj {\n  position: relative;\n  display: block;\n  white-space: nowrap;\n  overflow: hidden;\n  box-sizing: border-box;\n  width: 100%;\n}\n\n.styles_bounceContent__O\\+25p {\n  display: inline-block;\n  height: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/other/BounceContent/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".bounceContentParent {\n  position: relative;\n  display: block;\n  white-space: nowrap;\n  overflow: hidden;\n  box-sizing: border-box;\n  width: 100%;\n}\n\n.bounceContent {\n  display: inline-block;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bounceContentParent": "styles_bounceContentParent__MrzOj",
	"bounceContent": "styles_bounceContent__O+25p"
};
export default ___CSS_LOADER_EXPORT___;
