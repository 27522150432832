import React, { useContext } from "react";
import { HubContext } from "../../../../modules/hub/state/hub.context";
import { IHubFooterItemData } from "../../../../shared/interfaces/hub-footer-item-data.interface";
import { HUB_FOOTER_NAVIGATION_TYPE } from "../HubFooter";
import FooterItem from "./FooterItem/FooterItem";
import NewsItem from "./NewsItem/NewsItem";
import AnniversaryItem from "../anniversaries/AnniversaryItem";

interface IProps {
  item: IHubFooterItemData;
  itemType: HUB_FOOTER_NAVIGATION_TYPE;
}

const HubFooterItem = ({ item, itemType }: IProps) => {
  const { setHubRolloverCorporate } = useContext(HubContext);

  const renderFooterItemContent = () => {
    switch (itemType) {
      case HUB_FOOTER_NAVIGATION_TYPE.PRODUCT_TRENDS:
        return <FooterItem item={item} />;
      case HUB_FOOTER_NAVIGATION_TYPE.NEWS:
        return <NewsItem item={item} />;
      case HUB_FOOTER_NAVIGATION_TYPE.ANNIVERSARY:
        return <AnniversaryItem item={item} />;
      case HUB_FOOTER_NAVIGATION_TYPE.START_UPS:
        return <FooterItem item={item} isStartupItem={true} />;
      case HUB_FOOTER_NAVIGATION_TYPE.BANKRUPTCIES:
        return <FooterItem item={item} isBankruptcyItem={true} />;
      default:
        return <FooterItem item={item} />;
    }
  };

  return (
    <div
      className="hubFooterItemContainer"
      onMouseEnter={() => setHubRolloverCorporate(item.company.id)}
    >
      {renderFooterItemContent()}
    </div>
  );
};

export default HubFooterItem;
