// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_newsTitleContainer__2td2c {\n  width: 275px;\n  white-space: normal;\n}\n\n.styles_newsTitleContainer__2td2c > p {\n  display: inline;\n  padding: 0px 4px;\n}\n\n.styles_newsItem__lHird {\n  height: 100%;\n  padding: 4px 0px;\n}\n\n.styles_newsItem__lHird:hover {\n  background-color: var(--immutable_black_light);\n}\n\n.styles_arrowIcon__ytG1G {\n  width: 11px;\n  height: 11px;\n}\n\n.styles_scoreCircle__t554X {\n  width: 35px;\n  height: 35px;\n  background: var(--immutable_background_black);\n  color: var(--immutable_background_white);\n  border-radius: 100%;\n  padding: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/executives-hub/ExecutivesHubFooter/FooterItems/CompanyNews/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6CAA6C;EAC7C,wCAAwC;EACxC,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".newsTitleContainer {\n  width: 275px;\n  white-space: normal;\n}\n\n.newsTitleContainer > p {\n  display: inline;\n  padding: 0px 4px;\n}\n\n.newsItem {\n  height: 100%;\n  padding: 4px 0px;\n}\n\n.newsItem:hover {\n  background-color: var(--immutable_black_light);\n}\n\n.arrowIcon {\n  width: 11px;\n  height: 11px;\n}\n\n.scoreCircle {\n  width: 35px;\n  height: 35px;\n  background: var(--immutable_background_black);\n  color: var(--immutable_background_white);\n  border-radius: 100%;\n  padding: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsTitleContainer": "styles_newsTitleContainer__2td2c",
	"newsItem": "styles_newsItem__lHird",
	"arrowIcon": "styles_arrowIcon__ytG1G",
	"scoreCircle": "styles_scoreCircle__t554X"
};
export default ___CSS_LOADER_EXPORT___;
