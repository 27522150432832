import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as NavIconStartUps } from "../../../assets/NavIconStartUps.svg";
import { useStartupsSearchHits } from "../../../entities/startups-filters/hooks/startups-total-count.hook";
import FiltersHeader from "../filters/FiltersHeader/FiltersHeader";

const StartupsFiltersHeader = () => {
  const { t } = useTranslation();
  const { totalCount } = useStartupsSearchHits();

  return (
    <FiltersHeader
      title={t("navStartups")}
      Icon={NavIconStartUps}
      resultsCount={totalCount}
      onMyFiltersClick={null}
      onClearAllClick={() => null}
      filtersOpened={null}
    />
  );
};

export default StartupsFiltersHeader;
