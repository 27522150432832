// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropdownTitle__OjjuV {\n  line-height: 18px;\n  padding-right: 4px;\n}\n\n.styles_dropdownPopup__f3jMN {\n  position: absolute;\n  top: 25px;\n  z-index: 201;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 4px;\n  padding: 8px;\n  min-width: 300px;\n}\n\n.styles_dropdownOption__u4H7X {\n  cursor: pointer;\n  padding: 4px 8px;\n}\n\n.styles_dropdownOption__Active__QvYoN {\n  background: #e9e9e9;\n}\n\n.styles_filterContainer__gD23\\+ {\n  padding: 0 16px;\n}\n\n.styles_defaultTag__H7svI {\n  width: 18px;\n  height: 18px;\n  border-radius: 50%;\n}\n\n.styles_defaultTag__Responsive__4HNVX {\n  width: 15px;\n  height: 15px;\n}\n\n.styles_addIcon__4mvxD {\n  width: 10px;\n  height: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/filter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".dropdownTitle {\n  line-height: 18px;\n  padding-right: 4px;\n}\n\n.dropdownPopup {\n  position: absolute;\n  top: 25px;\n  z-index: 201;\n  border-style: solid;\n  border-width: 1px;\n  border-radius: 4px;\n  padding: 8px;\n  min-width: 300px;\n}\n\n.dropdownOption {\n  cursor: pointer;\n  padding: 4px 8px;\n}\n\n.dropdownOption__Active {\n  background: #e9e9e9;\n}\n\n.filterContainer {\n  padding: 0 16px;\n}\n\n.defaultTag {\n  width: 18px;\n  height: 18px;\n  border-radius: 50%;\n}\n\n.defaultTag__Responsive {\n  width: 15px;\n  height: 15px;\n}\n\n.addIcon {\n  width: 10px;\n  height: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownTitle": "styles_dropdownTitle__OjjuV",
	"dropdownPopup": "styles_dropdownPopup__f3jMN",
	"dropdownOption": "styles_dropdownOption__u4H7X",
	"dropdownOption__Active": "styles_dropdownOption__Active__QvYoN",
	"filterContainer": "styles_filterContainer__gD23+",
	"defaultTag": "styles_defaultTag__H7svI",
	"defaultTag__Responsive": "styles_defaultTag__Responsive__4HNVX",
	"addIcon": "styles_addIcon__4mvxD"
};
export default ___CSS_LOADER_EXPORT___;
