// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__MawTZ {\n  height: 28px;\n  border: #dbdada;\n  border-radius: 24px;\n  background: #dbdada;\n  padding: 2px 8px;\n  min-width: -webkit-min-content;\n  min-width: min-content;\n}\n\n.styles_icon__dL48\\+ {\n  height: 24px;\n  width: 24px;\n}\n\n.styles_closeIcon__FSudd {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/selections/SelectionItem/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,8BAAsB;EAAtB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".component {\n  height: 28px;\n  border: #dbdada;\n  border-radius: 24px;\n  background: #dbdada;\n  padding: 2px 8px;\n  min-width: min-content;\n}\n\n.icon {\n  height: 24px;\n  width: 24px;\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__MawTZ",
	"icon": "styles_icon__dL48+",
	"closeIcon": "styles_closeIcon__FSudd"
};
export default ___CSS_LOADER_EXPORT___;
