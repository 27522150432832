// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_navigationItemsContainer__1rfo0 {\n  height: 100%;\n  min-width: 100px;\n  line-height: 1.5;\n  margin-right: 15px;\n}\n\n.styles_navigationItemsContainer__Responsive__J7Anv {\n  line-height: 1.7;\n}\n\n.styles_activeItem__zNuBu {\n  color: var(--color_primary_dark) !important;\n}\n\n.styles_disabledItem__dEndn {\n  opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/executives-hub/ExecutivesHubFooter/FooterNavigation/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,YAAY;AACd","sourcesContent":[".navigationItemsContainer {\n  height: 100%;\n  min-width: 100px;\n  line-height: 1.5;\n  margin-right: 15px;\n}\n\n.navigationItemsContainer__Responsive {\n  line-height: 1.7;\n}\n\n.activeItem {\n  color: var(--color_primary_dark) !important;\n}\n\n.disabledItem {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationItemsContainer": "styles_navigationItemsContainer__1rfo0",
	"navigationItemsContainer__Responsive": "styles_navigationItemsContainer__Responsive__J7Anv",
	"activeItem": "styles_activeItem__zNuBu",
	"disabledItem": "styles_disabledItem__dEndn"
};
export default ___CSS_LOADER_EXPORT___;
