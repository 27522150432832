import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";
import { getDisplayValue } from ".";

export const suggestionsFunctionsByType: {
  [key: string]: (
    inputValue: string,
    items: Array<ICorporatesFilterValue>,
  ) => { suggestions: Array<ICorporatesFilterValue>; highlightedIdx: number };
} = {
  industry_sector_codes_full: (inputValue: string, items: Array<ICorporatesFilterValue>) => {
    if (!inputValue) {
      return { suggestions: [], highlightedIdx: -1 };
    }
    const uppercaseInputValue = inputValue.toUpperCase();
    let suggestions: Array<ICorporatesFilterValue>;

    if (uppercaseInputValue.length > 2) {
      suggestions = items.filter((item) => {
        const userVal = uppercaseInputValue.split(" ");
        const ucVal = [...item.id.toUpperCase().split(" "), item.value_key].join(" ");

        return userVal.every((el) => ucVal.includes(el));

        // return item.value_key
        //     ?.toUpperCase()
        //     .includes(uppercaseInputValue) ||
        // item.label?.toUpperCase().includes(uppercaseInputValue) ||
        // getDisplayValue("industry_sector_codes_full")(item)
        //     ?.toUpperCase()
        //     .includes(uppercaseInputValue)
      });
    } else {
      suggestions = items.filter((item) =>
        item.value_key?.toUpperCase().includes(uppercaseInputValue),
      );
    }

    let highlightedIdx: number;
    if (suggestions.length === 1) {
      highlightedIdx = 0;
    } else {
      const suggestionByValue = suggestions.findIndex(
        (suggestion) => suggestion.value_key?.toUpperCase() === uppercaseInputValue,
      );
      const suggestionByLabel = suggestions.findIndex(
        (suggestion) => suggestion.label?.toUpperCase() === uppercaseInputValue,
      );
      if (suggestionByValue !== -1 && suggestionByLabel !== -1) {
        highlightedIdx = -1;
      } else {
        const suggestionByDisplay = suggestions.findIndex(
          (suggestion) =>
            getDisplayValue("industry_sector_codes_full")(suggestion)?.toUpperCase() ===
            uppercaseInputValue,
        );
        if (suggestionByDisplay === -1) {
          highlightedIdx = suggestionByValue === -1 ? suggestionByLabel : suggestionByValue;
        } else if (suggestionByValue === -1) {
          highlightedIdx = suggestionByLabel === -1 ? suggestionByDisplay : -1;
        } else {
          highlightedIdx = -1;
        }
      }
    }
    return { suggestions, highlightedIdx };
  },
  zip_code: (inputValue: string, items: Array<ICorporatesFilterValue>) => {
    const suggestions =
      inputValue?.length > 2
        ? items.filter(
            (item) => !inputValue || getDisplayValue("zip_code")(item)?.startsWith(inputValue),
          )
        : [];
    const highlightedIdx =
      suggestions.length === 1
        ? 0
        : suggestions.findIndex((suggestion) => getDisplayValue()(suggestion) === inputValue);
    return { suggestions, highlightedIdx };
  },
};

export const defaultSuggestionsFunction = (
  inputValue: string,
  items: Array<ICorporatesFilterValue>,
) => {
  const suggestions =
    inputValue?.length > 2
      ? items.filter((item) => !inputValue || getDisplayValue()(item)?.startsWith(inputValue))
      : [];
  const highlightedIdx =
    suggestions.length === 1
      ? 0
      : suggestions.findIndex((suggestion) => getDisplayValue()(suggestion) === inputValue);
  return { suggestions, highlightedIdx };
};
