// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_countBoxContainer__KifRQ {\n  position: relative;\n  min-width: 40px;\n}\n\n.styles_coloredContainer__lQ0-R {\n  position: relative;\n  top: -1px;\n  left: -4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/other/CountBox/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ","sourcesContent":[".countBoxContainer {\n  position: relative;\n  min-width: 40px;\n}\n\n.coloredContainer {\n  position: relative;\n  top: -1px;\n  left: -4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countBoxContainer": "styles_countBoxContainer__KifRQ",
	"coloredContainer": "styles_coloredContainer__lQ0-R"
};
export default ___CSS_LOADER_EXPORT___;
