import React, { useContext } from "react";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import { Spacing } from "../../../../../../components/spacing/component";
import RText from "../../../../../../components/fonts/RText/RText";
import styles from "./styles.module.css";
import { ReactComponent as AncorDown } from "../../../../../../assets/company-dossier/news-section/AncorDown.svg";
import { ReactComponent as AncorUp } from "../../../../../../assets/company-dossier/news-section/AncorUp.svg";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../hooks/app-colors.hook";

export enum HUB_SIDE_PANEL_SORT_BY {
  PRODUCT_TRENDS = "PRODUCT_TRENDS",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

interface IProps {
  sortBy: HUB_SIDE_PANEL_SORT_BY;
  onSortByName: () => void;
}

const HubSidePanelSortBy = ({ sortBy, onSortByName }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const getSortByIcon = () => {
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.PRODUCT_TRENDS) {
      return null;
    }
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_ASC) {
      return <AncorUp color={colors.secondaryDarkColor} className={styles.arrowIcon} />;
    }
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_DESC) {
      return <AncorDown color={colors.secondaryDarkColor} className={styles.arrowIcon} />;
    }
  };

  return (
    <Flex alignItemsCenter>
      <Spacing pr="10" />
      <Flex alignItemsCenter pointer onClick={onSortByName}>
        <RText fontSize={isSmallerScreen ? "11" : "13"}>A - Z</RText>
        <Spacing pr="10" />
        {getSortByIcon()}
      </Flex>
    </Flex>
  );
};

export default HubSidePanelSortBy;
