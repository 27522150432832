import { SELECTED_VALUES_TYPE } from "../enums/selected-values-type.enum";
import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";

export interface ISelectedValuesRange {
  type: SELECTED_VALUES_TYPE.RANGE;
  from: string;
  to: string;
}

export interface ISelectedValuesRef {
  type: SELECTED_VALUES_TYPE.REFERENCE;
  ref: string;
}

/**
 * Represents a type of selected value. It should only be subtypes differentiated by the type prop, raw string is
 * left for compat reasons and should be removed when refactored.
 * There should be two main types - dynamic and referenced. Referenced can reference a filter value while dynamic
 * gets generated on the frontend with no single backing filter value object. Each of these could then be subdivided
 * into range and single value (and other types if necessary).
 */
export type SelectedValueTypes = string | ISelectedValuesRange | ISelectedValuesRef;

/**
 * Compares whether two instances of SelectedValueTypes are equal.
 * @param x known SelectedValueType
 * @param y object (SelectedValueType) to compare to the known object
 */
export const areEqual = (x: SelectedValueTypes, y: any): boolean => {
  if (x === undefined || x === null) {
    return x === y;
  }

  if (typeof x === "string" || x instanceof String) {
    return x === y;
  }

  switch (x.type) {
    case SELECTED_VALUES_TYPE.RANGE:
      return x.from === y?.from && x.to === y?.to;
    case SELECTED_VALUES_TYPE.REFERENCE:
      return x.ref === y?.ref;
    default:
      // return false;
      // added to support old format of other primitives since input is not strictly string due to lack of API input sanitizing
      return x === y;
  }
};

/**
 * Compare whether a SelectedValueType instance corresponds to an ICorporatesFilterValue instance
 * @param selectedValue
 * @param filterValue
 */
export const isSelectedValueEqualToFilterValue = (
  selectedValue: SelectedValueTypes,
  filterValue: ICorporatesFilterValue,
) => {
  // check and compare for nulls and undefs
  if (selectedValue === undefined || selectedValue === null) {
    return filterValue === undefined || filterValue === null;
  }

  // if sV not null/undef and fV is then it's !eq
  if (!filterValue) return false;

  // legacy sV format is in most cases set from fV.label
  // WARNING: there are some filter types where this is not true and this function
  // will therefore not work for them
  if (selectedValue instanceof String || typeof selectedValue === "string") {
    return selectedValue === filterValue.label;
  }

  // compare sV
  switch (selectedValue.type) {
    // ranges are equal if their from and to props are equal
    case SELECTED_VALUES_TYPE.RANGE:
      return selectedValue.from === filterValue.from && selectedValue.to === filterValue.to;

    // ref for reference sV is set to fV label so compare that
    case SELECTED_VALUES_TYPE.REFERENCE:
      return selectedValue.ref === filterValue.label;
    default:
      // break;
      // added to support old format of other primitives since input is not strictly string due to lack of API input sanitizing
      return selectedValue === filterValue?.label;
  }
  // return false;
};
