// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_exportButton__T2uSI {\n  cursor: pointer;\n  color: var(--color_text_dark);\n}\n", "",{"version":3,"sources":["webpack://./src/components/export/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".exportButton {\n  cursor: pointer;\n  color: var(--color_text_dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exportButton": "styles_exportButton__T2uSI"
};
export default ___CSS_LOADER_EXPORT___;
