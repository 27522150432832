import React from "react";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import styles from "./styles.module.css";
// import { Spacing } from "../../../../../components/spacing/component";
// import RText from "../../../../../components/fonts/RText/RText";
// import { useTranslation } from "react-i18next";
import {
  // HubSidePanelSortBy,
  HUB_SIDE_PANEL_SORT_BY,
} from "./HubSidePanelSortBy/HubSidePanelSortBy";
import HubSidePanelSearchInput from "./HubSidePanelSearchInput/HubSidePanelSearchInput";

interface IProps {
  searchQuery: string;
  sortBy: HUB_SIDE_PANEL_SORT_BY;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
  onSortByName: () => void;
  onSearchQueryDelete: () => void;
}

const HubSidePanelHeader = ({
  searchQuery,
  sortBy,
  onInputChange,
  onSearch,
  onSortByName,
  onSearchQueryDelete,
}: IProps) => {
  // const { t } = useTranslation();

  // Perform companies search on enter key press.
  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    onSearch();
  };

  return (
    <div className={styles.headerContainer}>
      <Flex alignItemsCenter>
        {/* {isSmallerScreen && (
                    <HubSidePanelSortBy
                        sortBy={sortBy}
                        onSortByName={onSortByName}
                    />
                )} */}

        <HubSidePanelSearchInput
          searchQuery={searchQuery}
          onInputChange={onInputChange}
          onSearchQueryDelete={onSearchQueryDelete}
          onEnterKeyPress={onEnterKeyPress}
        />

        {/* {!isSmallerScreen && (
                    <>
                        <Spacing pr="10" />
                        <Flex pointer onClick={onSearch}>
                            <RText primaryBlue bold fontSize="13">
                                {t("search")}
                            </RText>
                        </Flex>
                    </>
                )} */}
      </Flex>
    </div>
  );
};

export default React.memo(HubSidePanelHeader);
