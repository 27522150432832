import { IUser } from "../../../models/login/user.model";
import { Store, StoreConfig } from "@datorama/akita";
import { IClient } from "../../../models/login/client.model";
import { THEME_MODE } from "./session.model";

export interface SessionState {
  user?: IUser | undefined;
  token?: string | undefined;
  client: IClient;
  theme: THEME_MODE;
  loadingUser: boolean;
}

export function createInitialState(): SessionState {
  return {
    user: undefined,
    token: undefined,
    client: null,
    theme: THEME_MODE.LIGHT,
    loadingUser: false,
  };
}

@StoreConfig({ name: "session" })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}

export const sessionStore = new SessionStore();
