import React, { useContext } from "react";
import Divider from "../../../components/dividers/Divider/Divider";
import SearchResultsFor from "../SearchResultsFor/SearchResultsFor";
import StartupsFiltersHeader from "./StartupsFiltersHeader";
import StartupsSearchResults from "./StartupsSearchResults";
import { Spacing } from "../../../components/spacing/component";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";

const StartupsPage = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <>
      <SearchResultsFor />

      <StartupsFiltersHeader />

      <Spacing pt={isSmallerScreen ? "12" : "24"} />

      <Divider />

      <StartupsSearchResults />
    </>
  );
};

export default StartupsPage;
