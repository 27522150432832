// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_itemContainer__Q3aPf {\n  text-align: center;\n}\n\n.styles_navItemTitle__bZZQO {\n  color: var(--color_text_disabled);\n}\n\n.styles_selectedNavItem__fb0Rp {\n  color: var(--color_text_dark);\n}\n\n.styles_selectedDividerLine__TVIMo {\n  position: relative;\n  top: 1px;\n}\n\n.styles_disabled__l7dCB {\n  opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://./src/components/navigation/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd","sourcesContent":[".itemContainer {\n  text-align: center;\n}\n\n.navItemTitle {\n  color: var(--color_text_disabled);\n}\n\n.selectedNavItem {\n  color: var(--color_text_dark);\n}\n\n.selectedDividerLine {\n  position: relative;\n  top: 1px;\n}\n\n.disabled {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemContainer": "styles_itemContainer__Q3aPf",
	"navItemTitle": "styles_navItemTitle__bZZQO",
	"selectedNavItem": "styles_selectedNavItem__fb0Rp",
	"selectedDividerLine": "styles_selectedDividerLine__TVIMo",
	"disabled": "styles_disabled__l7dCB"
};
export default ___CSS_LOADER_EXPORT___;
