import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./styles.module.css";
import { useForgotPasswordValidationSchema } from "../../hooks/formValidationSchema.hook";
import { SessionContext } from "../../state/akita/session/session.context";
import { IForgotPasswordFormData } from "../../state/akita/session/session.model";
import { LoginInput } from "../../components/inputs/LoginInput/LoginInput";
import { Spacing } from "../../components/spacing/component";
import { Flex } from "../../components/containers/Flex/Flex";
import RText from "../../components/fonts/RText/RText";
import { CircularProgress } from "@mui/material";

interface IProps {
  showLogin: () => void;
}

const ForgotPasswordForm: React.FC<IProps> = ({ showLogin }) => {
  const { t } = useTranslation();

  const { resetPassword } = useContext(SessionContext);
  const forgotPasswordValidationSchema = useForgotPasswordValidationSchema();
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (userEmail: IForgotPasswordFormData) => {
    setLoading(true);
    resetPassword(userEmail)
      .then(() => setEmailSent(true))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex column alignItemsCenter>
      <div className={styles.formWrapper}>
        <RText fontSize="22" noWrap className={styles.formTitle}>
          {t("forgotPassword")}
        </RText>
        <Spacing pt="18" />

        <form>
          {!emailSent ? (
            <>
              <RText fontSize="12" immutableWhite className={styles.forgotPasswordInputLabel}>
                {t("forgotPasswordInputLabel")}
              </RText>
              <Spacing pt="53" />

              <Controller
                defaultValue=""
                name="email"
                control={control}
                render={({ ref, ...props }) => (
                  <LoginInput
                    inputRef={ref}
                    error={errors.email?.message}
                    placeholder={t("email")}
                    {...props}
                  />
                )}
              />
              <Spacing pb="66" />
              <button className={styles.submitButton} onClick={handleSubmit(onSubmit)}>
                {loading && <CircularProgress sx={{ color: "white" }} size={16} />}
                {!loading && <>{t("send")}</>}
              </button>
            </>
          ) : (
            <RText className={styles.forgotPasswordInputLabel} immutableWhite fontSize="12">
              {t("forgotPasswordResponseMessage")}
            </RText>
          )}

          <Spacing pb="34" />
          <Flex onClick={showLogin} pointer className={styles.link}>
            <RText fontSize="12" immutableWhite underlined>
              {t("backToLogin")}
            </RText>
          </Flex>
        </form>
      </div>
    </Flex>
  );
};

export default ForgotPasswordForm;
