import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";

const industrySectorLetterCodesLookUp: { [key: string]: string } = {
  A: "00",
  B: "05",
  C: "10",
  D: "35",
  E: "36",
  F: "41",
  G: "45",
  H: "49",
  I: "55",
  J: "58",
  K: "64",
  L: "68",
  M: "69",
  N: "77",
  O: "84",
  P: "85",
  Q: "86",
  R: "90",
  S: "94",
  T: "97",
  U: "99",
};

const replaceIndustrySectorLetterCodes = (code: string) => {
  if (code.length !== 1) return code;
  const uppercaseCode = code.toUpperCase();
  if (!industrySectorLetterCodesLookUp.hasOwnProperty(uppercaseCode)) return code;
  return industrySectorLetterCodesLookUp[uppercaseCode];
};

export const sortFunctionsByType: {
  [key: string]: (a: ICorporatesFilterValue, b: ICorporatesFilterValue) => number;
} = {
  industry_sector_codes_full: (a: ICorporatesFilterValue, b: ICorporatesFilterValue) =>
    replaceIndustrySectorLetterCodes(a.value_key).localeCompare(
      replaceIndustrySectorLetterCodes(b.value_key),
    ),
};

export const defaultSortFunction = (a: ICorporatesFilterValue, b: ICorporatesFilterValue) =>
  +a.value_key - +b.value_key;
