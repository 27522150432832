// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_executivesContainer__lgIf5 {\n  position: relative;\n  height: 100%;\n  max-height: 500px;\n  width: 100%;\n  overflow-y: scroll;\n}\n\n.styles_executivesContainer__lgIf5::-webkit-scrollbar {\n  display: none;\n}\n\n.styles_executivesContainer__lgIf5 {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.styles_lookALikesMapIcon__gueju {\n  width: 40px;\n  height: 40px;\n}\n\n.styles_starRatingContainer__Mi2EP {\n  padding: 20px;\n  background-color: var(--color_hover_selected_light);\n}\n\n.styles_scoreIconContainer__w8Xod {\n  width: 110px;\n  height: 110px;\n}\n\n.styles_scoreIconContainer__Responsive__r5qC1 {\n  height: 90px;\n  width: 90px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/executives/dossier/components/side-navigation-item-content/look-a-likes/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mDAAmD;AACrD;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".executivesContainer {\n  position: relative;\n  height: 100%;\n  max-height: 500px;\n  width: 100%;\n  overflow-y: scroll;\n}\n\n.executivesContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.executivesContainer {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.lookALikesMapIcon {\n  width: 40px;\n  height: 40px;\n}\n\n.starRatingContainer {\n  padding: 20px;\n  background-color: var(--color_hover_selected_light);\n}\n\n.scoreIconContainer {\n  width: 110px;\n  height: 110px;\n}\n\n.scoreIconContainer__Responsive {\n  height: 90px;\n  width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"executivesContainer": "styles_executivesContainer__lgIf5",
	"lookALikesMapIcon": "styles_lookALikesMapIcon__gueju",
	"starRatingContainer": "styles_starRatingContainer__Mi2EP",
	"scoreIconContainer": "styles_scoreIconContainer__w8Xod",
	"scoreIconContainer__Responsive": "styles_scoreIconContainer__Responsive__r5qC1"
};
export default ___CSS_LOADER_EXPORT___;
