// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_component__u5WgA {\n  background: var(--color_secondary_dark);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  padding: 5px 8px;\n  border-radius: 14px;\n\n  /* Prevent text from breaking into 2 or more lines */\n  white-space: nowrap;\n  overflow: hidden;\n\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/buttons/RoundedButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,gBAAgB;EAChB,mBAAmB;;EAEnB,oDAAoD;EACpD,mBAAmB;EACnB,gBAAgB;;EAEhB,eAAe;AACjB","sourcesContent":[".component {\n  background: var(--color_secondary_dark);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  padding: 5px 8px;\n  border-radius: 14px;\n\n  /* Prevent text from breaking into 2 or more lines */\n  white-space: nowrap;\n  overflow: hidden;\n\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "styles_component__u5WgA"
};
export default ___CSS_LOADER_EXPORT___;
