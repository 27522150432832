// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__B9XWS {\n  background-color: inherit;\n  color: var(--immutable_background_white);\n  font-family: inherit;\n  font-size: 100%;\n  line-height: 1;\n  margin: 0;\n  overflow: visible;\n  cursor: pointer;\n  -webkit-appearance: button;\n  padding: 5px;\n  border: 1px solid transparent;\n  border-radius: 6px;\n}\n.styles_button__B9XWS:active,\n.styles_button__B9XWS:focus {\n  border: 1px solid grey;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/header/language-switcher/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wCAAwC;EACxC,oBAAoB;EACpB,eAAe;EACf,cAAc;EACd,SAAS;EACT,iBAAiB;EACjB,eAAe;EACf,0BAA0B;EAC1B,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;AACpB;AACA;;EAEE,sBAAsB;AACxB","sourcesContent":[".button {\n  background-color: inherit;\n  color: var(--immutable_background_white);\n  font-family: inherit;\n  font-size: 100%;\n  line-height: 1;\n  margin: 0;\n  overflow: visible;\n  cursor: pointer;\n  -webkit-appearance: button;\n  padding: 5px;\n  border: 1px solid transparent;\n  border-radius: 6px;\n}\n.button:active,\n.button:focus {\n  border: 1px solid grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__B9XWS"
};
export default ___CSS_LOADER_EXPORT___;
