import { useTopNews } from "../../../entities/corporates-filters/hooks/top-news.hook";
import { useTopProductTrends } from "../../../entities/corporates-filters/hooks/top-product-trends.hook";
import { useTopStartUps } from "../../../entities/corporates-filters/hooks/top-start-ups.hook";
import { useAnniversaries } from "../../../entities/corporates-filters/hooks/anniversaries.hook";
import { HUB_FOOTER_NAVIGATION_TYPE } from "./HubFooter";

import React, { useEffect } from "react";

const VKB_CLIENT_NAME = "VKB";

const VKB_TOP_CLIENTS_STORE_NAME = "clients";
const VKB_TOP_LEADS_STORE_NAME = "leads";

enum ClientType {
  default = "default",
  vkb = "vkb",
}
const DefaultDataContainer = ({
  children,
  setNavigationItems,
}: {
  children: React.ReactNode;
  setNavigationItems: React.Dispatch<any[]>;
}): JSX.Element => {
  const { hubProductTrendsFooterItems, loadingTopProductTrends } = useTopProductTrends();
  const { hubNewsFooterItems, loadingTopNews } = useTopNews();
  const { hubStartUpsFooterItems, loadingTopStartUps } = useTopStartUps();

  useEffect(() => {
    setNavigationItems([
      {
        title: "productTrends",
        type: HUB_FOOTER_NAVIGATION_TYPE.PRODUCT_TRENDS,
        loading: loadingTopProductTrends,
        items: hubProductTrendsFooterItems,
        exist: loadingTopProductTrends ? true : hubProductTrendsFooterItems?.length > 0,
      },
      {
        title: "news",
        type: HUB_FOOTER_NAVIGATION_TYPE.NEWS,
        loading: loadingTopNews,
        items: hubNewsFooterItems,
        exist: loadingTopNews ? true : hubNewsFooterItems?.length > 0,
      },
      {
        title: "startups",
        type: HUB_FOOTER_NAVIGATION_TYPE.START_UPS,
        loading: loadingTopStartUps,
        items: hubStartUpsFooterItems,
        exist: loadingTopStartUps ? true : hubStartUpsFooterItems?.length > 0,
      },
    ]);
  }, [
    setNavigationItems,
    loadingTopStartUps,
    hubStartUpsFooterItems,
    loadingTopNews,
    hubNewsFooterItems,
    hubProductTrendsFooterItems,
    loadingTopProductTrends,
  ]);

  return <>{children}</>;
};

const DataContainer = ({
  clientType,
  setNavigationItems,
  children,
}: {
  clientType: ClientType;
  setNavigationItems: React.Dispatch<any[]>;
  children: React.ReactNode;
}): JSX.Element => {
  return clientType === ClientType.vkb ? (
    <VKBDataContainer setNavigationItems={setNavigationItems} children={children} />
  ) : (
    <DefaultDataContainer children={children} setNavigationItems={setNavigationItems} />
  );
};

const VKBDataContainer = ({
  children,
  setNavigationItems,
}: {
  children: React.ReactNode;
  setNavigationItems: React.Dispatch<any[]>;
}): JSX.Element => {
  const {
    hubProductTrendsFooterItems: topLeadsFooterItems,
    loadingTopProductTrends: loadingTopLeads,
  } = useTopProductTrends(VKB_TOP_LEADS_STORE_NAME);
  const {
    hubProductTrendsFooterItems: topClientsFooterItems,
    loadingTopProductTrends: loadingTopClients,
  } = useTopProductTrends(VKB_TOP_CLIENTS_STORE_NAME);

  const { hubAnniversariesFooterItems, loadingAnniversaries } = useAnniversaries();
  useEffect(() => {
    setNavigationItems([
      {
        title: "topLeads",
        type: HUB_FOOTER_NAVIGATION_TYPE.PLACEHOLDER,
        loading: loadingTopLeads,
        items: topLeadsFooterItems,
        exist: loadingTopLeads ? true : topLeadsFooterItems?.length > 0,
      },
      {
        title: "topClients",
        type: HUB_FOOTER_NAVIGATION_TYPE.PLACEHOLDER,
        loading: loadingTopClients,
        items: topClientsFooterItems,
        exist: loadingTopClients ? true : topClientsFooterItems?.length > 0,
      },
      {
        title: "anniversaries",
        type: HUB_FOOTER_NAVIGATION_TYPE.ANNIVERSARY,
        exist: hubAnniversariesFooterItems?.length > 0,
        loading:
          hubAnniversariesFooterItems === null || hubAnniversariesFooterItems === undefined,
        items: hubAnniversariesFooterItems,
      },
      {
        title: "industryTrends",
        type: HUB_FOOTER_NAVIGATION_TYPE.PLACEHOLDER,
        exist: false,
        loading: false,
        items: [],
      },
    ]);
  }, [
    setNavigationItems,
    hubAnniversariesFooterItems,
    loadingAnniversaries,
    loadingTopClients,
    topClientsFooterItems,
    loadingTopLeads,
    topLeadsFooterItems,
  ]);

  return <>{children}</>;
};

export {
  VKB_CLIENT_NAME,
  VKB_TOP_CLIENTS_STORE_NAME,
  VKB_TOP_LEADS_STORE_NAME,
  VKBDataContainer,
  DefaultDataContainer,
  DataContainer,
  ClientType,
};
