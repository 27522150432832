// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_scoreIconContainer__dkXdX {\n  width: 110px;\n  height: 110px;\n}\n\n.styles_scoreIconContainer__Responsive__vM6Wz {\n  height: 90px;\n  width: 90px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/executives/dossier/components/side-navigation-item-content/foundation-reach/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".scoreIconContainer {\n  width: 110px;\n  height: 110px;\n}\n\n.scoreIconContainer__Responsive {\n  height: 90px;\n  width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreIconContainer": "styles_scoreIconContainer__dkXdX",
	"scoreIconContainer__Responsive": "styles_scoreIconContainer__Responsive__vM6Wz"
};
export default ___CSS_LOADER_EXPORT___;
