import {
  valueToDisplayFunctionsByType,
  defaultValueToDisplayFunction,
} from "./display-values";
import { sortFunctionsByType, defaultSortFunction } from "./sorting";
import { suggestionsFunctionsByType, defaultSuggestionsFunction } from "./suggestions";
import { inputChangeCallbackByType } from "./input-change-callback";
import {
  dynamicRangeFilterCountsByType,
  defaultDynamicRangeFilterCount,
} from "./dynamic-range-filter-counts";
import { dynamicRangeLabelsByType, defaultDynamicRangeLabel } from "./dynamic-range-labels";
import { defaultDynamicRangeSelectionReferents } from "./dynamic-range-selection-referents";

interface IMapByType<T> {
  [key: string]: T;
}

const generateMapSingletonWithDefault =
  <T>(mapByType: IMapByType<T>, defaultValue: T) =>
  (filterKey?: string) => {
    const returnFunction = mapByType[filterKey];
    return returnFunction ?? defaultValue;
  };

export const getDisplayValue = generateMapSingletonWithDefault(
  valueToDisplayFunctionsByType,
  defaultValueToDisplayFunction,
);

export const getSortFunction = generateMapSingletonWithDefault(
  sortFunctionsByType,
  defaultSortFunction,
);

export const getSuggestionsFunction = generateMapSingletonWithDefault(
  suggestionsFunctionsByType,
  defaultSuggestionsFunction,
);

export const getInputChangeCallback = generateMapSingletonWithDefault(
  inputChangeCallbackByType,
  undefined,
);

export const getDynamicRangeFilterCount = generateMapSingletonWithDefault(
  dynamicRangeFilterCountsByType,
  defaultDynamicRangeFilterCount,
);

export const getDynamicRangeLabel = generateMapSingletonWithDefault(
  dynamicRangeLabelsByType,
  defaultDynamicRangeLabel,
);

export const getDynamicRangeSelectionReferents = generateMapSingletonWithDefault(
  {},
  defaultDynamicRangeSelectionReferents,
);
