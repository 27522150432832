import React from "react";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import StarRating from "../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../components/spacing/component";
import { FILTER_TYPE } from "../../../../shared/enums/filter-type.enum";
import { IFilterValue } from "../../../../shared/interfaces/filter-value.interface";
import HubFilterDot from "../HubFilterDot/HubFilterDot";
import ColoredContainer from "../../../../components/containers/ColoredContainer/ColoredContainer";
import { useSession } from "../../../../hooks/session.hook";

interface IProps {
  filterType: string;
  filterValue: IFilterValue;
  active: boolean;
  toggleOpen: (id: string) => void;
}

const HubFilterValue = ({ filterType, filterValue, active, toggleOpen }: IProps) => {
  const { isDarkTheme } = useSession();
  return (
    <>
      {(filterType === FILTER_TYPE.MULTI_BASIC || filterType === FILTER_TYPE.RANGE) && (
        <Spacing
          ph="6"
          pv="3"
          pointer
          onClick={() => toggleOpen(filterValue.label)}
          className={`${active && styles.active}`}
        >
          <Flex alignItemsCenter justifyContentSpaceBetween>
            <Flex alignItemsCenter justifyContentSpaceBetween>
              <HubFilterDot active={active} />
              <Spacing pl="8" />
              <RText fontSize="12" ellipsis textTooltip={filterValue.label}>
                {filterValue.label}
              </RText>
            </Flex>
            <Spacing pl="15" />
            <ColoredContainer primaryGreen={!isDarkTheme} size="16">
              <RText immutableBlack fontSize="12" bold>
                {filterValue.doc_count?.toString() ?? "0"}
              </RText>
            </ColoredContainer>
          </Flex>
        </Spacing>
      )}

      {filterType === FILTER_TYPE.PRODUCT_TRENDS && (
        <Spacing
          ph="6"
          pv="3"
          pointer
          onClick={() => toggleOpen(filterValue.label)}
          className={`${active && styles.active}`}
        >
          <Flex alignItemsCenter justifyContentSpaceBetween>
            <Flex alignItemsCenter justifyContentSpaceBetween>
              <HubFilterDot active={active} />
              <Spacing pl="8" />
              <RText fontSize="12" ellipsis textTooltip={filterValue.label}>
                {filterValue.label}
              </RText>
            </Flex>
            <Spacing pl="15" />
            <ColoredContainer primaryGreen={!isDarkTheme} size="16">
              <RText immutableBlack fontSize="12" bold>
                {filterValue.doc_count?.toString() ?? "0"}
              </RText>
            </ColoredContainer>
          </Flex>
        </Spacing>
      )}

      {filterType === FILTER_TYPE.AFFINITY && +filterValue.label > 0 && (
        <Spacing
          ph="6"
          pv="3"
          pointer
          onClick={() => toggleOpen(filterValue.label)}
          className={`${active && styles.active}`}
        >
          <Flex justifyContentSpaceBetween>
            <Flex alignItemsCenter justifyContentSpaceBetween>
              <HubFilterDot active={active} />
              <Spacing pl="8" />
              <StarRating
                spacing="4"
                iconHeight="14"
                affinity={+filterValue.label}
                hideEmptyIcon
              />
            </Flex>
            <Spacing pl="15" />
            <ColoredContainer primaryGreen={!isDarkTheme} size="16">
              <RText immutableBlack fontSize="12" bold>
                {filterValue.doc_count?.toString() ?? "0"}
              </RText>
            </ColoredContainer>
          </Flex>
        </Spacing>
      )}
    </>
  );
};

export default HubFilterValue;
