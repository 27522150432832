// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_scoreCircle__LSxuC {\n  width: 35px;\n  height: 35px;\n  background: var(--immutable_background_black);\n  color: var(--immutable_background_white);\n  border-radius: 100%;\n  padding: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.styles_leadItem__HUVcs {\n  height: 100%;\n  min-height: 80px;\n  padding: 4px 0px;\n}\n\n.styles_leadItem__HUVcs:hover {\n  background-color: var(--immutable_black_light);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/executives-hub/ExecutivesHubFooter/FooterItems/TopLeads/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,6CAA6C;EAC7C,wCAAwC;EACxC,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":[".scoreCircle {\n  width: 35px;\n  height: 35px;\n  background: var(--immutable_background_black);\n  color: var(--immutable_background_white);\n  border-radius: 100%;\n  padding: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.leadItem {\n  height: 100%;\n  min-height: 80px;\n  padding: 4px 0px;\n}\n\n.leadItem:hover {\n  background-color: var(--immutable_black_light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreCircle": "styles_scoreCircle__LSxuC",
	"leadItem": "styles_leadItem__HUVcs"
};
export default ___CSS_LOADER_EXPORT___;
