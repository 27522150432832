import { MD5 } from "object-hash";
import { IEntityTag } from "../entities/custom-tags/state/custom-tag.model";

// Returns a hex color string based on the last 6 digits of the
// MD5 hash of the input string.
// Could be cached by caller for performance.
export const stringToHashAndHexColor = (input: string): { color: string; hash: number } => {
  if (!input) return null;
  const hash = MD5(input);
  let numHash = 0;
  for (let i = 0; i < input.length; i++) {
    numHash = input.charCodeAt(i) + ((numHash << 5) - numHash);
  }
  return { color: "#" + hash.slice(-6), hash: Math.abs(numHash) * -1 };
};

export const tagEntityFromStringTag = (stringifiedTag: string): IEntityTag => {
  const parsedTag = JSON.parse(stringifiedTag)
  const hashAndColor = stringToHashAndHexColor(stringifiedTag);
  if (parsedTag.name !== undefined) {
    return {
      id: hashAndColor.hash,
      tag: {
        id: hashAndColor.hash,
        name: parsedTag.name,
        type: "",
        color_code: parsedTag.color_code !== undefined ? parsedTag.color_code : hashAndColor.color
      },
    }
  }


  return {
    id: hashAndColor.hash,
    tag: {
      id: hashAndColor.hash,
      name: stringifiedTag,
      type: "",
      color_code: hashAndColor.color,
    },
  };
};
