import React, { useContext } from "react";
import "reactjs-popup/dist/index.css";
import styles from "./styles.module.css";
import RText from "../../../../components/fonts/RText/RText";
import "../../../../components/popups/popupDefaultOverride.styles.css";
import { Spacing } from "../../../../components/spacing/component";
import { ICorporatesFilter } from "../../../../entities/corporates-filters/models/corporates-filter.model";
import { CorporatesFiltersContext } from "../../../../entities/corporates-filters/state/corporates-filters.context";
import { IFilterUI } from "../../../../shared/interfaces/filter-ui.interface";
import { Flex } from "../../../../components/containers/Flex/Flex";
import HubFilterDot from "../HubFilterDot/HubFilterDot";
import ColoredContainer from "../../../../components/containers/ColoredContainer/ColoredContainer";
import { FILTER_TYPE } from "../../../../shared/enums/filter-type.enum";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { ExecutivesFiltersContext } from "../../../../entities/executives-filters/state/executives-filters.context";
import { useSession } from "../../../../hooks/session.hook";
import { MapContext } from "../../../../context/MapContext";

interface IProps {
  filter: ICorporatesFilter;
  filterUI: IFilterUI;
  isExecutivesHub?: boolean;
}

const HubFilter = ({ filter, filterUI, isExecutivesHub }: IProps) => {
  const corporatesFiltersContext = useContext(CorporatesFiltersContext);
  const executivesFiltersContext = useContext(ExecutivesFiltersContext);
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme } = useSession();
  const { setRadius, setCenter } = useContext(MapContext);

  const toggleFilterOpen = () => {
    setRadius(null);
    setCenter(null);

    if (isExecutivesHub) {
      executivesFiltersContext.selectHubFilter(filter.id);
    } else {
      corporatesFiltersContext.selectHubFilter(filter.id);
    }
  };

  const isMultiLevelFilter = [
    FILTER_TYPE.MULTI_BASIC,
    FILTER_TYPE.RANGE,
    FILTER_TYPE.PRODUCT_TRENDS,
    FILTER_TYPE.AFFINITY,
  ].includes(filter.type as FILTER_TYPE);

  const hasAtLeastOneValueSelected = () => {
    return filterUI.selectedValues.length > 0;
  };

  return (
    <Spacing
      ph="6"
      pv={isSmallerScreen ? "2" : "3"}
      pointer
      className={`${filterUI.selected && styles.active}`}
    >
      <Flex onClick={toggleFilterOpen} alignItemsCenter justifyContentSpaceBetween>
        <Flex alignItemsCenter justifyContentSpaceBetween>
          <HubFilterDot
            active={filterUI.selected && !isMultiLevelFilter}
            activeMultiLevel={
              isMultiLevelFilter && (filterUI.selected || hasAtLeastOneValueSelected())
            }
          />
          <Spacing pl="8" />
          <RText fontSize={isSmallerScreen ? "11" : "13"}>{filter.title}</RText>
        </Flex>
        <Spacing pl="15" />
        <ColoredContainer primaryGreen={!isDarkTheme} size="16">
          <RText immutableBlack fontSize={isSmallerScreen ? "11" : "12"} bold>
            {filter.doc_count?.toString() ?? "0"}
          </RText>
        </ColoredContainer>
      </Flex>
    </Spacing>
  );
};

export default HubFilter;
