import { ICorporatesFilterValue } from "../../entities/corporates-filters/models/corporates-filter-value.model";
import { formatZipCode } from "../../helpers/map.helper";

export const valueToDisplayFunctionsByType: {
  [key: string]: (item: ICorporatesFilterValue) => string;
} = {
  industry_sector_codes_full: (item: ICorporatesFilterValue) => {
    const code_value = item.value_key;
    const label_value = item.label;
    return `${code_value ?? ""} ${label_value ?? ""}`;
  },
  zip_code: (item: ICorporatesFilterValue) => {
    return formatZipCode(item.label);
  },
};

export const defaultValueToDisplayFunction = (item: ICorporatesFilterValue) => {
  const value: unknown = item.label;
  return value === undefined || value === null ? "" : value.toString();
};
